import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ExpenseIntegrationService from "../../services/ExpenseIntegrationService";

const initialState = {
  data: {},
  columnConfig: [],
  status: "idle",
  formData: {
    accounts: [],
  },
};

export const fetchExpenseIntegration = createAsyncThunk(
  "integrationSettins",
  async () => {
    const response =
      await ExpenseIntegrationService.getExpenseIntegrationSettings();
    return response;
  }
);

export const fetchAccounts = createAsyncThunk("accounts", async () => {
  const response = await ExpenseIntegrationService.getAccounts();
  return response;
});

export const ExpenseIntegrationSlice = createSlice({
  name: "expenseIntegration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpenseIntegration.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchExpenseIntegration.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload;
      })
      .addCase(fetchAccounts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        state.status = "idle";
        state.formData.accounts = action.payload;
      });
  },
});

export const selectIntegrationSettings = (state) =>
  state.expenseIntegration.data;

export const selectAccounts = (state) =>
  state.expenseIntegration.formData.accounts;

export default ExpenseIntegrationSlice.reducer;
