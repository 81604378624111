import { Component } from "react";
import RouteManager from "../../managers/RouteManager";

import {
  DKIcons,
  DKIcon,
  DKButton,
  DKLabel,
  DKStatus,
  showToast,
  TOAST_TYPE,
  DKTooltipWrapper,
} from "deskera-ui-library";
import ApiConstants from "../../utils/ApiConstants";
import AppManager from "../../managers/AppManager";
import FeedbackPopup from "../common/FeedbackPopup";
import ic_credit_card from "../../assets/icons/ic_credit_card.png";
import TenantManager from "../../managers/TenantManager";
import ic_info_warning from "../../assets/icons/ic_info_gray.svg";
import { CONSTANTS } from "../../utils/Constants";
import ic_copy from "../../assets/icons/ic_copy.png";
import {
  isUSCompliance,
} from "../../managers/common/CommonUtil";
import PaymentOptions from "./PaymentOptions";

export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.settingOptions = [
      {
        title: "My Profile",
        description:
          "See your profile details and explore our other products too",
        icon: DKIcons.ic_user,
        iconTitle: "",
        url: ApiConstants.PRODUCT_URL_GO,
        buttonTitle: "View details",
        navigateTo: true,
        hasInfo: false,
        isVisible: true,
        redirectTo: "_blank",
      },
      {
        title: "Organization settings",
        description: "You can update or modify your organisation details here",
        icon: DKIcons.ic_home,
        iconTitle: "",
        url: ApiConstants.PRODUCT_URL_GO + "settings",
        buttonTitle: "View details",
        navigateTo: true,
        hasInfo: false,
        isVisible: true,
        redirectTo: "_blank",
      },
      {
        title: "User Management",
        description:
          "Add or modify users to your organisation. Users will be listed here",
        icon: DKIcons.ic_user_2,
        iconTitle: "",
        url: ApiConstants.PRODUCT_URL_GO + "users",
        buttonTitle: "View details",
        navigateTo: true,
        hasInfo: false,
        isVisible: true,
        redirectTo: "_blank",
      },
      {
        title: "Permissions",
        description:
          "Assign roles and permission to oraganisation's user different products",
        icon: DKIcons.ic_lock,
        iconTitle: "",
        url: ApiConstants.PRODUCT_URL_GO + "users",
        buttonTitle: "View details",
        navigateTo: true,
        hasInfo: false,
        isVisible: true,
        redirectTo: "_blank",
      },
      {
        title: "Subscription / Billing",
        description: "Manage your subscription, change plan and upgrade here",
        icon: DKIcons.ic_cards,
        iconTitle: "",
        url: ApiConstants.PRODUCT_URL_GO + "billing",
        buttonTitle: "View details",
        navigateTo: true,
        hasInfo: false,
        isVisible: true,
        redirectTo: "_blank",
      },
      {
        title: "Two-Factor Authentication (2FA)",
        description:
          "Increase your account's security by enabling Two-Factor Authentication (2FA).",
        icon: DKIcons.ic_key,
        iconTitle: "",
        url: ApiConstants.PRODUCT_URL_GO + "security",
        buttonTitle: "View details",
        navigateTo: true,
        hasInfo: false,
        isVisible: true,
        redirectTo: "_blank",
      },
      {
        title: "Change Password",
        description: "You can change or modify your password here",
        icon: DKIcons.ic_key_2,
        iconTitle: "",
        url: ApiConstants.PRODUCT_URL_GO + "security",
        buttonTitle: "View details",
        navigateTo: true,
        hasInfo: false,
        isVisible: true,
        redirectTo: "_blank",
      },
      {
        title: "Choose payment option",
        description: "You need to choose your payment modes",
        icon: ic_credit_card,
        iconTitle: "",
        url: "PAYMENT_OPTIONS",
        buttonTitle: "View details",
        navigateTo: false,
        hasInfo: false,
        infoObject: this.getInfoObjectForCashfree(),
        isVisible: isUSCompliance() && (ApiConstants.PAYOUT_ENABLE === "true"),
        redirectTo: "_self",
      },
    ];

    this.state = {
      needFeedbackPopup: false,
      showSelectOrgPopUp: false,
      showPaymentOptionPopup: false,
    };
  }

  componentDidMount() {
    RouteManager.setPresenter(this);
  }

  getInfoObjectForCashfree() {
    let values = {};
    let tenantData = TenantManager.getPeopleTenantData();
    if (tenantData && tenantData.tenant) {
      if (tenantData.tenant.isCashfreeEnabled) {
        values["isCashFreeConnected"] = tenantData.tenant.isCashfreeEnabled;
      }
    }
    values["infoText"] = CONSTANTS.CASHFREE_INFO_TEXT;
    values["whiteListIp"] = ApiConstants.CASHFREE_WHITELIST_IP.split(";");
    values["webhooks"] = ApiConstants.CASHFREE_WEBHOOKS;
    values["ipRedirectUrl"] = ApiConstants.CASHFREE_WHITELIST_REDIRECT;
    values["webhooksRedirectUrl"] = ApiConstants.CASHFREE_WEBHOOKS_REDIRECT;
    return values;
  }

  onClose = () => {
    // this.setState({ showPaymentOptionPopup : false});
    this.setState({ showPaymentOptionPopup: false });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="parent-width">
        <DKLabel className="fs-l fw-m" text="Settings" />
        {this.getHelpSection()}
        <div className="row flex-wrap mt-l align-items-stretch">
          {this.getAllCards()}
        </div>
        {this.getFooter()}
        {this.state.needFeedbackPopup && (
          <FeedbackPopup onCancel={this.hideFeedbackPopup} />
        )}
        {/* <FeedbackPopup onCancel={this.hideFeedbackPopup}></FeedbackPopup> */}
        {this.state.showPaymentOptionPopup && (
          <PaymentOptions onClose={this.onClose} {...this.props} />
        )}
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  getAllCards() {
    let arr = [];
    this.settingOptions.forEach((obj, index) => {
      if (obj.isVisible) {
        arr.push(this.getCard(obj, index));
      }
    });
    return arr;
  }

  routeToPage(url, navigateTo, redirectTo) {
    if (navigateTo) {
      window.open(url, redirectTo);
    } else if (!navigateTo && url === "PAYMENT_OPTIONS") {
      this.setState({
        showPaymentOptionPopup: true,
      });
    }
  }

  showOrgSelectPopup() {
    this.setState({
      showSelectOrgPopUp: true,
    });
  }

  async copySelectedValue(value) {
    try {
      await navigator.clipboard.writeText(value);
      showToast("Copied", TOAST_TYPE.DEFAULT);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }

  getCard(setting, index) {
    return (
      <>
        <div
          className="bg-white border-radius-m border-m p-l mr-xl mb-xl column border-box shadow-s cursor-hand mobile-parent-width"
          style={{ minWidth: 330, width: "23%", minHeight: "220px" }}
          onClick={() => {
            // this.routeToPage(setting.url, setting.navigateTo, setting);
          }}
        >
          <div className="row">
            <DKIcon src={setting.icon} className="" />
            <DKLabel text={setting.iconTitle} className="ml-s fw-m fs-m" />
            {setting.hasInfo && (
              <div
                className="d-flex ellipsis"
                style={{
                  alignItems: "center",
                  width: "max-content",
                }}
              >
                {setting.infoObject.isCashFreeConnected && (
                  <div className="d-flex pl-s fw-m">
                    <DKTooltipWrapper
                      content="Cashfree is configured"
                      tooltipClassName="warning-tooltip-prop"
                    >
                      <DKStatus color="bg-green" />
                    </DKTooltipWrapper>
                  </div>
                )}
                {!setting.infoObject.isCashFreeConnected && (
                  <div className="d-flex pl-s fw-m">
                    <DKTooltipWrapper
                      content="Cashfree is not configured"
                      tooltipClassName="warning-tooltip-prop"
                    >
                      <DKStatus color="bg-gray" />
                    </DKTooltipWrapper>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="row">
            <DKLabel text={setting.title} className="fw-m mt-m fs-m" />
            {setting.hasInfo && setting.infoObject && (
              <DKTooltipWrapper
                content={setting.infoObject.infoText}
                tooltipClassName="warning-tooltip-prop"
              >
                <DKIcon src={ic_info_warning} className="ic-s mt-m pl-xs" />
              </DKTooltipWrapper>
            )}
          </div>
          {setting.hasInfo && (
            <div className="ellipsis">
              {setting.infoObject && (
                <>
                  <div className="">
                    <div className="d-flex" style={{ alignItems: "baseline" }}>
                      <DKLabel text={"WhiteList IP:"} className="mt-s" />
                      <div
                        className="pl-xs fw-m"
                        style={{ textAlign: "start" }}
                      >
                        {setting.infoObject.whiteListIp.map((ip, index) => {
                          return (
                            <u key={ip} style={{ display: "flex" }}>
                              <a
                                href={setting.infoObject.ipRedirectUrl}
                                target="_blank"
                                className="text-app-color"
                              >
                                {ip}
                              </a>
                              <div>
                                <DKTooltipWrapper
                                  content="Copy"
                                  tooltipClassName="warning-tooltip-prop width-tooltip-copy "
                                >
                                  <DKIcon
                                    src={ic_copy}
                                    className="ic-xs pl-s"
                                    onClick={() => this.copySelectedValue(ip)}
                                  />
                                </DKTooltipWrapper>
                              </div>
                            </u>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="d-flex" style={{ alignItems: "baseline" }}>
                      <DKLabel text={"Webhooks URL:"} className="mt-s" />
                      <div
                        className="ellipsis pl-xs fw-m text-blue"
                        style={{ width: "35%" }}
                      >
                        <DKTooltipWrapper
                          content={setting.infoObject.webhooks}
                          tooltipClassName="warning-tooltip-prop ellipsis"
                          className="ellipsis"
                        >
                          <u>
                            <a
                              href={setting.infoObject.webhooksRedirectUrl}
                              target="_blank"
                              className="text-app-color"
                            >
                              {setting.infoObject.webhooks}
                            </a>
                          </u>
                        </DKTooltipWrapper>
                      </div>
                      <div className="fw-m">
                        <DKTooltipWrapper
                          content="Copy"
                          tooltipClassName="warning-tooltip-prop width-tooltip-copy "
                        >
                          <DKIcon
                            src={ic_copy}
                            className="ic-xs pl-s"
                            onClick={() =>
                              this.copySelectedValue(
                                setting.infoObject.webhooks
                              )
                            }
                          />
                        </DKTooltipWrapper>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {!setting.hasInfo && (
            <DKLabel
              text={setting.description}
              className="text-dark-gray mt-xs"
              style={{ height: 40 }}
            />
          )}
          <div
            className="row-reverse"
            style={{ justifyContent: "space-between", marginTop: "auto" }}
          >
            <div>
              <DKButton
                title={setting.buttonTitle}
                className="align-self-end bg-gray1 border-m mt-xl"
                icon={DKIcons.ic_arrow_right2}
                onClick={() => {}}
                isReverse={true}
                onClick={() => {
                  this.routeToPage(
                    setting.url,
                    setting.navigateTo,
                    setting.redirectTo
                  );
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  getHelpSection() {
    return (
      <div className="column bg-deskera-secondary p-r border-radius-m border-m mt-m banner-holder">
        <DKLabel text='<span style="font-size:20px">🙋‍♂️</span> Need help getting started? Learn more about Expense setting options.' />

        <div className="row mt-r ml-l">
          <DKButton
            className="border-m bg-white"
            title="Read"
            onClick={AppManager.showComingSoonWarning}
          />
          <DKButton
            className="border-m ml-r bg-white"
            title="Watch"
            onClick={AppManager.showComingSoonWarning}
          />
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  getFooter() {
    return (
      <div className="mt-xl parent-width mb-xxl">
        <DKLabel className="text-align-center fw-m" text="Contact us" />
        <DKLabel
          className="text-align-center mt-s text-gray"
          text="If you have any other query or problem please feel free to contact us"
        />
        <div className="cursor-hand" onClick={this.showFeedbackPopup}>
          <DKLabel
            className="text-align-center text-blue mt-r"
            text="<u>care@deskera.com</u>"
          />
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  showFeedbackPopup = () => {
    this.setState({ needFeedbackPopup: true });
  };
  hideFeedbackPopup = () => {
    this.setState({ needFeedbackPopup: false });
  };

  hideSelectOrgPopup = () => {
    this.setState({ showSelectOrgPopUp: false });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
}
