import { useRef } from "react";
import {
  acceptedResumeFormat,
} from "../../utils/EximsConstants";
import {
  DKLabel,
  DKButton,
  showToast,
  TOAST_TYPE,
  DKIcons,
} from "deskera-ui-library";
import UploadCloud from "../../assets/images/upload_cloud_outlined.svg";
import UploadCloudFilled from "../../assets/images/upload_cloud_filled.svg";

const FileImporter = (props) => {
  const uploadInput = useRef();
  const cloudImageRef = useRef();

  const isFileAcceptable = (selectedFile) => {
    if (!selectedFile) return false;
    if (props.acceptFileFormat) {
      return props.acceptFileFormat.some((acceptedFormat) =>
        selectedFile.name.includes(acceptedFormat)
      );
    } else {
      return acceptedResumeFormat.some((acceptedFormat) =>
        selectedFile.name.includes(acceptedFormat)
      );
    }
  };
  const styles = { paddingLeft: 37, paddingRight: 37 };
  const fileTypesAccepted = props.acceptFileFormat
    ? props.acceptFileFormat.join()
    : ".pdf,.doc,.docx";
  const handleFiles = (selectedFiles) => {
    if (!selectedFiles.length) {
      showToast("File not found", TOAST_TYPE.FAILURE);
    }

    const selectedFile = selectedFiles[0];
    if (isFileAcceptable(selectedFile)) {
      //   showLoader("Processing the uploaded file...");
      props.getFile({
        fileInfo: {
          name: selectedFile.name,
          size: selectedFile.size,
          mimeType: selectedFile.type,
        },
        fileContent: selectedFile,
        /* Reset mapped values whenever new file is uploaded */
        mappedHeaders: null,
        importRecords: null,
        invalidRows: null,
      });
      /* Parsing the file at Client End */
    } else {
      showToast(
        `Only ${fileTypesAccepted} file types are allowed`,
        TOAST_TYPE.FAILURE
      );
    }
  };

  const onUploadInputClicked = (event) => {
    const selectedFiles = event.target.files;
    handleFiles(selectedFiles);
  };

  const onFileDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    cloudImageRef.current.src = UploadCloud;
    const files = event.dataTransfer.files;
    handleFiles(files);
  };

  const onDragenter = (e) => {
    e.stopPropagation();
    e.preventDefault();
    cloudImageRef.current.src = UploadCloudFilled;
  };

  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  function getImportPortal() {
    return (
      /* upload area */
      <div
        className="column parent-width bg-gray1 border-m border-radius-s align-items-center p-v-r p-h-l m-v-xl cursor-hand"
        onDragEnter={onDragenter}
        onDragOver={onDragOver}
        onDrop={onFileDrop}
        onClick={() => {
          uploadInput.current.click();
        }}
      >
        <img
          style={{ height: "100px", width: "200px" }}
          className="m-r"
          src={UploadCloud}
          ref={cloudImageRef}
          alt="Upload Cloud"
        />

        <div className="row parent-width justify-content-center position-relative">
          <DKLabel
            text={`Drop ${
              props.acceptFileFormat && props.acceptFileFormat.length > 6
                ? "file "
                : fileTypesAccepted
            } `}
          />
          <DKButton
            className="fw-m border-none bg-white border-m ml-r"
            title="Choose a file"
            icon={DKIcons.ic_document}
            onClick={() => {
              //uploadInput.current.click();
            }}
          />
          <input
            className="parent-width parent-height position-absolute"
            style={{ visibility: "hidden" }}
            type="file"
            accept={fileTypesAccepted}
            onChange={onUploadInputClicked}
            ref={uploadInput}
          />
        </div>

        {/* <DKButton icon={DownloadIcon} title="Download Sample File" onClick={() => {}}/> */}
      </div>
    );
  }

  function getFileInfoPanel() {
    return props.fileInfo ? (
      <div className="column parent-width mb-r">
        <DKLabel text="File details" className="fw-m" />
        {!props.isMultiSelect && (
          <div className="row justify-content-between bg-gray1 border-m p-m border-radius-s mt-r">
            <DKLabel
              text={props.fileInfo.name}
              className="line-break-anywhere"
            />
            <DKLabel
              className="text-blue"
              text={`${Number(props.fileInfo.size) / 1000} Kb`}
            />
          </div>
        )}
        {props.isMultiSelect && getFileDetails()}
      </div>
    ) : null;
  }
  function getFileDetails() {
    let content = props.fileInfo.map((file) => {
      return (
        <div className="row justify-content-between bg-gray1 border-m p-m border-radius-s mt-r">
          <DKLabel
            text={file.fileContent.name}
            className="line-break-anywhere"
          />
          <DKLabel
            className="text-blue"
            text={`${Number(file.fileContent.size) / 1000} Kb`}
          />
        </div>
      );
    });
    return content;
  }

  return (
    <div
      className={`column align-items-center bg-white border-radius-s shadow-s ${
        props.isForPopup ? "" : "p-l"
      }`}
      style={props.isForPopup ? { width: "550px" } : styles}
    >
      <DKLabel text="File Uploader" className="fw-m fs-m" />
      <DKLabel
        text={props.title ? props.title : "Upload a resume you want to submit"}
        className="text-gray mt-s text-align-center"
      />
      {getImportPortal()}
      {getFileInfoPanel()}
    </div>
  );
};

export default FileImporter;
