import { showLoader, removeLoader } from "deskera-ui-library";
import ApiConstants from "../utils/ApiConstants";
import http from "../http";

export default class CustomDomainService {
  static save(data) {
    showLoader("Saving... Please wait");
    return http
      .post(ApiConstants.URL.CUSTOM_DOMAIN.DOMAIN_URL, data)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }

  static getData(query, onSuccess, onError) {
    showLoader("Fetching... Please wait");
    return http
      .get(ApiConstants.URL.CUSTOM_DOMAIN.DOMAIN_URL)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
}
