import { INPUT_TYPE } from "deskera-ui-library";
import { supportedMimeTypes, bufferTypes } from "../utils/EximsConstants";
import ApiConstants from "../utils/ApiConstants";
import { CONSTANTS, IMPORT_USERS_COUNTRY } from "../utils/Constants";
import { TENANT, TenantService } from "../services/TenantMangerService";
import { COUNTRY } from "../utils/CountryConstant";

export function getFile(blobContent, fileName, fileType) {
  const file = new File(
    [blobContent],
    `${fileName}_${new Date().getTime()}.${fileType}`,
    { type: getMimeType(fileType) }
  );

  return file;
}

export function generateBlob(content, fileType) {
  return import("xlsx").then((XLSX) => {
    const workSheet = XLSX.utils.json_to_sheet(content);
    const workBook = {
      Sheets: { Sheet1: workSheet },
      SheetNames: ["Sheet1"],
    };
    const excelBuffer = XLSX.write(workBook, {
      bookType: fileType,
      type: getBufferType(fileType),
    });
    return new Blob([excelBuffer], { type: getMimeType(fileType) });
  });
}

export function triggerDownload(blobContent, fileName, altSrc) {
  const downloadAnchor = document.createElement("a");
  // const filePath = `${assets}/employee_template.xlsx`;
  downloadAnchor.setAttribute("download", fileName);
  /* Supporting IE */
  if (blobContent && navigator.msSaveBlob) {
    navigator.msSaveBlob(blobContent, fileName);
  } else if (downloadAnchor.download !== undefined) {
    /* Checking If download feature supported in browser */
    downloadAnchor.href = blobContent
      ? URL.createObjectURL(blobContent)
      : altSrc;
    document.body.appendChild(downloadAnchor);
    downloadAnchor.click();
    document.body.removeChild(downloadAnchor);
  }
}

function getMimeType(fileType) {
  return supportedMimeTypes[fileType] || "text/csv";
}

function getBufferType(fileType) {
  return bufferTypes[fileType] || "array";
}

export function exportDataHandler(
  exportData,
  fileType,
  fileTitle = "download"
) {
  return new Promise((resolve, reject) => {
    let mimeType = getMimeType(fileType);
    if (!exportData || !mimeType) {
      reject("Please select data or valid file type to proceed");
    }
    try {
      generateBlob(exportData, fileType).then((blobContent) => {
        triggerDownload(blobContent, `${fileTitle}.${fileType}`);
        resolve("File generated successfully");
      });
    } catch (err) {
      reject(err);
    }
  });
}

export function getProcessedData(tableHeaders, tableRows) {
  const processedData = tableRows.map((row) => {
    const processedRow = {};

    tableHeaders.forEach((exportHeader) => {
      let displayValue = "";

      if (
        exportHeader.type === INPUT_TYPE.SELECT ||
        exportHeader.type === INPUT_TYPE.MULTI_SELECT
      ) {
        const selectedOptions = row[exportHeader.id];

        if (selectedOptions?.length) {
          const options = exportHeader.options;
          const displayOptions = selectedOptions.reduce(
            (computedVal, optionId) => {
              const selectedOption = options.filter(
                (option) => option.id === optionId
              );
              return selectedOption.length
                ? `${computedVal}${selectedOption[0].name},`
                : computedVal;
            },
            ""
          );

          displayValue = displayOptions.slice(0, -1);
        } else {
          displayValue = "";
        }
      } else {
        displayValue = row[exportHeader.id] || "";
      }

      processedRow[exportHeader.name] = displayValue;
    });

    return processedRow;
  });

  return processedData;
}

export function getExportDataFile(
  queryParamObj,
  payload = { filter: null, ids: null },
  options,
  endPoint = null
) {
  const url = getRequestURL(
    endPoint ? endPoint : "EXPORT_FILE",
    queryParamObj,
    options.endPointOptions
  );
  const requestOptions = getApiRequestOptions("POST", payload, "json");

  return fetch(url, requestOptions)
    .then((res) => res.blob())
    .then((blob) => {
      if (blob) {
        const file = getFile(blob, options.fileName, options.fileType);
        triggerDownload(file, file.name);
      } else {
        Promise.reject("No records found to export.");
      }
    })
    .catch((err) =>
      Promise.reject("Export records failed, please try again later.")
    );
}

export function getEmployeeExportDataFile(queryParamObj, data) {
  const { BASE, IMPORT_EXPORT } = ApiConstants.URL;
  const url = BASE + "v1/exim/employees/export?format=XLSX";
  const requestOptions = getApiRequestOptions("POST", data, "json");

  return fetch(url, requestOptions);
}

export function getRequestURL(
  endPointKey,
  queryParamObj,
  endPointOptions = null
) {
  const { BASE, IMPORT_EXPORT } = ApiConstants.URL;
  let queryParams = "";

  if (queryParamObj && Object.keys(queryParamObj).length) {
    queryParams = `?${new URLSearchParams(queryParamObj).toString()}`;
  }

  let endPoint = IMPORT_EXPORT[endPointKey];

  if (endPointOptions) {
    endPoint = endPoint(endPointOptions);
  }

  return `${BASE}${endPoint}${queryParams}`;
}

export function getApiRequestOptions(method, body = null, type) {
  const requestOptions = {
    method: method,
    credentials: "include",
    withCredentials: true,
    mode: "cors",
  };

  if (type === "json") {
    return {
      ...requestOptions,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
  } else if (method === "POST" || method === "PUT") {
    return {
      ...requestOptions,
      body,
    };
  }

  return requestOptions;
}

export function onDownload(importType) {
  const fileUrl = getDownLoadTemplate(importType);
  const link = document.createElement("a");
  if (fileUrl.length) {
    link.download = fileUrl[0];
    link.href = fileUrl[1];
    link.click();
  }
}

export function getDownLoadTemplate(importType) {
  return getDefaultImportTemplate(importType);
}

export function getDefaultImportTemplate(importType) {
  const countryCode = TenantService.get(TENANT.COUNTRY_CODE);
  let fileArray = [];
  let fileName = "";
  let filePath = "";

  if (IMPORT_USERS_COUNTRY.includes(countryCode)) {
    switch (importType) {
      case CONSTANTS.EMPLOYEE:
        switch (countryCode) {
          case COUNTRY.IN:
            // filePath = IN_EMPLOYEE;
            break;
          case COUNTRY.US:
            // filePath = US_EMPLOYEE;
            break;
          default:
            // filePath = Default_Employee;
            break;
        }

        fileName = "employee" + "_" + "template.xlsx";
        fileArray.push(fileName);
        fileArray.push(filePath);
        return fileArray;
      case CONSTANTS.COMPONENT:
        switch (countryCode) {
          case COUNTRY.IN:
            // filePath = IN_COMPONENT;
            break;
          case COUNTRY.US:
            // filePath = US_COMPONENT;
            break;
          default:
            // filePath = Default_Component;
            break;
        }
    }
  }
}
