export const commonCustomEvent = {
    on: (ev: string, cb: (data : any) => void) => {
        document.addEventListener(ev, (data: any) => cb(data.detail));
    },
    dispatch: (ev: string, data: any) => {
        document.dispatchEvent(new CustomEvent(ev, { detail: data }));
    },
    remove: (ev: string, cb: (data :any) => void) => {
        document.removeEventListener(ev, cb);
    }
}

export const COMMON_EVENTS = {
    ADD_NEW_REC : 'addNewRec',
    NEW_MSG: 'newMsg',
    REC_PERMISSION_CHANGE: 'recPermissionChange',
    THREAD_CLOSED: 'threadClosed',
    NEW_THREAD:'newThread',
    SUBSCRIPTION_CHECK: "onAuthorizationCheckStateChanged"
}

export const newThreadCustomEvent = {
    on: (ev: string, cb: (data : any) => void) => {
        document.addEventListener(ev, (data: any) => cb(data.detail));
    },
    dispatch: (ev: string, data: any) => {
        document.dispatchEvent(new CustomEvent(ev, { detail: data }));
    },
    remove: (ev: string, cb: (data: any) => void) => {
        document.removeEventListener(ev, cb);
    }
}