import {
    INPUT_VIEW_DIRECTION,
    INPUT_TYPE
} from "deskera-ui-library";

export const NEW_CLIAM_FORM_FILED = [
    {
        key: "spendDate",
        columnCode: "spendDate",
        options: [],
        icon: "",
        validation: null,
        isValid: true,
        name: "Date",
        type: INPUT_TYPE.DATE,
        errorMessage: null,
        direction: INPUT_VIEW_DIRECTION.HORIZONTAL,
        required: true,
        readOnly: false,
        uiVisible: true
    },
    {
        key: "merchant",
        columnCode: "merchant",
        options: [],
        icon: "",
        validation: null,
        isValid: true,
        name: "Merchant",
        type: INPUT_TYPE.TEXT,
        errorMessage: null,
        direction: INPUT_VIEW_DIRECTION.HORIZONTAL,
        required: true,
        readOnly: false,
        uiVisible: true
    },
    {
        key: "totalAmount",
        columnCode: "totalAmount",
        options: [],
        icon: "",
        validation: {
            regExp: `\[0-9]+$`
        },
        isValid: true,
        name: "Total Amount",
        type: INPUT_TYPE.NUMBER,
        errorMessage: null,
        direction: INPUT_VIEW_DIRECTION.HORIZONTAL,
        required: true,
        readOnly: false,
        uiVisible: true
    },
    {
        key: "taxCode",
        columnCode: "taxCode",
        options: [],
        icon: "",
        validation: null,
        isValid: true,
        name: "Tax Code",
        type: INPUT_TYPE.DROPDOWN,
        errorMessage: null,
        direction: INPUT_VIEW_DIRECTION.HORIZONTAL,
        required: false,
        readOnly: false,
        uiVisible: true
    },
    {
        key: "taxAmount",
        columnCode: "taxAmount",
        options: [],
        icon: "",
        validation: null,
        isValid: true,
        name: "Tax Amount",
        type: INPUT_TYPE.TEXT,
        errorMessage: null,
        direction: INPUT_VIEW_DIRECTION.HORIZONTAL,
        required: false,
        readOnly: true,
        uiVisible: true
    },
    {
        key: "categoryId",
        columnCode: "categoryId",
        options: [],
        icon: "",
        validation: null,
        isValid: true,
        name: "Category",
        type: INPUT_TYPE.DROPDOWN,
        errorMessage: null,
        direction: INPUT_VIEW_DIRECTION.HORIZONTAL,
        required: false,
        readOnly: false,
        uiVisible: true
    },
    {
        key: "otherDetails",
        columnCode: "otherDetails",
        options: [],
        icon: "",
        validation: null,
        isValid: true,
        name: "Description",
        type: INPUT_TYPE.LONG_TEXT,
        errorMessage: null,
        direction: INPUT_VIEW_DIRECTION.HORIZONTAL,
        required: false,
        readOnly: false,
        uiVisible: true
    },
];

export const EXPENSE_CLAIM_STATUS = {
    PENDING: "PENDING",
    DRAFT: "DRAFT",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED"
}

export const NEW_GROUP_FORM_FILED = [
    {
        key: "name",
        columnCode: "name",
        options: [],
        icon: "",
        validation: null,
        isValid: true,
        name: "Group Name",
        type: INPUT_TYPE.TEXT,
        errorMessage: null,
        direction: INPUT_VIEW_DIRECTION.HORIZONTAL,
        required: true,
        readOnly: false
    }
]

export const NEW_CATOGERY_FORM_FILED = [
    {
        key: "name",
        columnCode: "name",
        options: [],
        icon: "",
        validation: null,
        isValid: true,
        name: "Category Name",
        type: INPUT_TYPE.TEXT,
        errorMessage: null,
        direction: INPUT_VIEW_DIRECTION.HORIZONTAL,
        required: true,
        readOnly: false
    },
    {
        key: "group",
        columnCode: "group",
        options: [],
        icon: "",
        validation: null,
        isValid: true,
        name: "Available to Expense Groups",
        type: INPUT_TYPE.DROPDOWN,
        errorMessage: null,
        direction: INPUT_VIEW_DIRECTION.HORIZONTAL,
        required: true,
        readOnly: false,
        multiSelect: true
    }
]

export const MOVE_USER_FIELD = [
    {
        key: "name",
        columnCode: "name",
        options: [],
        icon: "",
        validation: null,
        isValid: true,
        name: "Select a group to move user",
        type: INPUT_TYPE.DROPDOWN,
        errorMessage: null,
        direction: INPUT_VIEW_DIRECTION.HORIZONTAL,
        required: true,
        readOnly: false
    },
]
