import { BREAKPOINTS, VIEWPORT_SIZES } from "./Constants";

// eslint-disable-next-line import/no-anonymous-default-export
const getViewportSize = () => {
    const width = window.innerWidth;

    if (width <= BREAKPOINTS.SMALL) {
        return VIEWPORT_SIZES.EXTRA_SMALL;
    } else if (width <= BREAKPOINTS.LARGE) {
        return VIEWPORT_SIZES.SMALL;
    } else {
        return VIEWPORT_SIZES.LARGE;
    }
};

const isViewportLarge = () => getViewportSize() === VIEWPORT_SIZES.LARGE;

export { getViewportSize, isViewportLarge };

//Check for in-app webview.
export function isMobileWebView() {
    let standalone = ('standalone' in window.navigator) && (window.navigator['standalone']),
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent);

        if (ios && !standalone && !safari) {
            return true // iOS Webview
        } else if (userAgent.includes('wv')) {
            return true // android Webview
        }

    return false
}
