import React, { Component } from "react";
import { DKListPicker, TOAST_TYPE, showToast } from "deskera-ui-library";
import UserManager from "../../managers/UserManager";
import IAM from "../../services/Iam";
import AppManager from "../../managers/AppManager";
import { ADMIN } from "../../managers/common/RouteAuth";

/*
PROPS:
- onAddOrgClick
- onClose
- allOrgs
- tenantIds
*/

class OrgList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allOrgs: this.props.allOrgs,
      tenantIds: this.props.tenantIds,
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      allOrgs: nextProps.allOrgs,
      tenantIds: nextProps.tenantIds,
    });
  }
  render() {
    return (
      <div>
        <DKListPicker
          className="shadow-l"
          title="My Organisations"
          data={this.state.allOrgs}
          onSelect={(index, value) => {
            this.onSwitchOrgClick(index, value);
          }}
          onClose={this.props.onClose}
          needIcon={true}
          button={
            this.props.accessedBy === ADMIN
              ? {
                  title: "+ Add Organisation",
                  className: "bg-app text-white",
                  onClick: () => {
                    this.props.onAddOrgClick();
                  },
                }
              : null
          }
        />
      </div>
    );
  }

  onSwitchOrgClick = (index, value) => {
    if (
      this.state.allOrgs[index] === value &&
      this.state.allOrgs[index] !== 0
    ) {
      let listTenantID = this.state.tenantIds[index];
      if (listTenantID !== UserManager.getUserTenantID()) {
        IAM.switchTenant({ tenantId: listTenantID }).then(
          (res) => {
            AppManager.refreshApp();
          },
          (err) => {}
        );
      } else {
        this.setState({ needOrgListPicker: false });
        showToast("Current organisation", TOAST_TYPE.SUCCESS);
      }
    }
  };
}

export default OrgList;
