import UserService from "../services/UserService";

export default class ExpenseManager {
  static expenseUsers;
  static setUsers(users) {
    ExpenseManager.expenseUsers = users;
  }

  static getUsers() {
    return ExpenseManager.expenseUsers;
  }

  static getUserFromApi() {
    UserService.getUsers({ limit: 100, page: 0 }).then(
      (res) => {
        if (res && res.content) {
          let allUsers = res.content.map((r) => {
            r.name = `${r.firstName} ${r.lastName}`;
            return r;
          });
          ExpenseManager.setUsers(allUsers);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
