import { CONSTANTS, COUNTRY_CODE } from "../../utils/Constants";
import moment from "moment";
import { ZIPCODE_REGEX } from "../../utils/RegexConstant";
import Utility from "../../utils/Utility";
import TenantManager from "../TenantManager";
import { TENANT, TenantService } from "../../services/TenantMangerService";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export function getUserProfilePic(options) {
  var canvas = document.createElement("canvas");
  var textWidth;
  canvas.style.display = "none";
  canvas.width = options.width ? options.width : 42;
  canvas.height = options.height ? options.height : 42;
  document.body.appendChild(canvas);
  var context = canvas.getContext("2d");
  context.fillStyle = options.backgroundColor
    ? options.backgroundColor
    : "#f7f7f7";
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.font = options.font ? options.font : "bold 14px Arial";
  context.fillStyle = options.textColor ? options.textColor : "#636363";
  context.textBaseline = options.textBaseline ? options.textBaseline : "middle";
  context.textAlign = options.textAlign ? options.textAlign : "center";
  var first, last;
  var name = options.name.trim().split(" ");
  if (name) {
    first = name[0] && name[0] != "" ? name[0] : null;
    last = name[1] && name[1] != "" ? name[1] : null;
    if (last) {
      var initials = first.slice(0, 1) + last.slice(0, 1);
      textWidth = context.measureText(initials).width;
      context.fillText(
        initials.toUpperCase(),
        canvas.width / 2,
        canvas.height / 2
      );
      var data = canvas.toDataURL();
      document.body.removeChild(canvas);
      return data;
    } else {
      if (first) {
        var initials = first.slice(0, 2);
        textWidth = context.measureText(initials).width;
        context.fillText(
          initials.toUpperCase(),
          canvas.width / 2,
          canvas.height / 2
        );
        var data = canvas.toDataURL();
        document.body.removeChild(canvas);
        return data;
      } else {
        return "../../assets/images/Default-Profile-pic.svg";
      }
    }
  } else {
    return "../.../assets/images/Default-Profile-pic.svg";
  }
}

export function getIncompleteFieldName(field) {
  let fieldName = field;
  switch (field) {
    case "address":
      fieldName = "Address";
      break;
    case "compensation":
      fieldName = "Compensation";
      break;
    case "compensationPeriod":
      fieldName = "Compensation Period";
      break;
    case "ctc":
      fieldName = "CTC";
      break;
    case "dateOfBirth":
      fieldName = "Date of Birth";
      break;
    case "dateOfJoining":
      fieldName = "Date of Joining";
      break;
    case "designation":
      fieldName = "Job Title";
      break;
    case "emaildId":
      fieldName = "Email Id";
      break;
    case "firstName":
      fieldName = "First Name";
      break;
    case "lastName":
      fieldName = "Last Name";
      break;
    case "paymentDetails":
      fieldName = "Payment Details";
      break;
    case "phone":
      fieldName = "Phone";
      break;
  }

  return fieldName;
}

export function getQueryParam(location, param) {
  if (location?.search && param) {
    const query = new URLSearchParams(location.search);
    return query.get(param);
  }
  return null;
}

export function trimValue(value) {
  if (value && typeof value === "string") {
    return value.trim();
  } else {
    return value;
  }
}

export function convertDateToJson(date) {
  let jsonDate;
  if (date) {
    if (typeof date === "string" && !isNaN(Date.parse(date))) {
      const dateObj = new Date(date);
      jsonDate = new Date(
        dateObj.getTime() - dateObj.getTimezoneOffset() * 60000
      ).toJSON();
    } else if (typeof date === "object") {
      jsonDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      ).toJSON();
    }
  }

  return jsonDate;
}

export function isInCompliance(countryCode) {
  return countryCode === "IN";
}

export function getFormattedAmount(value) {
  let amount = value ? value : 0;
  if (typeof amount === "string") {
    amount = parseFloat(amount);
    amount = amount.toFixed(2);
  } else {
    amount = amount.toFixed(2);
  }
  return amount;
}
export function isValidZipcode(value, countryCode) {
  let isValid = false;
  switch (countryCode) {
    case COUNTRY_CODE.INDIA:
      const regexZIP = new RegExp(ZIPCODE_REGEX.IND);
      isValid = !regexZIP.test(value);
      return isValid;
    default:
      isValid = true;
  }
  return isValid;
}

export function getSelectedIdFromDropDown(value, options, key) {
  let selectedId = "";
  if (value && Array.isArray(value)) {
    value.forEach((element) => {
      let dropdownValues = options[element];
      if (dropdownValues && dropdownValues[key]) {
        selectedId = dropdownValues[key];
      }
    });
  }
  return selectedId;
}

export function getSelectedIdFromObject(value, key) {
  let selectedId = "";
  if (!Utility.isEmpty(value) && typeof value === "object") {
    selectedId = value[key];
  }
  return selectedId;
}

export function removeFirstDecimalFromInput(value) {
  let formattedValue = value;
  if (!Utility.isEmpty(value)) {
    if (typeof value === "string" && value.length > 1) {
      formattedValue = value.replace(/^0+/, "");
      // formattedValue = numberValue.toString();
    } else if (typeof value === "number") {
      let stringValue = value.toString();
      if (stringValue.length > 1) {
        let newValue = stringValue.replace(/^0+/, "");
        formattedValue = parseFloat(newValue);
      }
    }
  }
  return formattedValue;
}

export function isUSCompliance() {
  const { tenant } = TenantManager.getPeopleTenantData();
  const countryCode = tenant && tenant.countryCode ? tenant.countryCode : "";
  return countryCode === COUNTRY_CODE.US;
}

export function isIndiaCompliance() {
  const { tenant } = TenantManager.getPeopleTenantData();
  const countryCode = tenant && tenant.countryCode ? tenant.countryCode : "";
  return countryCode === COUNTRY_CODE.INDIA;
}

export function isBenefitEnabled() {
  const { tenant } = TenantManager.getPeopleTenantData();
  const countryCode = tenant && tenant.countryCode ? tenant.countryCode : "";
  return countryCode === COUNTRY_CODE.US;
}

export function isEffectiveSalaryApplied(
  isEffectiveSalary,
  visibleCompensation
) {
  if (visibleCompensation === undefined || visibleCompensation === null) {
    return false;
  }
  let effectiveDate = visibleCompensation.effectiveDate;
  if (effectiveDate != "" && isEffectiveSalary) {
    if (moment().isSameOrAfter(effectiveDate)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
export function getCPFYears() {
  let cpfYears = [];
  if (TenantService.get(TENANT.DATE_OF_CREATION)) {
    let tenantCreationYear = new Date(
      TenantService.get(TENANT.DATE_OF_CREATION)
    ).getFullYear();
    while (tenantCreationYear <= new Date().getFullYear()) {
      cpfYears.push(tenantCreationYear.toString());
      tenantCreationYear++;
    }
  }

  return cpfYears;
}
export function getMaskedValue(value, field) {
  switch (field) {
    case "socialSecurityNumber":
      //eg: '212-23-2333'
      if (value && value.length === 9) {
        let sociNo = `${value.substring(0, 3)}-${value.substring(
          3,
          5
        )}-${value.substring(5, 9)}`;
        return sociNo;
      } else {
        return value;
      }
    default:
      return value;
  }
}

export function getTotalWorkHoursInMonth(workWeek) {
  let totalWorkHours = 0;
  const daysInMonth = moment().daysInMonth();
  for (var i = 1; i <= daysInMonth; i++) {
    const day = moment().date(i);
    workWeek.forEach((w) => {
      if (w.isWorkingDay && w.day === day.format("dddd").toLowerCase()) {
        totalWorkHours += w.duration;
      }
    });
  }

  return totalWorkHours;
}

export function downloadAsZip(blobDataArray, zipFileName) {
  var zip = new JSZip();
  blobDataArray.forEach((file) => {
    zip.file(file.name, file);
  });

  //may use in future FOR IMAGE FILE

  // zip.file("Hello1.pdf", blobData);
  // var img = zip.folder("images");
  // img.file("smile.gif", imgData, {base64: true});

  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, zipFileName);
  });
}

export function getTotalWorkingDaysBetweenDays(from, to, workWeek) {
  let totalWorkingDays = 0;
  var now = moment(from).clone(),
    dates = [];

  while (now.isSameOrBefore(moment(to))) {
    dates.push(now.format("YYYY/MM/DD"));
    now.add(1, "days");
  }
  dates.forEach((d) => {
    workWeek.forEach((w) => {
      if (
        w.isWorkingDay &&
        w.day === moment(d, "YYYY/MM/DD").format("dddd").toLowerCase()
      ) {
        totalWorkingDays += 1;
      }
    });
  });

  return totalWorkingDays;
}

export const decodeBase64Uri = (
  encodedData
) => {
  return decodeURIComponent(
    Buffer.from(encodedData, "base64").toString("ascii")
  );
};

export const encodeBase64Uri = (
  decodedData
) => {
  return Buffer.from(encodeURIComponent(decodedData), "ascii").toString(
    "base64"
  );
};
