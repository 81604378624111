import ApiConstants from "../utils/ApiConstants";
import { COMPANY_DATE_FORMATS, FORMAT } from "../utils/Constants";
import { COUNTRY } from "../utils/CountryConstant";
import http from "../http";
import { TENANT, TenantService } from "../services/TenantMangerService";

class CompanyDetailService {
  static getCompanyDetail() {
    return http
      .get(ApiConstants.URL.BASE + ApiConstants.URL.PEOPLE.TENANT)
      .catch((err) => {
        console.error("Error loading Company details: ", err);
        return Promise.reject(err);
      });
  }

  static updateCompanyDetail(param) {
    return http.put(
      ApiConstants.URL.BASE + ApiConstants.URL.PEOPLE.TENANT,
      param
    );
  }

  static getTenantConfig() {
    return http
      .get(ApiConstants.URL.BASE + ApiConstants.URL.PEOPLE.CONFIG)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
  }

  static updateTenantConfig(payload) {
    return http
      .patch(ApiConstants.URL.BASE + ApiConstants.URL.PEOPLE.CONFIG, payload)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
  }

  static setDateFormat(dateFormat) {
    if (dateFormat) {
      TenantService.set(TENANT.DATE_FORMAT, dateFormat.toUpperCase());
      TenantService.set(
        TENANT.SHORT_DATE_FORMAT,
        COMPANY_DATE_FORMATS.find((f) => f.label === dateFormat.toUpperCase())
          .short
      );
    } else {
      if (TenantService.get(TENANT.COUNTRY_CODE) === COUNTRY.US) {
        TenantService.set(TENANT.DATE_FORMAT, FORMAT.MMDDYYYY);
        TenantService.set(TENANT.SHORT_DATE_FORMAT, FORMAT.MMDD);
      } else {
        TenantService.set(TENANT.DATE_FORMAT, FORMAT.DDMMYYYY);
        TenantService.set(TENANT.SHORT_DATE_FORMAT, FORMAT.DDMM);
      }
    }
  }

  static setDecimalFormat(decimalFormat) {
    if (typeof decimalFormat === "string") {
      decimalFormat = !isNaN(parseInt(decimalFormat))
        ? parseInt(decimalFormat)
        : 2;
    }

    if (decimalFormat === -1) {
      decimalFormat = 2;
    }

    TenantService.set(TENANT.DECIMAL_FORMAT, decimalFormat);
  }

  static getCompliance(countryCode) {
    switch (countryCode) {
      case COUNTRY.IN:
        return {
          "@type": "type.googleapis.com/v1.INTenantCompliance",
          countryCode: countryCode,
        };
    }

    return null;
  }

  static getTenantSetupParams(params, countryObj) {
    params["countryCode"] = countryObj?.value;
    params["address"]["country"] = countryObj?.value;
    params["currency"] = countryObj?.currencyCode;
    params["orgSetupCompleted"] = true;
    params["refreshToken"] = TenantService.get(TENANT.REFRESH_TOKEN);
    params["compliance"] = CompanyDetailService.getCompliance(
      countryObj?.value
    );

    return params;
  }
  static updateBankDetail(param, id) {
    let url = `${ApiConstants.URL.BASE}${ApiConstants.URL.PEOPLE.BANK}/${id}`;
    return http.put(url, param);
  }
  static addBankAccount(param) {
    return http.post(
      ApiConstants.URL.BASE + ApiConstants.URL.PEOPLE.BANK,
      param
    );
  }
  static getBankDetail(param) {
    return http.get(
      `${ApiConstants.URL.BASE}${ApiConstants.URL.PEOPLE.BANK}?accountType=${param.accountType}`
    );
  }
  static getTenantNotificationSettings() {
    return http
      .get(
        ApiConstants.URL.BASE + ApiConstants.URL.PEOPLE.NOTIFICATION_SETTINGS
      )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
  }
  static setTenantNotificationSettings(data) {
    return http
      .post(
        ApiConstants.URL.BASE + ApiConstants.URL.PEOPLE.NOTIFICATION_SETTINGS,
        data
      )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
  }
}

export default CompanyDetailService;
