import React, { Component } from "react";
import { DKLabel, DKButton, DKSpinner } from "deskera-ui-library";

import RouteManager from "../../managers/RouteManager";
import DashboardCard from "../dashboard/DashboardCard";
import { TABLES, PRODUCTS, TableManger } from "../../managers/TableManager";
import { ADMIN, EMPLOYEE } from "../../managers/common/RouteAuth";
import { withTranslation } from "react-i18next";
import ic_settings from "../../assets/icons/ic_settings.png";
import CardRearrangePopup from "../CardRearrangePopup";
import DashboardService from "../../services/DashboardService";

const MODULES = [
  {
    name: TABLES.PEOPLE_EXPENSE_PENDING_CLAIMS,
    isGraphDesign: false,
  },
  {
    name: PRODUCTS.EXPENSE_TREND,
    isGraphDesign: true,
  },
  {
    name: TABLES.PEOPLE_EXPENSE_APPROVED_CLAIMS,
    isGraphDesign: true,
  },
  {
    name: TABLES.PEOPLE_EXPENSE_CLAIMS_BREAKDOWN_GROUP,
    isGraphDesign: true,
  },
  {
    name: TABLES.PEOPLE_EXPENSE_CLAIMS_BREAKDOWN_CATEGORY,
    isGraphDesign: true,
  },
  {
    name: TABLES.PEOPLE_EXPENSE_MY_CLAIMS,
    isGraphDesign: false,
  },
  {
    name: TABLES.PEOPLE_RECENT_PROCESSED_EXPENSE_CLAIMS,
    isGraphDesign: true,
  }
];

const MODULES_EMPLOYEE = [
  {
    name: TABLES.PEOPLE_EXPENSE_MY_CLAIMS,
    isGraphDesign: false,
  },
  {
    name: TABLES.PEOPLE_RECENT_PROCESSED_EXPENSE_CLAIMS,
    isGraphDesign: true,
  },
  {
    name: TABLES.PEOPLE_EXPENSE_CLAIMS_BREAKDOWN_CATEGORY,
    isGraphDesign: true,
  },
];
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRearrangePopup: false,
      sequence: null,
    };
  }

  componentDidMount() {
    RouteManager.setPresenter(this);
    this.fetchDashboardSettings();
    this.setState({ showRearrangePopup: false });
  }

  render() {
    return (
      <div className="parent-width">
        <div className="row justify-content-between">
          <DKLabel text={this.props.t("DASHBOARD")} className="fw-m fs-l" />
          {this.props.accessedBy === ADMIN && (
            <DKButton
              title="Rearrange"
              className="border-m bg-white"
              icon={ic_settings}
              onClick={() => this.toggleChartRearrangePopup()}
            />
          )}
        </div>
        <div className="row bg-deskera-secondary p-r border-radius-m border-m mt-m justify-content-between banner-holder">
          <DKLabel text='<span style=" font-size:18px">🧑🏻‍💻</span> Visit out community center to get all updates and learn about Expense <span style=" font-size:18px">🎉</span>' />
          <div>
            <div className="row flex-wrap">
              <DKButton
                className="border-m bg-white"
                title="Join Community 👏 "
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/groups/deskera/",
                    "_blank"
                  );
                }}
              />
              <DKButton
                className="border-m ml-r vertical-spacing bg-white"
                title="Learn Expense 🙇‍♂️"
                onClick={() => {
                  window.open(
                    "https://www.deskera.com/blog/tag/people/",
                    "_blank"
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="row flex-wrap mt-r justify-content-between align-items-stretch dashboard-card-wrapper pb-xxl">
          {this.state.sequence != null &&
            this.state.sequence.map(
              (module) =>
                (module.visible != null ? module.visible : true) && (
                  <DashboardCard
                    key={module.name}
                    module={module.name}
                    isGraphDesign={module.isGraphDesign}
                  />
                )
            )}
        </div>
        {this.state.showRearrangePopup && (
          <CardRearrangePopup
            onClose={() => this.toggleChartRearrangePopup()}
            sequence={this.state.sequence}
            onSequenceChange={(sequence) => this.onSequenceChange(sequence)}
          />
        )}
      </div>
    );
  }

  onSequenceChange(sequence) {
    this.setState({ sequence });
    this.updateDashboardSettings(sequence);
  }

  toggleChartRearrangePopup() {
    this.setState({ showRearrangePopup: !this.state.showRearrangePopup });
  }

  fetchDashboardSettings() {
    DashboardService.getDashboardSetting()
      .then((response) => {
        if (response?.dashboardSettingsExpense?.order?.length > 0) {
          const expenseSequence = this.getSequenceWithModules(
            response.dashboardSettingsExpense.order
          );
          this.setState({ sequence: expenseSequence });
        } else {
          this.setState({
            sequence:
              this.props.accessedBy === ADMIN ? MODULES : MODULES_EMPLOYEE,
          });
        }
      })
      .catch((err) => {
        this.setState({
          sequence:
            this.props.accessedBy === ADMIN ? MODULES : MODULES_EMPLOYEE,
        });
      });
  }

  updateDashboardSettings(sequence) {
    const data = { dashboardSettingsExpense: { order: sequence } };
    console.log(JSON.stringify(data));
    DashboardService.updateDashboardSetting(data)
      .then((response) => {
        if (response?.success) {
          this.fetchDashboardSettings();
        }
      })
      .catch((err) => {});
  }

  getSequenceWithModules(sequence) {
    const dashboardSequence = [];
    const modules = this.props.accessedBy === ADMIN ? MODULES : MODULES_EMPLOYEE
    modules.forEach((module) => {
      let index = sequence.findIndex(s => s.name === module.name);
      if(index !== -1) {
        dashboardSequence[index] = sequence[index];
      } else {
        let object = {
          isGraphDesign: module.isGraphDesign,
          name: module.name,
          visible: true
        }
        dashboardSequence[dashboardSequence.length] = object;
      }
    })

    return dashboardSequence.filter(function (el) {
      return el != null;
    });;
  }
}

export default withTranslation()(Dashboard);

export const getDashboardWidgetLoading = () => {
  return (
    <div className="parent-width parent-height row justify-content-center">
      <DKSpinner title="Loading..." />
    </div>
  );
};
