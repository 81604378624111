import React, { Component, Fragment } from "react";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import i18n from "../../i18n/i18n";
import { LanguageMenuList } from "../menu/LabelList/LanguageMenuList";
import LanguageList from "../menu/LanguageList";
import {
  DKButton,
  DKLabel,
  showLoader,
  removeLoader,
  DKIcon,
  DKIcons,
} from "deskera-ui-library";
import UserManager from "../../managers/UserManager";
import AppManager from "../../managers/AppManager";
import OrgList from "../common/OrgList";
import Tenant from "../../services/Tenant";
import TenantManager from "../../managers/TenantManager";
// import AddOrgPopup from "../common/AddOrgPopup";
import ApiConstants from "../../utils/ApiConstants";
// import NotificationPopup from "../notification/NotificationPopup";
// import AddNewList from "./AddNewList";
import ProfileOptionsSection from "../menu/ProfileOptionsSections";
import ic_profile_pic from "../../assets/menu/ic_profile_pic.png";
// import NotificationService, {
//   NotificationsUpdate,
// } from "../../services/NotificationService";
// import NotificationManager from "../../managers/NotificationManager";
import { ADMIN } from "../../managers/common/RouteAuth";
import {
  isMobileWebView,
  isViewportLarge,
} from "../../utils/ViewportSizeUtils";
import SideBarService from "../../services/Sidebar";
import InitialApiManager from "../../managers/InitialApiManager";
import Utility from "../../utils/Utility";
import { MOBILE_APP_ACTIONS } from "../../utils/Constants";
import SubscriptionAlert from "../subscription/SubscriptionAlert";
import { AUTHORIZATION_STATUS, PLANS } from "../../utils/Enum";
import {
  commonCustomEvent,
  COMMON_EVENTS,
} from "../../services/event/CommonEvents";
import IAM from "../../services/Iam";

import ic_startup from "../../assets/pricingSection/startup.svg";
import ic_essential from "../../assets/pricingSection/essential.svg";
import ic_professional from "../../assets/pricingSection/professional.svg";
import AddNewList from "../menu/AddNewList";
import MultipleExpensePopup from "../claims/MultipleExpensePopup";
// import SetupOrganization from "../SetupOrganization";
/*
- isOrgAvailable
*/

class TopMenu extends Component {
  constructor(props) {
    // const { t, i18n } = useTranslation();
    let defaultLanguage = LanguageMenuList.find(
      (lan) => lan.code === i18n.language
    );
    super(props);
    this.state = {
      needOrgListPicker: false,
      allOrgs: [],
      tenantIds: [],
      allTenantsReceived: false,
      orgBtnTapped: false,
      needNotificationPopup: false,
      needAddNewList: false,
      needLanguageList: false,
      selectedFlag: defaultLanguage,
      newNotification: false,
      planName: "",
      isDesktop: isViewportLarge(),
      subscriptionData: null,
      showCountrySelect: false,
      isNewClaims: false,
    };
  }

  componentDidMount() {
    if (!isMobileWebView()) {
      this.onWindowFocussed();
      AppManager.handleWindowFocusListeners(this.onWindowFocussed, true);
    }
    Tenant.getAllTenants()
      .then((res) => {
        let orgNames = [],
          ids = [];
        let orgs = TenantManager.getAllTenants();
        for (let index = 0; index < orgs.length; index++) {
          orgNames.push(orgs[index].tenantName);
          ids.push(orgs[index].tenantId);
        }

        this.setState(
          {
            allOrgs: orgNames,
            tenantIds: ids,
            allTenantResReceived: true,
          },
          () => {
            if (this.state.orgBtnTapped) {
              this.showListPicker();
              removeLoader();
            }
          }
        );
      })
      .catch((err) => {});
    InitialApiManager.getPeopleSubscription().then((response) => {
      if (response) {
        if (response["InTrial"]) {
          this.setState({ planName: "1 month FREE trial" });
        } else if (!response["InTrial"] && response["Subscribed"]) {
          this.setState({ planName: response["PlanName"] });
        } else {
          this.setState({ planName: "1 month FREE trial" });
        }
      } else {
        this.setState({ planName: "1 month FREE trial" });
      }
    });
    document.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("resize", this.windowSizeUpdated);
    // this.subscription = NotificationsUpdate.getMessage().subscribe(
    //   (message) => {
    //     this.getUnreadNotification();
    //   }
    // );
  }

  windowSizeUpdated = () => {
    this.setState({
      isDesktop: isViewportLarge(),
    });
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.hideListPicker();
    }
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("resize", this.windowSizeUpdated);
    // this.subscription.unsubscribe();
    if (!isMobileWebView()) {
      AppManager.handleWindowFocusListeners(this.onWindowFocussed, false);
    }
  }

  getProfileSection() {
    return (
      <div className=" position-relative ml-m">
        <div
          className="cursor-hand"
          onClick={() => {
            this.setState({ needProfileOptionsSection: true });
          }}
          style={{ opacity: 0.6 }}
        >
          <DKIcon src={ic_profile_pic} className="circle ic-r-3" />
        </div>

        {this.state.needProfileOptionsSection &&
          this.getProfileOptionsSection()}
      </div>
    );
  }
  getProfileOptionsSection() {
    return (
      <ProfileOptionsSection
        onClose={() => {
          this.setState({ needProfileOptionsSection: false });
        }}
        accessedBy={this.props.accessedBy}
      />
    );
  }
  onSubcritionClose() {
    this.setState({
      subscriptionData: null,
      showCountrySelect: true,
    });
  }
  // isOrganisationSetup() {
  //   return (
  //     <>
  //       {!this.props.isOrgSetupCompleted && (
  //         <SetupOrganization
  //           onComplete={() => {
  //             this.setState({
  //               showCountrySelect: false,
  //             });
  //           }}
  //         />
  //       )}
  //     </>
  //   );
  // }
  getSubscriptionAlert() {
    return this.state.subscriptionData?.needAlert ? (
      <SubscriptionAlert
        customMessage={this.state.subscriptionData.customMessage || ""}
        onClose={() => {
          this.onSubcritionClose();
        }}
      />
    ) : null;
  }
  onWindowFocussed = (e) => {
    if (isMobileWebView()) {
      return;
    }
    if (document.hidden) return;
    // Note: Need use in future
    // if (document.hidden || !AppManager.needSubscriptionApiCall) return;

    commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
      status: AUTHORIZATION_STATUS.PENDING,
    });

    IAM.checkIfUserSubscribed()
      .then(
        () => {
          /* Calling once on focus Only */
          AppManager.needSubscriptionApiCall = false;

          /* For triggering Top menu Rerender in case alert not opened,
          If Subscription alert already opened,
          updated message will get showcased through custom event  */
          if (!this.state.subscriptionData?.needAlert) {
            this.setState({
              subscriptionData: null,
            });
          }

          commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
            status: AUTHORIZATION_STATUS.AUTHORIZED,
          });
        },
        () => {
          /* Need to Show Subscription alert */
          this.setState({
            subscriptionData: {
              customMessage: "",
              needAlert: true,
            },
          });

          AppManager.needSubscriptionApiCall = true;

          commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
            status: AUTHORIZATION_STATUS.DENIED,
          });
        }
      )
      .catch((err) => {
        AppManager.needSubscriptionApiCall = true;
        commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
          status: AUTHORIZATION_STATUS.DENIED,
        });
      });
  };
  render() {
    const { isDesktop } = this.state;
    return (
      <>
        <div className="parent-width bg-white row justify-content-between p-h-r p-v-s shadow-s z-index-3 flex-shrink-0">
          <div
            className="dk-sidebar-menu-toggle p-v-s expandable-menu-button cursor-hand z-index-4 column justify-content-center display-only-mobile"
            onClick={() => SideBarService.toggleSideBar()}
          >
            <div className="row">
              <DKIcon src={DKIcons.ic_menu} className={`ic-s shadow-s-2`} />
              {isMobileWebView() && this.getTenantNameView()}
            </div>
          </div>
          <div className="row width-auto">{this.getNoCardView()}</div>
          {this.state.needOrgListPicker && this.renderListPicker()}
          {/* {this.state.showAddNewOrgPopup && this.showAddNewOrgPopup()} */}
          <div className="row width-auto">
            {isMobileWebView() && this.getMobileAppActions()}
            <div className="position-relative">
              {!isMobileWebView() && this.props.accessedBy === ADMIN && (
                <DKButton
                  icon={DKIcons.white.ic_add}
                  title={isDesktop ? "Create New" : ""}
                  className={`bg-button text-white ml-m ${
                    isDesktop ? "" : "circle"
                  }`}
                  onClick={this.toggleAddNewList}
                />
              )}
              {this.state.needAddNewList && (
                <div
                  className="transparent-background"
                  onClick={() => this.hideAddNewList()}
                />
              )}
              {this.state.needAddNewList && this.getAddNewList()}
            </div>
            {/* <div className="position-relative ml-m">
              {
                <>
                  {!isMobileWebView() && (
                    <DKButton
                      icon={DKIcons.ic_notification}
                      className="bg-gray2 border-radius-l "
                      onClick={() => {
                        this.showNotificationPopup();
                      }}
                    />
                  )}

                  {!isMobileWebView() && this.state.newNotification && (
                    <div
                      className="bg-red circle position-absolute p-xs"
                      style={{ top: -1, right: 1, backgroundColor: "red" }}
                    />
                  )}
                </>
              }
              {this.state.needNotificationPopup && (
                <div
                  className="transparent-background"
                  onClick={this.hideNotificationPopup}
                />
              )}
              {this.state.needNotificationPopup && this.getNotificationPopup()}
            </div> */}
            {false && (
              <div className="position-relative ml-m">
                <div
                  onClick={this.toggleLanguageList}
                  className="flex items-center cursor-pointer"
                  style={{ width: 30, height: 30 }}
                  id="language-list-popup-btn"
                >
                  <DKIcon
                    src={this.state.selectedFlag.icon}
                    className="circle ic-r-3"
                  />{" "}
                  <p
                    className="mr-m font-semibold"
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  ></p>
                </div>
                {this.state.needLanguageList && this.getLanguageList()}
              </div>
            )}
            {!isMobileWebView() && this.getProfileSection()}
          </div>
        </div>
        {/* {this.isOrganisationSetup()} */}
        {!isMobileWebView() && this.getSubscriptionAlert()}
        {this.state.isNewClaims && (
          <MultipleExpensePopup
            {...this.props}
            maxWidth={1020}
            onClose={() => {
              this.hideNewClaimsPopup();
            }}
          />
        )}
      </>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getAddNewList() {
    return (
      <AddNewList
        onClose={() => this.hideAddNewList()}
        selectedValue={(index) => this.selectedForm(index)}
      />
    );
  }

  selectedForm = (index) => {
    switch (index) {
      case 0:
        this.setState({
          isNewClaims: true,
        });
    }
  };

  hideNewClaimsPopup = () => {
    this.setState({
      isNewClaims: false,
    });
  }

  toggleAddNewList = () => {
    this.setState({
      needAddNewList: !this.state.needAddNewList,
    });
  };

  hideAddNewList = () => {
    this.setState({
      needAddNewList: false,
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getLanguageList = () => {
    return (
      <LanguageList
        onSelect={this.changeLanguageSelection}
        onClose={this.hideLanguageList}
      />
    );
  };

  changeLanguageSelection = (selected) => {
    this.setState({
      selectedFlag: selected,
      needLanguageList: false,
    });
  };

  toggleLanguageList = () => {
    this.setState({
      needLanguageList: !this.state.needLanguageList,
    });
  };

  hideLanguageList = () => {
    this.setState({
      needLanguageList: false,
    });
  };

  getNoCardView() {
    let helloKeywords = [
      "Hi",
      "Hello",
      "Hey",
      "Hola",
      "Bonjour",
      "Salve",
      "Nǐn hǎo",
      "Namaste",
    ];
    let hey = helloKeywords[Math.floor(Math.random() * helloKeywords.length)];

    let message = `<span style="font-size: 16px">👋</span> Welcome <b>${UserManager.getUserName()}</b>`;

    const userPlan = {
      name: "",
      icon: "",
      inTrial: UserManager.isTrialPlanActive(),
      isActive: UserManager.isPlanActive(),
      trialDays: 0,
    };

    if (userPlan.inTrial) {
      userPlan.trialDays = UserManager.getTrialDays(true);
    } else if (userPlan.isActive) {
      userPlan.name = UserManager.getUserPlanName();
      userPlan.icon = this.getPlanIcon(userPlan.name);
    }
    return (
      <div className=" display-only-web bg-deskera-secondarys border-ss p-h-s border-radius-m">
        <div className="row align-items-center ">
          <DKLabel text={message} />
          {userPlan.inTrial ? (
            <Fragment>
              <DKLabel
                text={`, Your trial will end ${
                  userPlan.trialDays ? `in ${userPlan.trialDays} days` : `today`
                }, `}
                className="fw-m text-red"
                style={{
                  /* To align with welcome text */
                  height: 16,
                }}
              />
              <DKButton
                title={`subscribe now`}
                className="fw-m text-blue text-underline"
                style={{ paddingLeft: 3, paddingRight: 5 }}
                onClick={() => {
                  AppManager.needSubscriptionApiCall = true;
                  window.open(ApiConstants.PRODUCT_URL_GO + "billing");
                }}
              />
            </Fragment>
          ) : userPlan.name ? (
            <Fragment>
              <DKLabel
                text=", you are on "
                style={{
                  /* To align with welcome text */
                  height: 16,
                }}
              />
              <DKButton
                title={`${userPlan.name} Plan`}
                className="fw-m text-blue text-underline"
                style={{ paddingLeft: 3, paddingRight: 5 }}
                onClick={() => {
                  AppManager.needSubscriptionApiCall = true;
                  window.open(ApiConstants.PRODUCT_URL_GO + "billing");
                }}
              />
              <DKIcon src={userPlan.icon} style={{ height: 20 }} />
              <DKLabel
                text={this.getPlanBadgeStatus(userPlan.isActive)}
                className={`${
                  userPlan.isActive
                    ? "bg-chip-orange border-app text-app"
                    : "bg-chip-red border-red text-red"
                } fw-m border-radius-r ml-s p-h-s`}
              />
            </Fragment>
          ) : null}
        </div>
      </div>
    );
  }
  getPlanIcon(planName = "") {
    let iconSrc = null;
    switch (planName.toLowerCase()) {
      case PLANS.STARTUP:
        iconSrc = ic_startup;
        break;
      case PLANS.ESSENTIAL:
        iconSrc = ic_essential;
        break;
      case PLANS.PROFESSIONAL:
        iconSrc = ic_professional;
        break;
      default:
    }

    return iconSrc;
  }
  getPlanBadgeStatus(isPlanActive) {
    return isPlanActive ? "Active" : "Expired";
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  addOrgTapped = () => {
    this.setState({
      showAddNewOrgPopup: true,
    });
  };

  cancelTapped = () => {
    this.setState({
      showAddNewOrgPopup: false,
    });
  };

  // showAddNewOrgPopup = () => {
  //   return <AddOrgPopup onCancel={this.cancelTapped} />;
  // };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  renderListPicker() {
    const { allOrgs, tenantIds } = this.state;
    return (
      <div
        ref={this.setWrapperRef}
        className="position-absolute"
        style={{ top: 52, left: 15 }}
      >
        <OrgList
          allOrgs={allOrgs}
          tenantIds={tenantIds}
          onAddOrgClick={this.addOrgTapped}
          onClose={this.hideListPicker}
        />
      </div>
    );
  }

  toggleListPicker = () => {
    this.setState({ orgBtnTapped: true });
    if (!this.state.allTenantResReceived) {
      showLoader("Fetching organisations...");
      return;
    }
    this.setState({
      needOrgListPicker: !this.state.needOrgListPicker,
    });
  };
  showListPicker = () => {
    this.setState({
      needOrgListPicker: true,
    });
  };
  hideListPicker = () => {
    this.setState({
      needOrgListPicker: false,
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  settingsTapped = () => {
    RouteManager.navigateToPage(PAGE_ROUTES.SETTINGS);
  };

  logoutTapped = () => {
    AppManager.logout();
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  // getNotificationPopup = () => {
  //   return (
  //     <NotificationPopup
  //       onClose={this.hideNotificationPopup}
  //       maskAsUnread={this.getUnreadNotification}
  //     />
  //   );
  // };

  showNotificationPopup = () => {
    this.setState({ needNotificationPopup: true });
  };

  hideNotificationPopup = () => {
    this.setState({ needNotificationPopup: false });
  };
  // getUnreadNotification = () => {
  //   NotificationService.getNotifications().then((response) => {
  //     if (response["data"]) {
  //       let notifications = response["data"];
  //       if (notifications) {
  //         let newNotification = false;
  //         notifications =
  //           NotificationManager.processBeforeDislay(notifications);
  //         notifications.forEach((notification) => {
  //           if (notification.status != "read") {
  //             newNotification = true;
  //           }
  //         });
  //         this.setState({ newNotification: newNotification });
  //       }
  //     }
  //   });
  // };

  getTenantNameView() {
    return (
      <DKLabel
        className="fw-m fs-l pr-m pl-m"
        style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          maxWidth: "160px",
        }}
        text={UserManager.getUserTenantName()}
      />
    );
  }
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getMobileAppActions() {
    return (
      <div className="row position-relative ml-m">
        <DKButton
          icon={DKIcons.white.ic_home}
          className={"bg-button text-white circle"}
          onClick={() => Utility.postMobileAppActions(MOBILE_APP_ACTIONS.HOME)}
        />
      </div>
    );
  }
}

export default TopMenu;
