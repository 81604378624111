import { Suspense, useEffect, useState } from "react";
import {
  DKLabel,
  DKButton,
  showAlert,
  DKIcon,
  Toggle,
  showToast,
  TOAST_TYPE,
  DKInput,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKSpinner,
  showLoader,
  removeLoader,
} from "deskera-ui-library";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import logo from "../../assets/images/logo.svg";
import deskeraBook from "../../assets/images/Deskera_Books.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Utility, { deepClone } from "../../utils/Utility";
import {
  fetchAccounts,
  fetchExpenseIntegration,
  selectIntegrationSettings,
  selectAccounts,
} from "../../redux/slices/ExpenseIntegartionSlice";
import { CONSTANTS } from "../../utils/Constants";
import ExpenseIntegrationService from "../../services/ExpenseIntegrationService";
import SyncRevertJE from "./SyncOrRevertJe";
import ic_info_warning from "../../assets/icons/ic_info_gray.svg";

const ExpenseIntegationSettings = (props) => {
  const { t, i18n } = useTranslation();
  const [integrationEnabled, setIntegrationEnabled] = useState(false);
  const [integrationData, setIntegrationData] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [expenseAccounts, setExpenseAccounts] = useState([]);
  const [liabilityAccounts, setLiabilityAccounts] = useState([]);
  const [defaultValues, setDefaultValues] = useState({});
  const [canValidate, setCanValidate] = useState(false);
  const [isSyncRevertPopupEnabled, setSyncRevertPopup] = useState(false);
  const [syncOrRevert, setSyncOrRevert] = useState("");

  //Dispatch and Selectors
  const settingsData = useSelector(selectIntegrationSettings);
  const booksAccounts = useSelector(selectAccounts);
  const dispatch = useDispatch();

  useEffect(() => {
    RouteManager.setPresenter({ props: props });
    loadIntegrationsSettings();
    loadBooksAccounts();
  }, []);

  useEffect(() => {
    if (!Utility.isEmpty(settingsData)) {
      const { expenseAcc, liabilitiesAcc } = setAccountsData(booksAccounts);
      const isIntegrationEnabled =
        settingsData && settingsData.active ? settingsData.active : false;
      const categories = getCategoryData(settingsData);
      setAccountDefaultValues(
        settingsData,
        categories,
        expenseAcc,
        liabilitiesAcc
      );
      setExpenseAccounts(expenseAcc);
      setLiabilityAccounts(liabilitiesAcc);
      setCategoryData(categories);
      setIntegrationEnabled(isIntegrationEnabled);
      setIntegrationData(settingsData);
    }
  }, [settingsData, booksAccounts]);

  const loadIntegrationsSettings = async () => {
    try {
      await dispatch(fetchExpenseIntegration());
    } catch (err) {
      showToast(t("UNABLE_TO_FETCH_INTEGRATION_SETTINGS"), TOAST_TYPE.FAILURE);
    }
  };

  const loadBooksAccounts = async () => {
    try {
      await dispatch(fetchAccounts());
    } catch (err) {
      showToast(t("UNABLE_TO_FETCH_ACCOUNTS"), TOAST_TYPE.FAILURE);
    }
  };

  const setAccountsData = (accounts) => {
    let expenseAcc = [],
      liabilitiesAcc = [];
    if (
      !Utility.isEmpty(accounts) &&
      !Utility.isEmpty(accounts.content) &&
      accounts.content.length > 0
    ) {
      const content = accounts.content;
      expenseAcc = content.filter(
        (account) =>
          account.accountGroup === CONSTANTS.EXPENSES ||
          account.accountGroup === CONSTANTS.OTHER_EXPENSES
      );
      liabilitiesAcc = content.filter(
        (account) => account.accountGroup === CONSTANTS.CURRENT_LIABILITY
      );
    }
    return { expenseAcc, liabilitiesAcc };
  };

  const getCategoryData = (settingsData) => {
    let categories = [];
    if (
      !Utility.isEmpty(settingsData.mapping) &&
      !Utility.isEmpty(settingsData.mapping.category) &&
      settingsData.mapping.category.length > 0
    ) {
      categories = settingsData.mapping.category;
    }
    return categories;
  };

  const enableDisableIntegration = (value) => {
    toggleBooksIntegrationSettings(value);
  };

  const setAccountDefaultValues = (
    expenseIntegration,
    categories,
    expenseAccounts,
    liabilityAccounts
  ) => {
    if (categories && categories.length > 0) {
      categories.forEach((element) => {
        let group = {};
        if (!Utility.isEmpty(element.expenseAccount)) {
          let index = expenseAccounts.findIndex(
            (x) => x.accountCode === element.expenseAccount
          );
          if (index != -1) {
            let expenseSelectedAccount = expenseAccounts[index];
            group["expenseAccount"] = expenseSelectedAccount;
          } else {
            let defaultExpenseAccount = expenseIntegration?.mapping
              ?.expenseAccount
              ? expenseIntegration.mapping.expenseAccount
              : "";
            let index = expenseAccounts.findIndex(
              (x) => x.accountCode === defaultExpenseAccount
            );
            if (index != -1) {
              let expenseDefaultSelectedAccount = expenseAccounts[index];
              group["expenseAccount"] = expenseDefaultSelectedAccount;
            } else {
              group["expenseAccount"] = {};
            }
          }
        }
        if (!Utility.isEmpty(element.liabilityAccount)) {
          let index = liabilityAccounts.findIndex(
            (x) => x.accountCode === element.liabilityAccount
          );
          if (index != -1) {
            let liabilitySelectedAccount = liabilityAccounts[index];
            group["liabilityAccount"] = liabilitySelectedAccount;
          } else {
            let defaultLiabilityAccount = expenseIntegration?.mapping
              ?.liabilityAccount
              ? expenseIntegration.mapping.liabilityAccount
              : CONSTANTS.EMPTY_STRING;
            let index = liabilityAccounts.findIndex(
              (x) => x.accountCode === defaultLiabilityAccount
            );
            if (index != -1) {
              let liabilityDefaultSelectedAccount = liabilityAccounts[index];
              group["liabilityAccount"] = liabilityDefaultSelectedAccount;
            } else {
              group["liabilityAccount"] = {};
            }
          }
        }
        defaultValues[element.id] = group;
      });
    }
  };

  const toggleBooksIntegrationSettings = (integrationEnabled) => {
    showLoader(
      `${
        integrationEnabled ? "Enabling" : "Disabling"
      } Books Integration... Please wait`
    );
    const status = integrationEnabled ? CONSTANTS.ACTIVE : CONSTANTS.INACTIVE;
    ExpenseIntegrationService.updateIntegrationToggle(status).then(
      (response) => {
        setIntegrationEnabled(integrationEnabled);
        removeLoader();
        showToast(
          `${t("INTEGRATION_WITH_BOOKS_NOW")} ${
            integrationEnabled ? "Enabled" : "Disabled"
          }.`,
          TOAST_TYPE.SUCCESS
        );
        loadIntegrationsSettings();
      },
      (error) => {
        removeLoader();
        showToast(t("SOMETHING_WENT_WRONG"), TOAST_TYPE.FAILURE);
      }
    );
  };

  const onSelectField = (obj, value, index, props, section) => {
    const columnData = deepClone(categoryData);
    switch (section) {
      case CONSTANTS.EXPENSE:
        if (
          !Utility.isEmpty(value) &&
          !Utility.isEmpty(defaultValues[obj.id])
        ) {
          defaultValues[obj.id]["expenseAccount"] = value;
          columnData[index]["expenseAccount"] = value.accountCode;
        }
        break;
      case CONSTANTS.LIABILITY:
        if (
          !Utility.isEmpty(value) &&
          !Utility.isEmpty(defaultValues[obj.id])
        ) {
          defaultValues[obj.id]["liabilityAccount"] = value;
          columnData[index]["liabilityAccount"] = value.accountCode;
        }
        break;
    }
    setCategoryData(columnData);
  };

  const saveOnConfirm = () => [
    {
      title: "Cancel",
      className: "bg-gray1 border-m",
    },
    {
      title: "Save",
      className: "bg-app text-white ml-r",
      onClick: () => saveData(),
    },
  ];

  const missingMappingAccounts = () => {
    let isMappingMissing = false;
    for (const [key, value] of Object.entries(defaultValues)) {
      if (
        Utility.isEmpty(value) ||
        !value?.expenseAccount ||
        !value?.liabilityAccount ||
        Utility.isEmpty(value.expenseAccount) ||
        Utility.isEmpty(value.liabilityAccount)
      ) {
        isMappingMissing = true;
      }
    }
    return isMappingMissing;
  };

  const saveData = () => {
    showLoader("Saving... Please wait");
    if (missingMappingAccounts()) {
      setCanValidate(true);
      removeLoader();
      return;
    }
    const data = deepClone(integrationData);
    const selectedCategories = deepClone(categoryData);
    if (data?.mapping?.category && data?.mapping?.category.length > 0) {
      data.mapping.category = selectedCategories;
      data.active = integrationEnabled;
    }
    ExpenseIntegrationService.saveIntegrationSettings(data).then(
      (response) => {
        removeLoader();
        showToast(t("INTEGRATION_SAVE_SUCCESS"), TOAST_TYPE.SUCCESS);
      },
      (error) => {
        removeLoader();
        showToast(t("SOMETHING_WENT_WRONG"), TOAST_TYPE.FAILURE);
      }
    );
  };

  const syncRevertExpenseJE = (isPopUpenabled, isSync) => {
    setSyncRevertPopup(isPopUpenabled);
    setSyncOrRevert(isSync ? "sync" : "revert");
  };

  const onClose = () => {
    setSyncRevertPopup(false);
    setSyncOrRevert("");
  };

  const getIntegrationSettingsHeader = () => {
    return (
      <div className="d-flex justify-content-between">
        <DKLabel
          text={t("EXPENSE_INTEGRATION_SETTINGS")}
          className="fs-r text-align-left fw-m fs-l"
        />
        <div className="d-flex">
          {integrationEnabled && (
            <DKButton
              title={t("REVERT")}
              className="border-radius-m bg-gray1 border-m mr-s"
              onClick={() => {
                syncRevertExpenseJE(true, false);
              }}
            />
          )}
          {integrationEnabled && (
            <DKButton
              title={t("SYNC")}
              className="border-radius-m bg-gray1 border-m mr-s"
              onClick={() => {
                syncRevertExpenseJE(true, true);
              }}
            />
          )}
          {/* <DKButton
            title={t("CANCEL")}
            className="border-radius-m bg-gray1 border-m mr-s"
            onClick={() => {
              RouteManager.navigateToPage(PAGE_ROUTES.DASHBOARD);
            }}
          /> */}
          {integrationEnabled && (
            <DKButton
              title={t("SAVE")}
              className="bg-app text-white"
              onClick={() => {
                showAlert(
                  t("CONFIRM"),
                  t("CHECK_MAPPING_BEFORE_PROCEEDING"),
                  saveOnConfirm()
                );
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const getIntegrationWithBooksSection = () => {
    return (
      <>
        <div className="row p-v-m p-h-l mt-m bg-white border-radius-s shadow-s column">
          <div className="">
            <DKIcon src={logo} />
            <DKIcon
              style={{
                marginLeft: "-27px",
              }}
              src={deskeraBook}
            />
          </div>
          <div className="row">
            <Toggle
              className="mr-s border-n"
              isOn={integrationEnabled}
              onChange={() => enableDisableIntegration(!integrationEnabled)}
            />
            <span className="mb-xs">{t("INTEGRATION_WITH_BOOKS")}</span>
            <div style={{ marginLeft: "auto" }}>
              {integrationEnabled && (
                <div className="bg-chip-orange text-app border-app p-v-s p-h-r border-radius-l fw-m text-wrap-none m-s">
                  {t("ENABLED")}
                </div>
              )}
              {!integrationEnabled && (
                <div className="bg-white text-gray border-m p-v-s p-h-r border-radius-l fw-m text-wrap-none m-s">
                  {t("DISABLED")}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const getMappingsTableHeader = (x) => {
    return (
      <>
        <tr className="p-v-s">
          <td
            className="p-v-s data-grid-bottom-border"
            style={{ width: "20%" }}
          >
            <div className="d-flex">
              <span className="p-s fw-r fw-m">{t("CATEGORY")}</span>
            </div>
          </td>
          <td
            className="p-v-s p-h-l data-grid-bottom-border"
            style={{ width: "40%" }}
          >
            <div className="d-flex">
              <span className="p-s fw-r fw-m">{t("EXPENSE_ACCOUNT")}</span>
            </div>
          </td>
          <td
            className="p-v-s p-h-l data-grid-bottom-border"
            style={{ width: "40%" }}
          >
            <div className="d-flex">
              <span className="p-s fw-r fw-m">{t("LIABILITY_ACCOUNT")}</span>
            </div>
          </td>
        </tr>
      </>
    );
  };

  const getMappingsTable = () => {
    return categoryData.map((obj, index) => {
      return (
        <>
          <tr className="">
            <td className="p-v-m">
              <div className="d-flex">
                <span className="p-s fw-r">{obj.name}</span>
              </div>
            </td>
            <td className="p-v-m">
              <div className="d-flex pl-xl">
                <DKInput
                  className=""
                  value={
                    defaultValues[obj.id] &&
                    defaultValues[obj.id]["expenseAccount"]
                      ? defaultValues[obj.id]["expenseAccount"]
                      : {}
                  }
                  formatter={(obj) => {
                    return obj.name;
                  }}
                  type={INPUT_TYPE.DROPDOWN}
                  showError={true}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  readOnly={false}
                  required={true}
                  canValidate={canValidate}
                  dropdownConfig={{
                    className: "",
                    style: {},
                    allowSearch: expenseAccounts && expenseAccounts.length > 6,
                    searchableKey: obj.name,
                    data: expenseAccounts,
                    renderer: (index, obj) => {
                      return <DKLabel text={`${obj.name}`} />;
                    },
                    onSelect: (ind, value) => {
                      onSelectField(obj, value, index, props, "expense");
                    },
                  }}
                  errorMessage={"Please select an Expense Account"}
                  validator={(value) => {
                    if (Utility.isEmpty(value)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  onChange={(value) => {}}
                />
              </div>
            </td>
            <td className="p-v-m">
              <div className="d-flex pl-xl">
                <DKInput
                  className=""
                  formatter={(obj) => {
                    return obj.name;
                  }}
                  value={
                    defaultValues[obj.id] &&
                    defaultValues[obj.id]["liabilityAccount"]
                      ? defaultValues[obj.id]["liabilityAccount"]
                      : {}
                  }
                  type={INPUT_TYPE.DROPDOWN}
                  showError={true}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  readOnly={false}
                  required={true}
                  canValidate={canValidate}
                  dropdownConfig={{
                    className: "",
                    style: {},
                    allowSearch:
                      liabilityAccounts && liabilityAccounts.length > 6,
                    searchableKey: obj.name,
                    data: liabilityAccounts,
                    renderer: (index, obj) => {
                      return <DKLabel text={`${obj.name}`} />;
                    },
                    onSelect: (ind, value) => {
                      onSelectField(obj, value, index, props, "liability");
                    },
                  }}
                  onChange={(value) => {}}
                  errorMessage={"Please select a Liability Account"}
                  validator={(value) => {
                    if (Utility.isEmpty(value)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                />
              </div>
            </td>
          </tr>
        </>
      );
    });
  };

  const getMappingSection = () => {
    return (
      <>
        <div className="bg-white border-radius-s shadow-s">
          <div className="p-v-s p-h-l">
            {
              <table class="width-80">
                {getMappingsTableHeader()}
                {getMappingsTable()}
              </table>
            }
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="parent-width">
        <div>{getIntegrationSettingsHeader()}</div>
        <div>{getIntegrationWithBooksSection()}</div>
        {integrationEnabled && (
          <Suspense
            fallback={
              <div className="row justify-content-center">
                <DKSpinner />
              </div>
            }
          >
            <div className="p-v-xl">
              <div className="row p-v-m p-h-m bg-deskera-secondary">
                <DKIcon
                  className="mr-s ic-s-2"
                  src={ic_info_warning}
                  style={{ opacity: 0.7 }}
                />
                {t("NO_JE_WITHOUT_CATEGORY")}
              </div>
              {getMappingSection()}
            </div>
          </Suspense>
        )}
        {isSyncRevertPopupEnabled && (
          <SyncRevertJE action={syncOrRevert} onClose={() => onClose()} />
        )}
      </div>
    </>
  );
};

export default ExpenseIntegationSettings;
