import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LanguageMenuList } from '../components/menu/LabelList/LanguageMenuList';
import { LANGUAGES_CODE } from '../utils/Constants';

import en from './traslations/en.json';
// import en_GB from './traslations/en_GB.json';

export const LANG = "language";

const resources = {
    en: {
        translation: en // English
    },
    // en_GB: {
    //     translation: en_GB // English UK
    // }
};

i18next.use(initReactI18next).init({
    resources,
    lng: getDefaultLangCode(),
    interpolation: {
        escapeValue: false
    }
});

export default i18next;

export function getDefaultLangCode() {
    return LANGUAGES_CODE.ENGLISH_US;
}

export function getDefaultLangTitle() {
    const defaultLang = LanguageMenuList.find((l) => l.code === getDefaultLangCode());
    return defaultLang?.title;
}
