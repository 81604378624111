import ApiConstants from "../utils/ApiConstants";
import Utility from "../utils/Utility";
import http from "../http";
import { ENTITY } from "../utils/Constants";


const defaultConfig = {
  params: {
    limit: 20,
    page: 0,
  },
};

class ExpenseService {
  static apiConfig;
  static getCategory(isAllCategory) {
    const url = isAllCategory
      ? `${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.CATEGORY}`
      : `${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.GET_CATEGORY}`;
    return http.get(url).catch((error) => {
      console.error("Error loading category: ", error);
      return Promise.reject(error);
    });
  }
  static getTaxes() {
    const url = ApiConstants.URL.EXPENSE.TAXES;
    return http.get(url).catch((err) => {
      console.error("Error loading taxes: ", err);
      return Promise.reject(err);
    });
  }
  static getExpenseById(id) {
    const url = ApiConstants.URL.EXPENSE.EXPENSES;
    return http.get(`${url}/${id}`).catch((err) => {
      console.error("Error loading EXPENSES: ", err);
      return Promise.reject(err);
    });
  }
  static saveExpenses(param, id) {
    const url = ApiConstants.URL.EXPENSE.EXPENSES;
    if (id) {
      return http.put(`${url}/${id}`, param).catch((err) => {
        console.error("Error save category: ", err);
        return Promise.reject(err);
      });
    } else {
      return http.post(url, param).catch((err) => {
        console.error("Error save category: ", err);
        return Promise.reject(err);
      });
    }
  }
  static upload(param) {
    const url = ApiConstants.URL.EXPENSE.UPLOAD;
    return http.post(url, param).catch((err) => {
      console.error("Error uplaod: ", err);
      return Promise.reject(err);
    });
  }
  static image(path) {
    const url = ApiConstants.URL.EXPENSE.IMAGE;
    return http.get(`${url}?relativeFilePath=${path}`).catch((err) => {
      console.error("Error get image: ", err);
      return Promise.reject(err);
    });
  }
  static fetchExpensesGroup() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    return http
      .get(ApiConstants.URL.EXPENSE.GROUP, { params: this.apiConfig.params })
      .catch((error) => {
        console.error("Error fetching expenses GROUP : ", error);
        return Promise.reject(error);
      });
  }
  static expenseBulkUser(data) {
    return http
      .post(ApiConstants.URL.EXPENSE.BULK_USER, data)
      .catch((error) => {
        console.error("Error save expenses bulk user : ", error);
        return Promise.reject(error);
      });
  }
  static changeRole(data, isManager) {
    let assign = !isManager ? "/bulkUser" : "/bulkUnassign";
    return http
      .post(ApiConstants.URL.EXPENSE.GET_EXPENSES + assign, data)
      .catch((error) => {
        console.error("Error save expenses bulk user : ", error);
        return Promise.reject(error);
      });
  }
  static saveGroup(data) {
    return http.post(ApiConstants.URL.EXPENSE.GROUP, data).catch((error) => {
      console.error("Error save group : ", error);
      return Promise.reject(error);
    });
  }
  static deleteGroup(id) {
    return http.delete(`${ApiConstants.URL.EXPENSE.GROUP}/${id}`);
  }
  static getUserByGroup(id) {
    return http.get(ApiConstants.URL.EXPENSE.GET_USER_BY_GROUP_ID(id));
  }
  static deleteUser(id, param) {
    return http.delete(`${ApiConstants.URL.EXPENSE.USER}/${id}`, {
      data: param,
    });
  }
  static moveUser(param) {
    return http.post(ApiConstants.URL.EXPENSE.MOVE_USER, param);
  }
  static saveCategory(param) {
    let url = ApiConstants.URL.EXPENSE.GET_CATEGORY;
    return http.post(url, param);
  }
  static updateCategory(param, id) {
    let url = ApiConstants.URL.EXPENSE.GET_CATEGORY + "/" + id;
    return http.put(url, param);
  }
  static deleteCategory(id) {
    let url = ApiConstants.URL.EXPENSE.GET_CATEGORY;
    return http.delete(`${url}/${id}`);
  }
  static downloadAttachment(attachmentId) {
    const finalEndPoint = ApiConstants.URL.ATTACHMENT.DOWNLOAD(attachmentId);
    return http
      .get(finalEndPoint)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static getAllAttachment(attachmentId) {
    const finalEndPoint = ApiConstants.URL.ATTACHMENT.GET_ALL(
      attachmentId,
      ENTITY.EXPENSE
    );
    return http
      .get(finalEndPoint)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static deleteAttachment(attachmentId) {
    const finalEndPoint = ApiConstants.URL.ATTACHMENT.DELETE(attachmentId);
    return http
      .delete(finalEndPoint)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static getNonGroupMembers() {
    return http.get(
      `${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.GET_NO_GROUP_USERS}`
    );
  }
  static uploadAttachment(param, attachmentConfig) {
    const formData = new FormData();
    if (attachmentConfig.entityId) {
      formData.append("entityId", attachmentConfig.entityId);
    }
    formData.append("entityName", attachmentConfig.entityName);
    formData.append("file", param);
    const url = ApiConstants.URL.ATTACHMENT.UPLOAD;
    return http.post(url, formData).catch((err) => {
      console.error("Error uplaod: ", err);
      return Promise.reject(err);
    });
  }
  static saveMultiExpense(param) {
    return http
      .post(ApiConstants.URL.EXPENSE.MULTI_EXPENSE, param)
      .catch((error) => {
        console.error("Error save expenses multi expense : ", error);
        return Promise.reject(error);
      });
  }
}

export default ExpenseService;
