import { useEffect, useState } from "react";
import {showToast,TOAST_TYPE} from  "deskera-ui-library";
import DataGridHolderPayroll from "../common/DataGridHolderPayroll";
import { TableManger, TABLES, TABLE_DISPLAY_NAME } from "../../managers/TableManager";
import { fetchExpenseCategories, selectExpenseCategories } from "../../redux/slices/ExpenseCategorySlice";
import { useDispatch, useSelector } from "react-redux";
import { ExpenseCategoryColumnConfig, parseColumnConfig, parseVisibleColumnConfig } from "../../services/Config";
import ExpenseService from "../../services/ExpenseService";
import Utility, { deepClone, removeDelayedLoader, showDelayedLoader } from "../../utils/Utility";
import AddEditCategory from "./AddEditCategory";
import Popup from "../common/Popup";
import RouteManager from "../../managers/RouteManager";
import { useHistory } from "react-router";

export default function ExpenseCategory(props) {
    const history = useHistory();
    const expenseCategory = useSelector(selectExpenseCategories);
    const initial = {
        columnData: [],
        rowData: [],
        originalData: [],
        filter: []
    };
    const dispatch = useDispatch();
    const [expenseCategoryData, setexpenseCategory] = useState(initial);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [filters, setFilters] = useState([]);
    const [addCategoryPopup, setAddGroupPopup] = useState({
        isVisible: false,
        isLoadCategory:true,
        categoryData:null
    })
    useEffect(()=>{
        RouteManager.setPresenter({props: {...props, history: history}});

    },[])
    useEffect(() => {

        if(!addCategoryPopup.isVisible && addCategoryPopup.isLoadCategory) {
            fetchGroup();
        }
    }, [addCategoryPopup])

    useEffect(() => {
        if (!Utility.isEmptyObject(expenseCategory)) {
            const rowData = ExpenseCategoryColumnConfig(expenseCategory);
            const columnConfig = parseVisibleColumnConfig(parseColumnConfig(TableManger.getTableVisibleColumns(TABLES.PEOPLE_EXPENSE_CATEGORY), TABLES.PEOPLE_EXPENSE_CATEGORY));

            const data = {
                columnData: deepClone(columnConfig),
                rowData: rowData,
                filter: [],
                originalData: rowData
            };
            setexpenseCategory(data);
            setCurrentPage(0);
            setTotalPageCount(0);
        }
    }, [expenseCategory])

    const fetchGroup = async (apiConfig) => {
        const timeout = showDelayedLoader();
        ExpenseService.apiConfig = apiConfig;
        await dispatch(fetchExpenseCategories());
        removeDelayedLoader(timeout);
    }
    const newCategory = () => {
        setAddGroupPopup({ isVisible: true, isLoadCategory:false,categoryData:null })
    }
    const onFilter = (dataList, queries) => {

    }
    const onRowEdit = (data) => {
        if(data.rowData.data.id) {
            setAddGroupPopup({
                isVisible:true,
                isLoadCategory:false,
                categoryData: data.rowData.data
            })
        }
    }
    const onRowDelete = (data) => {
        ExpenseService.deleteCategory(data.rowData.data.id).then(res=>{
            showToast("Category has been deleted successfully", TOAST_TYPE.SUCCESS);
            fetchGroup();
        },error=>{
            if(error && error.message) {
                showToast(error.message, TOAST_TYPE.FAILURE);
            }
        })
    }
    return (
        <>
        {addCategoryPopup.isVisible && <Popup popupClass={" popup-overflow "}>
                <AddEditCategory {...props} categoryData={addCategoryPopup.categoryData} onClose={(data) => setAddGroupPopup({ isVisible: false,isLoadCategory:data,categoryData:null })} />
            </Popup>}
        <div className="column " style={{ height: '90%' }}>
            <div className="flex-1 position-relative" style={{ minHeight: "200px" }}>
                <DataGridHolderPayroll
                    tableName={TABLES.PEOPLE_EXPENSE_CATEGORY}
                    displayTableName={TABLE_DISPLAY_NAME[TABLES.PEOPLE_EXPENSE_CATEGORY]}
                    allowColumnEdit={false}
                    allowColumnDelete={false}
                    allowColumnShift={false}
                    allowSearch={false}
                    allowFilter={false}
                    allowColumnAdd={false}
                    allowRowAdd={false}
                    refresh={false}
                    allowDataExport={false}
                    allowRowEdit={true}
                    showContextEdit={false}
                    isActionEnabled={true}
                    contextMenu={true}
                    isMenuVisible={false}
                    gridData={expenseCategoryData}
                    filter={filters}
                    currentPage={currentPage}
                    totalPageCount={totalPageCount}
                    onFilter={(dataList, queries) => {
                        onFilter(dataList, queries);
                    }}
                    onRowDelete={(data) => {
                        onRowDelete(data);
                    }}
                    onRowEdit={(data) => {
                        onRowEdit(data);
                    }}
                    addButtonConfig={[{
                        title: "+ Create New Category",
                        className: "bg-app text-white ml-s",
                        onClick: () => {
                            newCategory();
                        }
                    }]}
                    onPaginationClick={(requestedPageNumber) => {
                        fetchGroup({
                            ...ExpenseService.apiConfig,
                            params: {
                                limit: 20,
                                page: requestedPageNumber - 1
                            }
                        })
                    }}
                    onRowOpenBtnHidden={true}
                />
            </div>
        </div>
        </>
    )
}