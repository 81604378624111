import getSymbolFromCurrency from "currency-symbol-map";
import { TENANT, TenantService } from "../services/TenantMangerService";

class FormatCurrencyService {
    private static transform(value: number | string, fractionSize: number = 2, countryCode: string): string {
        let [integer, fraction = ""] = (value || "").toString().split(".");

        fraction = fractionSize > 0 ? "." + (fraction + "000000").substring(0, fractionSize) : "";

        if (countryCode === "IN") {
            integer = integer.replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
        } else {
            integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        if (integer.length === 0) {
            integer = '0';
        }

        return integer + fraction;
    }

    static format(amount: number | string, fractionSize?: number): string {
        if (amount === null || amount === undefined || String(amount).trim() === "") {
            amount = 0;
        }

        const countryCode = TenantService.get(TENANT.COUNTRY_CODE);
        const currencySymbol = getSymbolFromCurrency(TenantService.get(TENANT.CURRENCY_CODE));
        if(currencySymbol === null || currencySymbol === undefined) {
            return amount.toString();
        } else {
            fractionSize = fractionSize || TenantService.get(TENANT.DECIMAL_FORMAT);
            return currencySymbol + this.transform(amount, fractionSize, countryCode);
        }
    }
}

export default FormatCurrencyService;
