import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {DKLabel} from "deskera-ui-library"
import DataGridHolderPayroll from "../common/DataGridHolderPayroll";
import { TableManger, TABLES } from "../../managers/TableManager";
import RouteManager from "../../managers/RouteManager";
import AppManager from "../../managers/AppManager";
import { fetchExpenseClaims, selectCategories, selectExpenses } from "../../redux/slices/ExpenseClaimListSlice";
import Utility, { deepClone, removeDelayedLoader, showDelayedLoader } from "../../utils/Utility";
import ExpenseClaimListService from "../../services/ExpenseClaimListService";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ExpenseClaimsColumnConfig, parseColumnConfig, parseVisibleColumnConfig } from "../../services/Config";
import { TENANT, TenantService } from "../../services/TenantMangerService";
import UserManager from "../../managers/UserManager";

const RecentClaims = (props) => {

    const initialExpenseClaims = {
      columnData: [],
      rowData: [],
      originalData: [],
      filter: [],
    };
    const initialClaimPeriod = {
      toggle: false,
      startDate: moment(moment().format()).startOf("month"),
      endDate: moment(moment().format()).endOf("month"),
      defaultDate: false,
    };

    const history = useHistory();
    const dispatch = useDispatch();
    const [claimPeriod, setClaimPeriod] = useState(initialClaimPeriod);
    const [expenseClaims, setExpenseClaims] = useState(initialExpenseClaims);
    const [filters, setFilters] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const expenses = useSelector(selectExpenses);
    const categories = useSelector(selectCategories);

    useEffect(() => {
      RouteManager.setPresenter({ props: { ...props, history: history } });
      AppManager.scrollToTop();
      fetchExpenses(
        getAPIConfig(claimPeriod.startDate, claimPeriod.endDate, currentPage)
      );
    }, []);

   useEffect(() => {
     if (!Utility.isEmptyObject(expenses)) {
       const { content, number, totalPages } = expenses;
       const rowData = ExpenseClaimsColumnConfig(
         content,
         undefined,
         TenantService.get(TENANT.IS_ADMIN),
         UserManager.getUserDetails().id
       );
       const columnConfig = parseVisibleColumnConfig(
         parseColumnConfig(
           TableManger.getTableVisibleColumns(TABLES.PEOPLE_EXPENSE_CLAIMS),
           TABLES.PEOPLE_EXPENSE_CLAIMS,
           { categories: categories }
         )
       );

       const claimsData = {
         columnData: deepClone(columnConfig),
         rowData: rowData,
         filter: [],
         originalData: rowData,
       };
       setExpenseClaims(claimsData);
       setCurrentPage(number + 1);
       setTotalPageCount(totalPages);
     }
   }, [expenses]);

   const fetchExpenses = async (apiConfig) => {
     const timeout = showDelayedLoader();
     if (apiConfig) {
       ExpenseClaimListService.apiConfig = apiConfig;
     }
     await dispatch(fetchExpenseClaims());
     removeDelayedLoader(timeout);
   };

   const getAPIConfig = (
     startDate,
     endDate,
     currentPage
   ) => {
     return {
       params: {
         view: "EMPLOYEE",
         page: currentPage - 1,
         limit: 5,
         sort: "createdAt",
         sortDir: "desc"
       },
     };
   };

   const getGridWidth = () => {
      const griWidth = document.getElementById("recent-claims-id")?.clientWidth;
      return griWidth;
   }

    return (
      <>
        <div
          className="bg-white p-h-xl pt-r pb-s border-m border-radius-m border-box dashboard-card column align-items-center parent-width"
          style={{ width: "66%", minHeight:"300px" }}
        >
          <div className="row align-items-center justify-content-between mb-s">
            <DKLabel
              text={"My Recently Processed Claims"}
              className="fw-m fs-m"
            />
          </div>
          <div
            className="parent-width parent-height"
          >
            <div
              id="recent-claims-id"
              className="column"
              style={{ marginTop: "0px", height: "90%" }}
            >
              <div
                className="flex-1 position-relative parent-width"
                style={{ minHeight: "200px" }}
              >
                <DataGridHolderPayroll
                  tableName={TABLES.PEOPLE_EXPENSE_CLAIMS}
                  allowColumnEdit={false}
                  allowColumnDelete={false}
                  allowColumnShift={false}
                  allowSearch={false}
                  allowFilter={false}
                  allowColumnAdd={false}
                  allowRowAdd={false}
                  allowColumnSort={false}
                  refresh={false}
                  allowDataExport={false}
                  allowRowEdit={false}
                  showContextEdit={false}
                  isActionEnabled={false}
                  contextMenu={false}
                  isMenuVisible={false}
                  showContextMenu={false}
                  gridData={expenseClaims}
                  filter={filters}
                  currentPage={currentPage}
                  totalPageCount={totalPageCount}
                  gridWidth={getGridWidth()}
                  onSelection={(selection) => {}}
                  onFilter={(dataList, queries) => {
                  }}
                  onRowEdit={(data) => {
                  }}
                  onRowUpdate={(data) => {
                  }}
                  addButtonConfig={[]}
                  addMenuButton={[]}
                  onPaginationClick={(requestedPageNumber) => {
                    const config = getAPIConfig(
                      claimPeriod.startDate,
                      claimPeriod.endDate,
                      requestedPageNumber
                    );
                    fetchExpenses(config);
                  }}
                  onCancelContext={() => {
                    // onContextCancel();
                  }}
                  hideTableName={{
                    hidden: true,
                    width: 197,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default RecentClaims;