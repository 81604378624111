import React, { Component, useEffect } from "react";
import { getDashboardWidgetLoading } from "../dashboard/Dashboard";
import { DKLabel } from "deskera-ui-library";
import FormatCurrency from "../../sharedComponents/FormatCurrency";
import ExpenseClaimListService from "../../services/ExpenseClaimListService";

class MyClaimsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
    };
  }

  componentDidMount() {
    this.fetchClaimsByStatus();
  }

  fetchClaimsByStatus() {
    this.setState({ loading: true });
    ExpenseClaimListService.fetchClaimsSummary()
      .then(
        (response) => {
          this.setState({ data: response, loading: false });
        },
        (error) => {
          this.setState({ loading: false, data: null });
        }
      )
      .catch((error) => {
        this.setState({ loading: false, data: null });
      });
  }

  getEmptyState = () => {
    return (
      <div
        className={`column parent-width parent-height align-items-center justify-content-center`}
      >
        <DKLabel text="No data found" className="fw-m fs-m text-gray" />
        <DKLabel text="Add new claims" className="text-gray mt-xs" />
      </div>
    );
  };

  getClaimAmount(claimList) {
    let amount = 0;
    claimList.map((claim) => {
      amount += claim.totalAmount;
    });
    return amount;
  }

  render() {
    console.log(this.state.data);
    return (
      <>
        {this.state.loading && getDashboardWidgetLoading()}
        {!this.state.loading &&
          this.state.data != null &&
          this.state.data.length > 0 && (
            <div
              className={`column parent-width parent-height align-items-stretch pt-l`}
            >
              {this.state.data.map((expense) => {
                return (
                  <div
                    key={expense.status}
                    className="row border-m border-app p-r mt-xl justify-content-between"
                  >
                    <div className="column">
                      <b>
                        {expense.status} {expense.count}
                      </b>
                    </div>
                    <div className="column">
                      <FormatCurrency amount={expense.totalAmount} />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        {!this.state.loading &&
          (this.state.data == null || this.state.data.length == 0) &&
          this.getEmptyState()}
      </>
    );
  }
}
export default MyClaimsCard;
