import { Component } from "react";
import { DKLabel, DKButton, DKInput, showToast, TOAST_TYPE } from "deskera-ui-library";
import { MOVE_USER_FIELD } from "../claims/ClaimsConstants";
import Utility from "../../utils/Utility";
import ExpenseService from "../../services/ExpenseService";


export default class Moveuser extends Component {
    canValidate = false;
    allUsers;
    groupId;
    oldData;
    expenseData = {
        name: null
    }
    groupList = []
    moveUserField = MOVE_USER_FIELD
    constructor(props) {
        super(props)
        this.groupId = props?.data?.groupId
        this.oldData = props.data
        this.expenseData['name'] = this.oldData.data.group.id
    }
    componentDidMount() {
        this.getGroups()
        this.updateState()
    }
    updateState() {
        this.setState({
            ...this.state
        })
    }
    getGroups() {
        ExpenseService.fetchExpensesGroup().then(res=>{
            if(res.content) {
                this.groupList = res.content;
                let ind = this.moveUserField.findIndex(field => field.key === "name");
                this.moveUserField[ind].options = this.groupList ;
                this.updateState();
            }
        })
    }

    getFieldValue(obj) {
        switch (obj.columnCode) {
            case "name":
                let data = this.groupList.filter(res => res.id === this.expenseData[obj.columnCode])
                return (data && data.length != 0) ? data[0] : [];


            default:
                return this.expenseData[obj.columnCode]
        }
    }
    validateForm() {
        let isValid = true;
        this.canValidate = true;
        this.moveUserField.forEach(field => {
            if (field.required && Utility.isEmpty(this.expenseData[field.key])) {
                isValid = false;
            } else if (!field.isValid) {
                isValid = false;
            }
        })
        this.updateState();
        return isValid
    }
    submit() {
        this.canValidate = true;
        this.updateState()
        if (!this.validateForm()) {
            return;
        }
        let obj = {
            newGroupId: this.expenseData['name'],
            newRole: this.oldData?.data?.role,
            oldGroupId: this.groupId,
            oldRole: this.oldData?.data?.role,
            userId: this.oldData?.data?.id
        }
        ExpenseService.moveUser(obj).then(res=>{
            showToast('Member moved successfully', TOAST_TYPE.SUCCESS);
            this.props.onClose(true)
        },error=>{
            if(error && error.message) {
                showToast(error.message, TOAST_TYPE.FAILURE)
            } else {
                showToast('Something went wrong', TOAST_TYPE.FAILURE)
            }
        })


    }
    onChange(value, index, obj) {
        switch (obj.key) {
            case "name":

                this.expenseData[obj.key] = value.id ;

                break;
            default:
                this.expenseData[obj.key] = value;
                break;
        }
        this.updateState()
    }
    renderTop() {
        return (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <DKLabel text={`Move Users`} className="fw-m fs-l" />
                    <div className="d-flex">
                        <DKButton
                            className="bg-gray1 border-m mr-s"
                            title="Close"
                            onClick={() => {
                                if (this.props.onClose) {
                                    this.props.onClose()
                                }
                            }}
                        />

                        <DKButton
                            className={`bg-app text-white  `}
                            title="Submit"

                            onClick={() => {
                                this.submit()
                            }}
                        />
                    </div>
                </div>
            </>
        )
    }
    renderField(obj, index) {
        return (
            <>
                <DKInput

                    className={"p-v-s mb-r"}
                    icon={obj.icon}
                    title={obj.name}
                    placeholder={obj.name}
                    value={this.getFieldValue(obj)}
                    options={obj.options}
                    type={obj.type}
                    errorMessage={obj.errorMessage}
                    labelClassName=" text-align-left "
                    canValidate={this.canValidate}
                    onChange={(value) => {
                        this.onChange(value, index, obj);
                    }}
                    validator={(value) => {
                        if (obj.validation && obj.validation.regExp) {
                            const regex = new RegExp(obj.validation.regExp);
                            obj.isValid = regex.test(value);
                            return regex.test(value);
                        } else if (obj.required && typeof value === "string" && Utility.isEmpty(value.trim())) {
                            obj.isValid = false
                            return false;
                        } else if (obj.required && Utility.isEmpty(value)) {
                            obj.isValid = false
                            return false;
                        } else {
                            obj.isValid = true;
                            return true;
                        }
                    }}
                    formatter={(obj) => {
                        /** How to render a selected option in case of object **/
                        return obj.name;
                    }}
                    dropdownConfig={{
                        className: "",
                        style: {},

                        data: obj.options,
                        renderer: (index, obj) => {
                            /** How to render value in Dropdown in case of object **/
                            return <DKLabel text={`${obj.name}`} />;
                        },
                        onSelect: (index, obj) => {

                        },
                    }}
                    direction={obj.direction}
                    required={obj.required}
                    readOnly={obj.readOnly}
                />
            </>
        )
    }
    render() {
        return (
            <>
                {this.renderTop()}
                <div className="mt-l ">

                    {this.moveUserField.map((obj, index) => {
                        return (this.renderField(obj, index))
                    })}
                </div>
            </>
        )
    }
}