import DataParser from "../helper/DataParser";
import {
  showToast,
  TOAST_TYPE,
  showLoader,
  removeLoader,
  DKIcons,
} from "deskera-ui-library";
import TenantManager from "../managers/TenantManager";
import { COUNTRY_CODE, FILE_TYPE } from "./Constants";
import { TENANT, TenantService } from "../services/TenantMangerService";
import moment from "moment";
import ApiConstants from "./ApiConstants";
import ic_attachment from "../assets/icons/icon_attachment.png";

export function getVW(value) {
  //   return value / PIXEL_TO_VW + "vw";
}

export function getCapitalized(name) {
  let str = name;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isData(data) {
  return data !== null && data !== undefined;
}

export function toCurrencyFormat(number) {
  // return number
  return (
    TenantManager.getCurrency() +
    " " +
    parseFloat("" + number)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
  );
}

export function toKFormat(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
    : Math.sign(num) * Math.abs(num);
}

export function getRandomAlphaNumericString(length = 16) {
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

export function getRandomNumber(number = 1000) {
  return Math.floor(Math.random() * number);
}

export function getRandomHexString(length = 24) {
  const chars = "0123456789abcdefABCDEF";
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}
///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

export function swapArrayElement(arr, fromIndex, toIndex) {
  var b = arr[fromIndex];
  arr[fromIndex] = arr[toIndex];
  arr[toIndex] = b;
  return arr;
}

export function shiftArrayElement(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}

export function sortArray(arr, sortOrder, keyToSort) {
  return arr.sort(function (obj1, obj2) {
    const i1 = sortOrder === "ASC" ? obj1[keyToSort] : obj2[keyToSort];
    const i2 = sortOrder === "ASC" ? obj2[keyToSort] : obj1[keyToSort];
    if (!isNaN(i1)) {
      return Number(i1) - Number(i2);
    }
    if (i1 < i2) {
      return -1;
    }
    if (i1 > i2) {
      return 1;
    }
    return 0;
  });
}

export function isMacintosh() {
  var deviceDetect = navigator.platform;
  var appleDevicesArr = [
    "MacIntel",
    "MacPPC",
    "Mac68K",
    "Macintosh",
    "iPhone",
    "iPod",
    "iPad",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad Simulator",
    "Pike v7.6 release 92",
    "Pike v7.8 release 517",
  ];

  if (appleDevicesArr.includes(deviceDetect)) {
    return true;
  }

  return false;
}

export default class Utility {
  static STRING_EMPTY = "";
  static DEFAULT_DATE = "0001-01-01T00:00:00Z";

  static encodeString(json) {
    let temp = encodeURI(json);
    return btoa(temp);
  }

  static decodeString(base64String) {
    return JSON.parse(atob(base64String));
  }

  ////////////////////////////////////////////////
  static encodeStringForTemplate(json) {
    return btoa(unescape(encodeURIComponent(json)));
  }
  static decodeStringForTemplate(base64String) {
    return JSON.parse(decodeURIComponent(escape(atob(base64String))));
  }
  ////////////////////////////////////////////////

  ///////////////////// JSON encryption ///////////////////////////
  static encodeJSON(json) {
    let temp = encodeURI(JSON.stringify(json));
    return btoa(temp);
  }
  static decodeJSON(base64String) {
    return JSON.parse(decodeURI(atob(base64String)));
  }
  ////////////////// END - JSON encryption /////////////////////////

  ///////////////////// HTML encryption ///////////////////////////
  static encodeHTML(html) {
    let temp = encodeURI(html);
    return btoa(temp);
  }
  static decodeHTML(base64String) {
    return decodeURI(atob(base64String));
  }
  ////////////////// END - HTML encryption /////////////////////////

  static copyToClipBoard(textToCopy) {
    if (navigator && navigator.clipboard) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => showToast("Copied!!!", TOAST_TYPE.SUCCESS));
    }
  }

  static isDevice() {
    if (window.screen.width <= 1142) {
      return true;
    }
    return false;
  }

  static openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  static isValidNumber(number) {
    if (typeof number == "string") {
      return number.match(/^[0-9]+$/) !== null;
    } else if (typeof number == "number") {
      return true;
    } else {
      return false;
    }
  }

  static isValidEmail(email) {
    if (email.trim() === "") {
      return false;
    }
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static isValidURL(string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  }

  static isValidDate(string) {
    const date = new Date(string);
    return Boolean(date.getTime());
  }
  ////////////////////////////////////////////////////////
  //////////// START - LOCAL STORAGE FEATURES ////////////
  ////////////////////////////////////////////////////////

  static setPersistentValue(value, key) {
    localStorage.setItem(key, value);
  }

  static getPersistentValue(key) {
    return localStorage.getItem(key);
  }

  static getURLOptionValue(urlOption) {
    return urlOption === undefined || urlOption === null
      ? false
      : urlOption.toLowerCase() === "true"
      ? true
      : false;
  }
  static isEmpty = (value) => {
    if (value === null || value === undefined || value.length === 0)
      return true;
    if (
      typeof value === "object" &&
      Object.prototype.toString.call(value) === "[object Date]"
    )
      return false;
    if (typeof value === "number" && value > 0) return false;
    if (Array.isArray(value) || typeof value === "string") return !value.length;
    return Object.keys(value).length === 0;
  };
  static validateAgainstPattern(string, pattern) {
    return pattern.test(string);
  }
  static getFirstAndLastDayByMonth(
    month = new Date().getMonth(),
    year = new Date().getFullYear()
  ) {
    return [
      DataParser.formatDate(new Date(year, month, 1)),
      DataParser.formatDate(new Date(year, month + 1, 0)),
    ];
  }
  static isPhoneValid(value, country) {
    switch (country) {
      case COUNTRY_CODE.INDIA:
        return (
          (value.trim() != "" && !Utility.isValidNumber(value)) ||
          value.length != (value[0] == 0 ? 11 : 10)
        );
      default:
        return (
          (value.trim() != "" && !Utility.isValidNumber(value)) ||
          value.length != 10
        );
    }
  }

  static makeCopyOfObject(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  // getCompressedImage = (e, success, failure) => {
  //   const originalImgUrl = window.URL.createObjectURL(e.target.files[0]);
  //   const imgObj = new Image();
  //   imgObj.src = originalImgUrl;
  //   imgObj.onload = async () => {
  //     const originalImgWidth = imgObj.naturalWidth;
  //     const MAX_WIDTH = 300,
  //       MAX_HEIGHT = 225,
  //       QUALITY = 1;
  //     if (originalImgWidth > MAX_WIDTH) {
  //       const config = {
  //         quality: QUALITY,
  //         maxWidth: MAX_WIDTH,
  //         maxHeight: MAX_HEIGHT,
  //       };

  //       let image = await readAndCompressImage(e.target.files[0], config);
  //       let base64Image = await this.convertToBase64(image);

  //       if (base64Image === "error") {
  //         failure("An error has occured");
  //       } else {
  //         success(base64Image);
  //       }
  //     } else {
  //       success(originalImgUrl);
  //     }
  //   };
  // };
  static isEmptyForCompliance = (value) => {
    if (value === null || value === undefined || value.length === 0)
      return true;
    if (
      typeof value === "object" &&
      Object.prototype.toString.call(value) === "[object Date]"
    )
      return false;
    if (typeof value === "number" && value >= 0) return false;
    if (Array.isArray(value) || typeof value === "string") return !value.length;
    return Object.keys(value).length === 0;
  };

  static formatDate(date, inputFormat, outputFormat) {
    let formattedDate = date;
    outputFormat = outputFormat || TenantService.get(TENANT.DATE_FORMAT);
    if (date && inputFormat && outputFormat) {
      try {
        formattedDate = moment(date, inputFormat).format(outputFormat);
      } catch (e) {}
    }

    return formattedDate;
  }
  static makeCopyOfObject(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  static isEmptyObject = (objectToCheck) => {
    if (
      objectToCheck === null ||
      objectToCheck === undefined ||
      objectToCheck.length === 0
    )
      return true;
    if (Array.isArray(objectToCheck)) return !objectToCheck.length;
    if (typeof objectToCheck === "string") return !objectToCheck.trim().length;
    return Object.keys(objectToCheck).length === 0;
  };

  ///////////////////////Mobile App Only////////////////////////////
  static postMobileAppActions(action) {
    window.ReactNativeWebView.postMessage(action);
  }
  /////////////////////////////////////////////////////////////////
  static getFileIcon = (fileName) => {
    let fileType = fileName?.split(".");
    switch (fileType.pop()) {
      case FILE_TYPE.TXT:
        return DKIcons.doc_type.ic_txt;

      case FILE_TYPE.PDF:
        return DKIcons.doc_type.ic_pdf;

      case FILE_TYPE.JPG:
      case FILE_TYPE.JPEG:
        return DKIcons.doc_type.ic_jpg;
      case FILE_TYPE.PNG:
      case FILE_TYPE.SVG:
      case FILE_TYPE.BMP:
        return DKIcons.doc_type.ic_png;

      case FILE_TYPE.DOC:
      case FILE_TYPE.DOCX:
        return DKIcons.doc_type.ic_doc;

      case FILE_TYPE.XLSX:
      case FILE_TYPE.XLS:
        return DKIcons.doc_type.ic_xls;
      case FILE_TYPE.CSV:
        return DKIcons.doc_type.ic_csv;

      case FILE_TYPE.PPTX:
      case FILE_TYPE.PPT:
        return DKIcons.doc_type.ic_ppt;
      default:
        return ic_attachment;
    }
  };

  static getFormattedAddressObj = (address) => {
    const line1 =
      address && !Utility.isEmpty(address.address1) ? address.address1 : "";
    const line2 =
      (address && !Utility.isEmpty(address.address2)) ||
      (address && !address.address2)
        ? address.address2
        : "";
    const city = address && !Utility.isEmpty(address.city) ? address.city : "";
    const state =
      address && !Utility.isEmpty(address.state) ? address.state : "";
    const country =
      address && !Utility.isEmpty(address.country) ? address.country : "";
    const postalCode =
      address && !Utility.isEmpty(address.postalCode) ? address.postalCode : "";
    let cityAndState = "";
    let countryAndPostalCode = "";

    if (city !== "" && state !== "") {
      cityAndState = `${city}, ${state}`;
    }
    if (city !== "" && state === "") {
      cityAndState = `${city}`;
    }
    if (city === "" && state !== "") {
      cityAndState = `${state}`;
    }

    if (country !== "" && postalCode !== "") {
      countryAndPostalCode = `${country}, ${postalCode}`;
    }
    if (country !== "" && postalCode === "") {
      countryAndPostalCode = `${country}`;
    }
    if (country === "" && postalCode !== "") {
      countryAndPostalCode = `${postalCode}`;
    }

    return { line1, line2, cityAndState, countryAndPostalCode };
  };
}

export function showDelayedLoader() {
  return setTimeout(() => {
    showLoader();
  }, ApiConstants.LOADING_MASK_DELAY);
}

export function removeDelayedLoader(timeout) {
  clearTimeout(timeout);
  removeLoader();
}
export function isUndefined(value) {
  return typeof value === "undefined";
}
