import { useState, useRef, useEffect, useLayoutEffect } from "react";
import { DKLabel, DKButton, DKListPicker, DKIcons } from "deskera-ui-library";
import MoreIconBlack from "../../assets/icons/ic_more_vertical.png";
import MoreIconWhite from "../../assets/icons/white/ic_more.png";
import SideBarService from "../../services/Sidebar";

/*
PROPS:
 - className
 - data ([{id, name}])
 - selectedId (id: selected Segment)
 - onChange
 - onClickNew
 - maxWidth (Controls maxWidth for whole segmentListContainer)
*/
const SegmentListContainer = ({
  data,
  selectedId,
  onChange,
  onClickNew,
  maxWidth,
  isAddEnabled,
  customTab = false,
}) => {
  const [showSegmentPicker, setShowSegmentPicker] = useState(false);
  const [showMoreList, setShowMoreList] = useState([]);
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [controlWidth, setControlWidth] = useState(getListWidth());
  const segmentListElement = useRef();

  function onResize(e) {
    setControlWidth(getListWidth());
  }

  useEffect(() => {
    window.addEventListener("resize", onResize);
    window.addEventListener("onSideBarChange", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("onSideBarChange", onResize);
    };
  }, []);

  /*   useEffect(() => {
    const container = segmentListElement.current;
    if (!container) return;

    const selectedElement = container.querySelector(`#segment${selectedId}`);
    selectedElement?.scrollIntoView({ block: "nearest", inline: "center" });
  }, [selectedId]); */

  function getElementEndPosition(element) {
    return element.offsetLeft + element.clientWidth;
  }

  useLayoutEffect(() => {
    const container = segmentListElement.current;
    const segmentControls = container.childNodes;

    /* Reset Segments Display */
    for (const segmentControlElement of segmentControls) {
      segmentControlElement.hidden = false;
    }

    const containerEnd = getElementEndPosition(container);
    const overflownElements = [];

    for (const segmentControlElement of segmentControls) {
      const segmentIndex = Number(
        segmentControlElement.getAttribute("data-index")
      );

      if (getElementEndPosition(segmentControlElement) > containerEnd) {
        overflownElements.push(segmentIndex);
        segmentControlElement.hidden = true;
      }
    }

    setShowMoreList(overflownElements);
  }, [data, controlWidth]);

  function getListWidth() {
    /* More Dropdown & Add buttons width */
    const controlsWidth = 70;
    const availableWidth = SideBarService.getContainerWidth();

    let requiredWidth = availableWidth - controlsWidth;

    if (maxWidth) {
      requiredWidth = Math.min(requiredWidth, maxWidth - controlsWidth);
    }

    return requiredWidth;
  }
  return (
    <div className="row m-v-l position-relative justify-content-center">
      {/* <div
        className="row bg-white border-m border-radius-m shadow-s align-items-start"
        style={{ overflow: "hidden" }}
      > */}
      <div
        className="d-flex shadow-s" /* "show-scroll-bar" */
        style={{
          overflow: "hidden",
          /* overflow: "auto hidden", // for horizontal screen */
          maxWidth: controlWidth,
          /* Adjusting list maxWidth for accomodating showMore & New Segment buttons */
        }}
        ref={segmentListElement}
      >
        {data.map((segment, index) => (
          <div
            id={`segment${segment.id}`}
            key={`segment-${index}`}
            data-index={index}
            onClick={() => onChange(segment, index)}
            className={`${
              selectedId === segment.id
                ? "bg-app text-white fw-m"
                : customTab
                ? "bg-gray3"
                : "bg-white"
            } ${index === 0 && "segment-ctrl-first "}
                      ${
                        !isAddEnabled &&
                        index === data.length - 1 &&
                        "segment-ctrl-last"
                      }
              `}
            style={{
              borderLeft: `${index ? "1px solid lightgray" : "none"}`,
              height: 30,
            }}
          >
            <DKLabel
              className={"p-h-l p-v-s cursor-hand "}
              style={{
                overflowWrap: "unset",
                whiteSpace: "nowrap",
              }}
              text={segment.name}
            />
          </div>
        ))}
      </div>
      <div className="d-flex shadow-s">
        {showMoreList.length ? (
          <DKButton
            style={{
              borderLeft: "1px solid lightgray",
              padding: "7px 8px",
              borderRadius: "0 6px 6px 0px",
            }}
            className={`${
              showMoreList.includes(selectedId) ? "bg-app" : "bg-white"
            }`}
            icon={
              showMoreList.includes(selectedId) ? MoreIconWhite : MoreIconBlack
            }
            onClick={() => {
              setShowSegmentPicker(true);
            }}
          />
        ) : null}
        {isAddEnabled ? (
          <DKButton
            style={{
              borderLeft: "1px solid lightgray",
            }}
            className="segment-ctrl-last bg-white"
            icon={DKIcons.ic_add}
            onClick={onClickNew}
          />
        ) : null}
        {/* </div> */}
        {showSegmentPicker ? (
          <DKListPicker
            title="More Options"
            className="position-absolute border-m shadow-m z-index-3"
            style={{ top: 10, right: 32, width: 200 }}
            data={showMoreList.map((segmentId) =>
              selectedId === segmentId
                ? `${data[segmentId].name} ✓`
                : data[segmentId].name
            )}
            onSelect={(id) => {
              const index = showMoreList[id];
              onChange(data[index], index);
              setShowSegmentPicker(false);
            }}
            onClose={() => {
              setShowSegmentPicker(false);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SegmentListContainer;
