import { useEffect, useState } from "react";
import {showAlert,showToast,TOAST_TYPE} from  "deskera-ui-library";
import DataGridHolderPayroll from "../common/DataGridHolderPayroll";
import { TableManger, TABLES, TABLE_DISPLAY_NAME } from "../../managers/TableManager";
import { fetchExpenseGroup, selectExpenseGroup } from "../../redux/slices/ExpenseGroupSlice";
import { useDispatch, useSelector } from "react-redux";
import { ExpenseGroupColumnConfig, parseColumnConfig, parseVisibleColumnConfig } from "../../services/Config";
import ExpenseService from "../../services/ExpenseService";
import Utility, { deepClone, removeDelayedLoader, showDelayedLoader } from "../../utils/Utility";
import AddEditExpenseGroup from "./AddEditExpenseGroup";
import Popup from "../common/Popup";
import UserService from "../../services/UserService";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import { useHistory } from "react-router";
import ExpenseManager from "../../managers/ExpenseManager";

export default function ExpenseGroup(props) {
    const history = useHistory();
    const expenseGroup = useSelector(selectExpenseGroup);
    const initial = {
        columnData: [],
        rowData: [],
        originalData: [],
        filter: []
    };
    const dispatch = useDispatch();
    const [expenseGroupData, setExpenseGroup] = useState(initial);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [filters, setFilters] = useState([]);
    const [addGroupPopup, setAddGroupPopup] = useState({
        isVisible: false,
        isLoadGroup:true,
        expenseData:null
    })
    const [groupUserPopup, setGroupUserPopup] = useState({
        isVisible: false
    })
    const [users, setAllUsers] = useState([])
    useEffect(()=>{
        RouteManager.setPresenter({props: {...props, history: history}});
        getAllUser()
    },[])
    useEffect(() => {
        if(!addGroupPopup.isVisible && addGroupPopup.isLoadGroup) {
            fetchGroup();
        }
    }, [addGroupPopup])

    useEffect(() => {
        if (!Utility.isEmptyObject(expenseGroup)) {
            const { content, pageable, totalPages } = expenseGroup;
            const rowData = ExpenseGroupColumnConfig(content);
            const columnConfig = parseVisibleColumnConfig(parseColumnConfig(TableManger.getTableVisibleColumns(TABLES.PEOPLE_EXPENSE_GROUP), TABLES.PEOPLE_EXPENSE_GROUP));
            const data = {
                columnData: deepClone(columnConfig),
                rowData: rowData,
                filter: [],
                originalData: rowData
            };
            setExpenseGroup(data);
            setCurrentPage(pageable.pageNumber + 1);
            setTotalPageCount(totalPages);
        }
    }, [expenseGroup])
    const getAllUser=() => {
        UserService.getUsers({limit:100,page:0}).then(res=>{
            if(res && res.content) {
                let allUsers = res.content.map(r=>{
                    r.name= `${r.firstName} ${r.lastName}`
                    return r
                }) ;
                ExpenseManager.setUsers(allUsers);
                setAllUsers(allUsers);
            }
        },
        error=>{
            console.log(error)
        })
    }
    const fetchGroup = async (apiConfig) => {
        const timeout = showDelayedLoader();
        ExpenseService.apiConfig = apiConfig;
        await dispatch(fetchExpenseGroup());
        removeDelayedLoader(timeout);
    }
    const newGroup = () => {
        setAddGroupPopup({ isVisible: true, isLoadGroup:false,expenseData:null })
    }
    const onFilter = (dataList, queries) => {

    }
    const onRowEdit = (data) => {
        if(data.rowData?.data){
            // setAddGroupPopup({ isVisible: true,isLoadGroup:data,expenseData:data.rowData.data })
            RouteManager.navigateToPage(PAGE_ROUTES.EXPENSE.EXPENSE_GROUP_USER+'/'+data.rowData?.data?.id+'/'+data.rowData?.data?.name)
        }
    }
    const onRowDelete = (data) => {
        if(data.rowData?.data?.memberCount ) {
            showAlert("Sorry","The group with members can be not deleted.");
        } else if(data.rowData.data.id) {
            ExpenseService.deleteGroup(data.rowData.data.id).then(res=>{
                showToast("Group has been deleted successfully", TOAST_TYPE.SUCCESS);
                fetchGroup();
            },error=>{
                if(error && error.message) {
                    showToast(error.message, TOAST_TYPE.FAILURE);
                }
            })
        }
    }
    return (
        <>
            {addGroupPopup.isVisible && <Popup popupClass={" popup-overflow "}>
                <AddEditExpenseGroup {...props} expenseData={addGroupPopup.expenseData} users={users} onClose={(data) => setAddGroupPopup({ isVisible: false,isLoadGroup:data,expenseData:null })} />
            </Popup>}

            <div className="column " style={{ height: '90%' }}>
                <div className="flex-1 position-relative" style={{ minHeight: "200px" }}>
                    <DataGridHolderPayroll
                        tableName={TABLES.PEOPLE_EXPENSE_GROUP}
                        displayTableName={TABLE_DISPLAY_NAME[TABLES.PEOPLE_EXPENSE_GROUP]}
                        allowColumnEdit={false}
                        allowColumnDelete={false}
                        allowColumnShift={false}
                        allowSearch={false}
                        allowFilter={false}
                        allowColumnAdd={false}
                        allowRowAdd={false}
                        refresh={false}
                        allowDataExport={false}
                        allowRowEdit={true}
                        showContextEdit={false}
                        isActionEnabled={true}
                        contextMenu={true}
                        isMenuVisible={false}
                        gridData={expenseGroupData}
                        filter={filters}
                        currentPage={currentPage}
                        totalPageCount={totalPageCount}
                        onFilter={(dataList, queries) => {
                            onFilter(dataList, queries);
                        }}
                        onRowDelete={(data) => {
                            onRowDelete(data);
                        }}
                        onRowEdit={(data) => {
                            onRowEdit(data);
                        }}
                        addButtonConfig={[{
                            title: "+ Create New Group",
                            className: "bg-app text-white ml-s",
                            onClick: () => {
                                newGroup();
                            }
                        }]}
                        onPaginationClick={(requestedPageNumber) => {
                            fetchGroup({
                                ...ExpenseService.apiConfig,
                                params: {
                                    limit: 20,
                                    page: requestedPageNumber - 1
                                }
                            })
                        }}
                        onRowOpenBtnHidden={false}
                    />
                </div>
            </div>
        </>
    )
}