import http from "../http";
import ApiConstants from "../utils/ApiConstants";

class DashboardService {
  static getClaimsTrendByInterval(interval) {
    const url = `${
      ApiConstants.URL.BASE + ApiConstants.URL.EXPENSE.CLAIMS_BY_TREND
    }\?trend_view=${interval}`;
    return http.get(url).catch((err) => {
      console.error("Error while claims trend ", err);
      return Promise.reject(err);
    });
  }

  static getDashboardSetting() {
    const url = `${ApiConstants.URL.SETTINGS.USER}?appName=EXPENSE`;
    return http.get(url).catch((err) => {
      console.error("Error getting dashboard settings ", err);
      return Promise.reject(err);
    });
  }

  static updateDashboardSetting(data) {
    const url = `${ApiConstants.URL.SETTINGS.USER}`;
    return http.patch(url, data).catch((err) => {
      console.error("Error while updating dashboard settings ", err);
      return Promise.reject(err);
    });
  }
}

export default DashboardService;
