import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ExpenseService from "../../services/ExpenseService";

const initialState = {
  data: {},
  status: "idle",
};
export const fetchExpenseCategories = createAsyncThunk(
  "expenseCategories",
  async () => {
    const response = await ExpenseService.getCategory(true);
    return response;
  }
);
export const ExpenseCategoriesSlice = createSlice({
  name: "expenseCategories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpenseCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchExpenseCategories.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload;
      });
  },
});

export const selectExpenseCategories = (state) => {
  return state.expenseCategories.data;
};

export default ExpenseCategoriesSlice.reducer;
