import ApiConstants from "../utils/ApiConstants";
import http from "../http";
import UserManager from "../managers/UserManager";
import { deepClone } from "../utils/Utility";
export default class UserService {
  static saveUser(param) {
    const url = ApiConstants.URL.BASE + ApiConstants.URL.PAYROLL.GET_USERS;
    return http.post(`${url}`, param);
  }
  static isEmailDuplicate(param) {
    const url = `${
      ApiConstants.URL.BASE + ApiConstants.URL.PEOPLE.TENANT
    }/email/validate?emailId=${param.emailId}&userId=${param.userId}`;
    return http.get(`${url}`);
  }
  static getAllUsers() {
    const url = `${
      ApiConstants.URL.BASE + ApiConstants.URL.PAYROLL.GET_USERS
    }?ignTeamData=true`;
    return http.get(url);
  }
  static getUsers(param) {
    const url = `${ApiConstants.URL.BASE + ApiConstants.URL.USER.USERS}`;
    return http.get(url, { params: param });
  }

  static isContactDuplicate(params) {
    const url = `${ApiConstants.URL.USER.DUPLICATE_CONTACT}?contact=${params.contact}&userId=${params.userId}`;
    return http.get(url);
  }

  static isEmailDuplicateContractor(param) {
    const url = `${
      ApiConstants.URL.BASE + ApiConstants.URL.PEOPLE.TENANT
    }/email/validate?emailId=${param.emailId}`;
    return http.get(`${url}`);
  }

  static setUserNameAndEmail(iamUserId) {
    http
      .get(
        `${
          ApiConstants.URL.BASE + ApiConstants.URL.USER.USER_INFO_BY_ID
        }${iamUserId}`
      )
      .then(
        (response) => {
          if (response?.content?.length > 0 && response.content[0]) {
            const name = `${response.content[0].firstName} ${response.content[0].lastName}`;
            const email = response.content[0].email;
            const userDetails = deepClone(UserManager.getUserDetails());
            if (userDetails) {
              userDetails["name"] = name || userDetails["name"];
              userDetails["email"] = email || userDetails["email"];
              userDetails["roleShortInfo"] = response.content[0]?.roleShortInfo || [];
              UserManager.setUserDetails(userDetails);
            }
          }
        },
        (error) => {}
      );
  }
}
