import ApiConstants from "../utils/ApiConstants";
import { showLoader, removeLoader } from "deskera-ui-library";
import http from "../http";
import IAMManager from "../managers/IamManager";
import UserManager from "../managers/UserManager";

export default class IAM {
  static getLoginStatus(params) {
    return http.get(ApiConstants.URL.IAM, { params: params }).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
  static logOut() {
    showLoader("Logging out...");
    return http.get(ApiConstants.URL.IAM.LOG_OUT).then(
      (res) => {
        removeLoader();
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }
  static switchTenant(params) {
    showLoader("Switching organization...");
    localStorage.setItem("selectedPipeline", 0);
    return http.post(ApiConstants.URL.IAM.SWITCH_TENANT, params).then(
      (res) => {
        removeLoader();
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }
  static getTwoFactorAuthStatus(params) {
    showLoader("Getting status...");
    return http.get(ApiConstants.URL.IAM.TFA_STATUS, { params: params }).then(
      (res) => {
        removeLoader();
        IAMManager.setTFAStatus(res);
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }
  static async checkIfUserSubscribed() {
    return http
      .get(ApiConstants.URL.SUBENGINE.SUBSCRIPTION_PEOPLE)
      .then((data) => {
        UserManager.setUserPlanDetails(data);
        if (UserManager.isPlanActive()) {
          return Promise.resolve("Authorized User");
        } else {
          return Promise.reject(data);
        }
      })
      .catch((error) => {
        return Promise.reject("Failed to fetch user subscription");
      });
  }
}
