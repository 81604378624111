import { COUNTRY } from "./CountryConstant";

export const ZIPCODE_REGEX_IMPORT = {
  [COUNTRY.IN]: "^([1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3})$",
  [COUNTRY.US]: "[0-9]*",
};

export const ROUTING_NUMBER_REGEX = {
  [COUNTRY.US]: "^(0[1-9]|1[0-2]|2[1-9]|3[0-2])([0-9]{7})*$",
};

export const ZIPCODE_REGEX = {
  IND: "(^([1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3})$)",
};
