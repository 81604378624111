import { Component } from "react";
import { DKLabel, showAlert, DKIcon } from "deskera-ui-library";
import { TABLES } from "../../managers/TableManager";
import { PRODUCT_ICON } from "../dashboard/DashboardCard";

class AddNewList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewClaims: false,
    };
  }

  render() {
    const list = [
      {
        title: "Claims",
        icon: PRODUCT_ICON[TABLES.PEOPLE_EXPENSE_CLAIMS],
      },
    ];

    return (
      <div
        className="column bg-white border-radius-s border-m shadow-m position-absolute pb-s"
        style={{ top: 40, right: -5, width: 150, zIndex: 9999 }}
      >
        <DKLabel text="Create New" className="fw-m fs-m pt-r pb-s p-h-s" />
        {list.map((obj, index) => {
          return (
            <div
              className="row listPickerBG cursor-hand parent-width border-box"
              style={{ padding: 12 }}
              onClick={() => {
                this.navigateToCreate(index);
              }}
              key={index}
            >
              <DKIcon src={obj.icon} className="ic-s" />
              <DKLabel text={obj.title} className="ml-r" />
            </div>
          );
        })}
      </div>
    );
  }

  navigateToCreate(index) {
    switch (index) {
      case 0:
        this.props.selectedValue(index)
        break;
      default:
        showAlert("Cell tapped ", "Index- " + index);
        break;
    }
    this.props.onClose();
  }
}

export default AddNewList;
