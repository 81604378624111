import { CURRENCY } from "../utils/Enum";
import { Subject } from "rxjs";
export default class TenantManager {
  static allTenants = null;
  static tenantDetails = null;
  static currency = null;
  static peopleTenant = null;
  static tenantDetails = null;

  static setTenantDetails(data) {
    TenantManager.tenantDetails = data;
  }

  static getTenantDetails() {
    return TenantManager.tenantDetails;
  }

  static setAllTenants(data) {
    TenantManager.allTenants = data;
  }
  static getAllTenants() {
    return TenantManager.allTenants;
  }
  static setTenantDeatils(data) {
    TenantManager.tenantDetails = data;
    this.setCurrency();
  }
  static getTenantDetails() {
    return TenantManager.tenantDetails;
  }
  static setCurrency() {
    TenantManager.currency =
      CURRENCY[
        TenantManager.tenantDetails.currency
          ? TenantManager.tenantDetails.currency
          : "SGD"
      ];
  }
  static getCurrency() {
    return TenantManager.currency;
  }

  static setPeopleTenantData(data) {
    TenantManager.peopleTenant = data;
    TenantManagerEvent.setData(data);
  }

  static getPeopleTenantData() {
    return TenantManager.peopleTenant;
  }
  static getProfileData() {
    return TenantManager.getPeopleTenantData()?.tenant?.profileStages;
  }
  static setProfileData(data) {
    TenantManager.peopleTenant.tenant.profileStages = data;
    TenantManagerEvent.setData(data);
  }
}
const subject = new Subject();
export const TenantManagerEvent = {
  setData: (data) => subject.next({ tenantData: data }),
  clearData: () => subject.next({}),
  getTenantData: () => subject.asObservable(),
};
