import ApiConstants from "../utils/ApiConstants";
import http from "../http";

class ExpenseIntegrationService {
  static getExpenseIntegrationSettings() {
    const url = `${
      ApiConstants.URL.BASE + ApiConstants.URL.EXPENSE.GET_INTEGRATION_MAPPING
    }`;
    return http.get(`${url}`).catch((err) => {
      console.error("Error while fetching Integration settings ", err);
      return Promise.reject(err);
    });
  }

  static getAccounts() {
    const url = `${
      ApiConstants.URL.BASE +
      ApiConstants.URL.COMPLIANCE.IN.GET_EXPENSE_AND_LIABILITY_ACCOUNTS
    }`;
    return http.get(`${url}`).catch((err) => {
      console.error(
        "Error while fetching Expense and Liability Accounts ",
        err
      );
      return Promise.reject(err);
    });
  }

  static updateIntegrationToggle(status) {
    const url = `${
      ApiConstants.URL.BASE +
      ApiConstants.URL.EXPENSE.PATCH_INTEGRATION_TOGGLE(status)
    }`;
    return http.patch(`${url}`).catch((err) => {
      return Promise.reject(err);
    });
  }

  static saveIntegrationSettings(params) {
    const url = `${
      ApiConstants.URL.BASE + ApiConstants.URL.EXPENSE.GET_INTEGRATION_MAPPING
    }`;
    return http.put(url, params).catch((err) => {
      return Promise.reject(err);
    });
  }

  static syncRevertJEs(body, action) {
    const url = `${
      ApiConstants.URL.BASE + ApiConstants.URL.EXPENSE.SYNC_REVERT_JE(action)
    }`;
    return http.post(url, body).catch((err) => {
      return Promise.reject(err);
    });
  }
}

export default ExpenseIntegrationService;
