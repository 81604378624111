import {
  DKDataGrid,
  DKLabel,
  DKIcons,
  INPUT_TYPE,
  DKButton,
  DKIcon,
  shiftArrayElement,
  DKTooltipWrapper,
  showAlert,
  showToast,
  TOAST_TYPE,
} from "deskera-ui-library";
import { useEffect, useRef, useState } from "react";
import {
  COLUMN_CODE,
  TABLES,
} from "../../managers/TableManager";
import { AddRecordButtonConfig, IColumn } from "../../model/Table";
import ContextMenu from "./ContextMenu";
import ic_visibility from "../../assets/icons/black/ic_visibility.png";
import SideBarService from "../../services/Sidebar";
import ic_no_data from "../../assets/icons/ic_no_data_3.png";
import Utility from "../../utils/Utility";
import { isViewportLarge } from "../../utils/ViewportSizeUtils";
import {
  CANDIDATE_ACCEPT_FILE_TYPE,
  ENTITY,
  FILE_TYPE,
} from "../../utils/Constants";
import i18next from "i18next";
import ExpenseService from "../../services/ExpenseService";

export interface IDataGridHolderPayrollProps {
  tableName: string;
  displayTableName?: string;
  filter?: any;
  allowExpand?: boolean;
  allowRowEdit?: boolean;
  allowColumnEdit?: boolean;
  allowRowAdd?: boolean;
  allowColumnAdd?: boolean;
  allowColumnDelete?: boolean;
  allowColumnShift?: boolean;
  allowSearch?: boolean;
  allowFilter?: boolean;
  allowDataExport?: boolean;
  allowColumnSort?: boolean;
  allowBulkOperation?: boolean;
  onRowExpand?: (data: any) => void;
  onRowUpdate?: (data: any) => void;
  onColumnUpdate?: (data: any) => void;
  onColumnShift?: (data: any) => void;
  onRowAdd?: (data: any) => void;
  onRowClick?: (rowIndex: any, rowData: any) => void;
  onColumnAdd?: (data: any) => void;
  onColumnDelete?: (data: any) => void;
  onSearch?: (searchTerm: any) => void;
  onFilter?: (updatedData: any[], config: any[]) => void;
  onRowDelete?: (data: any) => void;
  onRowEdit?: (data: any, isFromRowOpen?: any) => void;
  onCancelContext?: () => void;
  onRowSelect?: (data: any) => void;
  onPaginationClick?: (requestedPageNumber: any) => void;
  onClockIn?: (data: any) => void;
  onClockOut?: (data: any) => void;
  addButtonConfig?: AddRecordButtonConfig;
  refresh: boolean;
  currentPage?: number;
  totalPageCount?: number;
  gridData?: any;
  width?: string | number;
  onRowDetailedView?: (data: any) => void;
  onTerminateEmployee?: (data: any) => void;
  onView?: (data: any) => void;
  onDownloadAttachment?: (data: any) => void;
  onDesignDocument?: (data: any) => void;
  isActionEnabled?: boolean;
  onProcessPayroll?: (data: any) => void;
  onStatusChange?: (data: any) => void;
  contextMenu?: boolean;
  gridWidth?: number;
  isMenuVisible?: boolean;
  showContextEdit?: boolean;
  isHeaderHidden?: boolean;
  onSelection?: (data: any) => void;
  onRowOpenBtnHidden?: boolean;
  isActionColumnFirst?: boolean;
  isHideTooltip?: boolean;
  isShowRevert?: boolean;
  onRowSelectActionBtn?: any;
  onRevertClick?: (data: any) => void;
  latestProcessedPayrollId?: any;
  onDocumentDownloadSigned?: (data: any, isSigned: boolean) => void;
  onDocumentUploadSigned?: (data: any, isSigned: boolean) => void;
  onDocumentDelete?: (data: any, isSigned: boolean) => void;
  onDocumentUploadAndReplace?: (data: any, isSigned: boolean) => void;
  onSort?: (data: any) => void;
  addMenuButton?: any;
  uploadFile?: (data: any) => void;
  dateFormat?: string;
  hideTableName?: any;
  onCheckPrint?: () => void;
  isCheckPrint?: boolean;
}
export interface IDataGridHolderPayrollState {
  tableName: string;
  columns: IColumn[];
  rows: any[];
  currentPage: number;
  totalPageCount: number;
  showContextMenu: boolean;
  needNoDataView: boolean;
  filter: any[];
  originalData: any[];
  width: number;
  isAllRowSelected: boolean;
  onRowOpenBtnHidden?: boolean;
  isShowRevert?: boolean;
  attachmentData?: any;
  // gridWidth?: number;
}

const DataGridHolderPayroll: React.FC<IDataGridHolderPayrollProps> = (
  props
) => {
  const mounted: React.MutableRefObject<boolean> = useRef(false);

  const initialState: IDataGridHolderPayrollState = {
    tableName: "",
    columns: [],
    rows: [],
    currentPage: 1,
    totalPageCount: 1,
    showContextMenu: false,
    needNoDataView: false,
    filter: [],
    originalData: [],
    width: SideBarService.getContainerWidth(),
    isAllRowSelected: false,
    attachmentData: null,
  };
  const [state, setState] = useState<IDataGridHolderPayrollState>(initialState);
  let [selection, setSelection] = useState<any[]>([]);
  const [width, setWidth] = useState(SideBarService.getContainerWidth());
  const attachmentOpenFileRef: React.MutableRefObject<any> = useRef();
  const [attachmentPickerExists, setAttachmentPickerExists] = useState(false);
  useEffect(() => {
    setSelection([]);
  }, [props]);

  useEffect(() => {
    window.addEventListener("resize", windowSizeUpdated);
    window.addEventListener("onSideBarChange", windowSizeUpdated);
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      setState({
        ...state,
        tableName: props.tableName,
        rows: props.gridData.rowData,
        columns: props.gridData.columnData,
        filter: props.filter,
        originalData: props.gridData.originalData,
        needNoDataView: props.gridData.rowData.length === 0,
        currentPage: props.currentPage ?? 1,
        totalPageCount: props.totalPageCount ?? 1,
        showContextMenu: false,
        isAllRowSelected: false,
        onRowOpenBtnHidden: props.onRowOpenBtnHidden
          ? props.onRowOpenBtnHidden
          : false,
        isShowRevert: false,
      });
      if (
        props.tableName == TABLES.PEOPLE_MULTIPLE_EXPENSE &&
        !Utility.isEmpty(state.columns)
      ) {
        updateColumns();
      }
    }
    return () => {
      setState(initialState);
      window.removeEventListener("resize", windowSizeUpdated);
      window.removeEventListener("onSideBarChange", windowSizeUpdated);
    };
  }, [props]);

  const windowSizeUpdated = () => {
    setWidth(SideBarService.getContainerWidth());
  };

  const getNoDataView = () => {
    return (
      <div
        className="column align-self-center align-items-center position-absolute"
        style={{ top: "30%", pointerEvents: "none" }}
      >
        <DKIcon
          src={ic_no_data}
          className="ic-l"
          style={{ opacity: 0.2, marginTop: 70 }}
        />
        <DKLabel text="No data found" className="fw-m mt-l" />
        <DKLabel
          text="Once data is available, it will appear here"
          className="text-gray mt-s "
        />
      </div>
    );
  };

  const getActionButtonsColumn = () => {
    let options = [];

    let editIcon = {
      // icon: DKIcons.ic_edit,
      title: "Edit",
      className: "p-0 text-app fw-m text-underline",
      onClick: (data: any) => {
        if (props.onRowEdit) {
          props.onRowEdit(data);
        }
      },
    };
  if (props.tableName == TABLES.PEOPLE_EXPENSE_GROUP) {
      options.push({
        title: "View",
        className: "p-0 text-app fw-m text-underline",
        onClick: (data: any) => {
          if (props.onRowEdit) {
            props.onRowEdit(data);
          }
        },
      });
    } else if (props.tableName == TABLES.PEOPLE_EXPENSE_GROUP_USER) {
      options.push({
        title: "Move User",
        className: "p-0 text-app fw-m text-underline",
        onClick: (data: any) => {
          if (props.onRowEdit) {
            props.onRowEdit(data);
          }
        },
      });
    } else {
      options.push(editIcon);
    }

    let actionColumnName = "Actions";
    let actionColumnWidth = 150;
    let width = 150;
    switch (props.tableName) {
      case TABLES.PEOPLE_EXPENSE_GROUP:
      case TABLES.PEOPLE_EXPENSE_GROUP_USER:
        actionColumnWidth = 200;
        break;
    }
    return {
      key: "actions",
      name: actionColumnName,
      className: "p-0",
      type: INPUT_TYPE.BUTTON,
      width: actionColumnWidth,
      options,
    };
  };
  const getGridContextMenu = () => {
    let options:any = [];
    let edit = {
      title: "Edit",
      icon: DKIcons.ic_edit,
      onClick: (data:any) => {
        if (props.onRowEdit) {
          props.onRowEdit(data);
        }
      },
    };

    let deleteRow = {
      title: "Delete",
      icon: DKIcons.ic_delete,
      onClick: (data:any) => {
        if (props.onRowDelete) {
          props.onRowDelete(data);
        }
      },
    };
    let terminate = {
      title: "Terminate",
      icon: DKIcons.ic_close,
      className: "p-0",
      onClick: (data: any) => {
        if (props.onTerminateEmployee) {
          props.onTerminateEmployee(data);
        }
      },
    };

    let employeeView = {
      title: "View",
      icon: ic_visibility,
      className: "p-0",
      onClick: (data: any) => {
        if (props.onView) {
          props.onView(data);
        }
      },
    };

    switch (props.tableName) {
      default:
        options = [deleteRow];
    }

    return options;
  };
  const getRecords = (search = "", payload = state.filter): void => {
    console.log(`${state.tableName}: getRecords called...`);
  };
  const getColumns = () => {
    console.log(`${state.tableName}: getColumns called...`);
  };

  const updateColumns = () => {
    if (
      props.tableName == TABLES.PEOPLE_MULTIPLE_EXPENSE &&
      !Utility.isEmpty(state.columns)
    ) {
      let gridColumns = props.gridData.columnData; //TableManger.getTableVisibleColumns(props.tableName); //state.columns //For Future reference
      gridColumns = gridColumns.map((col:any) => {
        switch (col.columnCode) {
          case COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.ATTACHMENTS:
            col["renderer"] = attachmentRenderer;
            col["allowFilter"] = false;
            break;

          default:
        }

        col["disableWebhook"] = ![
          INPUT_TYPE.TEXT,
          INPUT_TYPE.EMAIL,
          INPUT_TYPE.NUMBER,
        ].includes(col.type);

        return col;
      });
      setState({
        ...state,
        tableName: props.tableName,
        rows: props.gridData.rowData,
        filter: props.filter,
        originalData: props.gridData.originalData,
        needNoDataView: props.gridData.rowData.length === 0,
        currentPage: props.currentPage ?? 1,
        totalPageCount: props.totalPageCount ?? 1,
        showContextMenu: false,
        isAllRowSelected: false,
        onRowOpenBtnHidden: props.onRowOpenBtnHidden
          ? props.onRowOpenBtnHidden
          : false,
        isShowRevert: false,
        columns: getUpdatedColumnData(gridColumns),
      });
    }
  };
  const attachmentRenderer = ({ value, rowIndex }: any) => {
    let attachment = [],
      jsonData = [];
    if (!Utility.isEmpty(value)) {
      jsonData = Utility.decodeJSON(value);
      jsonData.forEach((data:any) => {
        attachment.push(
          <DKTooltipWrapper content={renderFile(data)} tooltipClassName="">
            <DKButton
              icon={Utility.getFileIcon(data.title)}
              className="p-0"
              style={{ padding: "0px 2px", opacity: 0.8 }}
              onClick={() => {
                window.open(data.path, "_blank");
              }}
            />
          </DKTooltipWrapper>
        );
      });
    }
    attachment.push(
      <div
        className="parent-height parent-width cursor-hand"
        onClick={() => {
          addAttachment(rowIndex);
        }}
      />
    );
    return (
      <div
        className="row"
        style={{ overflowX: "auto", scrollbarWidth: "thin", height: "inherit" }}
      >
        {attachment}
      </div>
    );
  };
  const getUpdatedColumnData = (columns:any) => {
    if (!isViewportLarge()) {
      return columns.map((columnData:any) => ({
        ...columnData,
        width: Math.floor(columnData.width / 1.5),
      }));
    }

    return columns;
  };
  const renderFile = (file:any) => {
    let fileType = file.title?.split(".").pop();
    if (fileType === FILE_TYPE.TXT || fileType === FILE_TYPE.PDF) {
      return `<iframe width="180" src="${file.path}" title="${file.title}"></iframe>`;
    } else if (
      fileType === FILE_TYPE.JPG ||
      fileType === FILE_TYPE.JPEG ||
      fileType === FILE_TYPE.PNG ||
      fileType === FILE_TYPE.SVG
    ) {
      return `<img width="180" src="${file.path}" alt="${file.title}" />`;
    } else {
      return i18next.t("NO_PREVIEW");
    }
  };
  const addAttachment = (index:any) => {
    let data = {
      rowData: state.rows[index],
      rowIndex: index,
    };
    setState({
      ...state,
      attachmentData: data,
    });
    openAttachmentPicker();
  };
  const openAttachmentPicker = () => {
    setAttachmentPickerExists(true);
    if (attachmentOpenFileRef) {
      attachmentOpenFileRef?.current?.click();
    }
  };
  const onRowUpdate = (data: any) => {
    console.log(data, "row update");
    if (props.onRowUpdate) props.onRowUpdate(data);
  };
  const onColumnUpdate = (data: any) => {
    // TODO: persist this in column config DB
    if (props.onColumnUpdate) props.onColumnUpdate(data);
  };
  const onColumnShift = (data: any) => {
    let { newIndex, oldIndex } = data;
    let newColumnArray = shiftArrayElement(
      [...state.columns],
      oldIndex,
      newIndex
    );
    // Table.shiftColumn(
    //   newColumnArray.map((column) => column.key),
    //   this.tableId
    // ).then((data) => {
    //   getColumns();
    // });
    if (props.onColumnShift) {
      props.onColumnShift(data);
    }
  };
  const onRowAdd = (data: any) => {
    const { rowData } = data;
    if (props.onRowAdd) {
      props.onRowAdd(rowData);
    }
  };

  const onColumnAdd = (data: any) => {
    if (props.onColumnAdd) {
      const columnData = data.columnData;
      const newColumn = {
        id: columnData.key,
        key: columnData.key,
        type: columnData.type,
        columnCode: columnData.key,
        editable: true,
        index: state.columns.length + 1,
        name: columnData.name,
        width: columnData.width,
        options: columnData.options,
        hidden: false,
        uiVisible: false,
      };
      props.onColumnAdd(newColumn);
    }
  };
  const onColumnDelete = (data: any) => {};

  const onRowClick = (rowIndex: any, rowData: any) => {
    if (props.onRowClick) {
      props.onRowClick(rowIndex, rowData);
    }
  };
  const onPagination = (requestedPageNumber: number) => {
    if (props.onPaginationClick) {
      props.onPaginationClick(requestedPageNumber);
    }
  };

  const onSearch = (searchTerm: string) => {
    if (props.onSearch) {
      props.onSearch(searchTerm);
    }
  };
  const onFilter = (data: any) => {
    if (data && data.query) {
      const queries: any[] = data.query;
      let dataList = [...state.originalData];
      queries.forEach((query) => {
        let filteredDataList: any[] = [];
        const { key, condition, value } = query;
        dataList.forEach((data) => {
          if (condition === "eq") {
            if (data[key] == value) {
              filteredDataList.push(data);
            }
          } else if (condition === "gt" && typeof data[key] === "number") {
            if (data[key] > value) {
              filteredDataList.push(data);
            }
          } else if (condition === "lt" && typeof data[key] === "number") {
            if (data[key] < value) {
              filteredDataList.push(data);
            }
          } else if (condition === "c" && data[key]) {
            if (
              data[key].toString().toLowerCase().includes(value.toLowerCase())
            ) {
              filteredDataList.push(data);
            }
          } else if (condition === "sw" && data[key]) {
            const valueLength: number = data[key].toString().length;
            const searchKey: string = data[key]
              .toString()
              .substring(0, Math.round(valueLength / 2));
            if (
              searchKey.toLowerCase().includes(value.toString().toLowerCase())
            ) {
              filteredDataList.push(data);
            }
          } else if (condition === "ew" && data[key]) {
            const valueLength: number = data[key].toString().length;
            const searchKey: string = data[key]
              .toString()
              .substring(Math.round(valueLength / 2), valueLength);
            if (
              searchKey.toLowerCase().includes(value.toString().toLowerCase())
            ) {
              filteredDataList.push(data);
            }
          }
        });
        dataList = [...filteredDataList];
      });
      if (props.onFilter) {
        props.onFilter(dataList, queries);
      }
    }
  };
  const onRowSelect = (data:any = null) => {
    if (data) {
      const index = selection.findIndex(
        (item) =>
          (data.rowData.id && item.id === data.rowData.id) ||
          (data.rowData.userId && item.userId === data.rowData.userId) ||
          (data.rowData.data &&
            data.rowData.data.id &&
            item.data.id &&
            item.data.id === data.rowData.data.id &&
            item?.data?.role?.toLowerCase() ===
              data?.rowData?.data?.role?.toLowerCase())
      );
      if (data && data.rowData && data.rowData.selected && index === -1) {
        selection.push(data.rowData);
      } else if (data && data.rowData && !data.rowData.selected) {
        selection.splice(index, 1);
      }
    }
    // setSelection(data)
    setState({
      ...state,
      isShowRevert: isRevertPayrunEnabled(),
      showContextMenu: !(selection.length === 0),
      // isAllRowSelected: selection.length === state.rows.length
    });
    // this.setState({
    //     showContextMenu: !(this.selection.length === 0),
    //     isAllRowSelected: this.selection.length === this.state.rows.length
    //   });
    if (props.onSelection) {
      props.onSelection(selection);
    }
    if (props.onRowSelect) props.onRowSelect(data);
  };
  const onAllRowSelect = (data: any) => {
    selection.splice(0, state.rows.length);
    if (data) {
      state.rows.forEach((ele) => {
        selection.push(ele);
      });
    }
    setState({
      ...state,
      showContextMenu: !(selection.length === 0),
      isAllRowSelected: data,
    });
    if (props.onSelection) {
      props.onSelection(selection);
    }
  };
  const onContextMenuOp = (data: any) => {};
  const getDeleteConfirmation = (data: any) => {
    if (props.onRowDelete) {
      props.onRowDelete(data);
      // setState({ ...state, showContextMenu: false, isAllRowSelected: false });
      setSelection([]);
    }
  };
  const isEdit = (data: any) => {
    if (props.onRowEdit) {
      props.onRowEdit(data);
    }
  };
  const onContextMenuCancel = () => {
    // selection.splice(0,selection.length)
    // setState({ ...state,showContextMenu: false });
    // getRecords()
    if (props.onCancelContext) {
      setSelection([]);
      props.onCancelContext();
    }
  };

  const showEditProp = () => {
    if (typeof props.showContextEdit === "boolean") {
      return props.showContextEdit;
    }

    return true;
  };
  const isRevertPayrunEnabled = () => {
    let isEnabled = false;
    if (selection.length == 1 && selection[0].allowToRevertDelete) {
      isEnabled = true;
    }
    return isEnabled;
  };
  const onSort = (data:any) => {
    if (props.onSort) {
      props.onSort(data);
    }
  };
  const initializeImageSelect = () => {
    document.body.onfocus = checkIt;
  };
  const checkIt = () => {
    if (document.querySelector("#dataGridAttachment")) {
      const fileElement = document.querySelector(
        "#dataGridAttachment"
      ) as HTMLInputElement;
      if (fileElement.files && fileElement.files.length === 0) {
        if (props.uploadFile) {
          props.uploadFile(null);
        }
        setAttachmentPickerExists(false);
        return;
      }
    }
  };
  const getAttachmentPicker = () => {
    return (
      <input
        id="dataGridAttachment"
        type="file"
        accept={CANDIDATE_ACCEPT_FILE_TYPE.join()}
        ref={attachmentOpenFileRef}
        style={{ display: "none" }}
        onClick={(e) => {
          initializeImageSelect();
          e.stopPropagation();
        }}
        onChange={(e:any) => {
          if (e.target.files.length > 0) {
            const fileSize = e.target.files[0].size / 1024 / 1024;
            if (fileSize > 10) {
              showAlert(
                i18next.t("FILE_SIZE_HEADER"),
                i18next.t("FILE_SIZE_ERROR")
              );
              return;
            }
            uploadImageToAWS(e.target.files[0]);
          }
        }}
      />
    );
  };
  const uploadImageToAWS = (imageData:any) => {
    let rowData = { ...state.attachmentData.rowData };
    triggerAttachmentUpload(imageData, state.attachmentData.rowIndex, rowData);
    setAttachmentPickerExists(false);
  };
  const triggerAttachmentUpload = (file: any, rowIndex: any, rowData: any) => {
    let obj = {
      entityName: ENTITY.EXPENSE,
    };
    ExpenseService.uploadAttachment(file, obj).then(
      (res: any) => {
        if (res && res.attachmentId) {
          let newAttachment = {
            title: file.name,
            type: file.type,
            path: URL.createObjectURL(file),
            attachmentId: res.attachmentId,
          };
          if (props.uploadFile) {
            props.uploadFile({
              ...res,
              file: newAttachment,
              rowIndex: rowIndex,
              rowData: rowData,
            });
          }
        }
      },
      (error) => {
        console.log(error);
        showToast(i18next.t("ERROR_UPLOAD"), TOAST_TYPE.FAILURE);
      }
    );
  };
  const getDataGrid = () => (
    <>
      {state.showContextMenu && props.isMenuVisible && (
        <ContextMenu
          tableName={state.tableName || props.tableName}
          isShowEdit={selection.length === 1 && showEditProp() ? true : false}
          displayTableName={
            props.displayTableName ? props.displayTableName : state.tableName
          }
          onDelete={() => getDeleteConfirmation(selection)}
          onApply={(data: any) => onContextMenuOp(data)}
          isCheckPrint={props.isCheckPrint}
          onCheckPrint={() =>
            props.onCheckPrint ? props.onCheckPrint() : false
          }
          onCancel={() => onContextMenuCancel()}
          onEdit={() => isEdit(selection)}
          selection={selection}
          isShowRevert={(selection.length === 1 && state.isShowRevert) || false}
          onRevertClick={(data: any) => {
            props.onRevertClick(data);
          }}
          hideTableName={props.hideTableName}
          addMenuButton={props.addMenuButton ? props.addMenuButton : undefined}
        />
      )}
      <DKDataGrid
        title={props.displayTableName}
        width={props.gridWidth ? props.gridWidth : width}
        columns={
          props.isActionEnabled
            ? props.isActionColumnFirst
              ? [getActionButtonsColumn(), ...state.columns]
              : [...state.columns, getActionButtonsColumn()]
            : [...state.columns]
        }
        rows={[...state.rows]}
        currentPage={state.currentPage}
        totalPageCount={state.totalPageCount}
        onRowUpdate={onRowUpdate}
        onColumnUpdate={onColumnUpdate}
        onColumnShift={onColumnShift}
        onRowAdd={onRowAdd}
        onRowClick={onRowClick}
        onColumnAdd={onColumnAdd}
        onColumnDelete={onColumnDelete}
        onSearch={(data: any) => onSearch(data)}
        onFilter={(data: any) => onFilter(data)}
        allowExpand={props.allowExpand}
        onRowExpand={props.onRowExpand}
        allowRowAdd={props.allowRowAdd}
        allowRowEdit={props.allowRowEdit}
        allowColumnAdd={false}
        allowColumnEdit={props.allowColumnEdit}
        allowColumnDelete={props.allowColumnDelete}
        allowColumnShift={props.allowColumnShift}
        allowSearch={props.allowSearch}
        allowFilter={props.allowFilter}
        allowColumnSort={props.allowColumnSort}
        allowBulkOperation={props.allowBulkOperation}
        onPrevPageTapped={() => onPagination(state.currentPage - 1)}
        onNextPageTapped={() => onPagination(state.currentPage + 1)}
        filterData={state.filter}
        onRowSelect={(data: any) => onRowSelect(data)}
        onAllRowSelect={(data: any) => onAllRowSelect(data.selected)}
        showHeader={
          !(
            (state.showContextMenu && props.isMenuVisible) ||
            props.isHeaderHidden
          )
        }
        addButton={props.addButtonConfig ? props.addButtonConfig : undefined}
        contextMenu={props.contextMenu ? getGridContextMenu() : ""}
        isAllRowSelected={state.isAllRowSelected}
        onRowOpenClick={
          state.onRowOpenBtnHidden
            ? false
            : (rowData: any, rowIndex: any) =>
                props.onRowEdit ? props.onRowEdit(rowData, true) : undefined
        }
        buttons={props.addButtonConfig ? props.addButtonConfig : undefined}
        onSort={props.onSort ? (data: any) => onSort(data) : null}
        dateFormat={props.dateFormat}
      />
      {getAttachmentPicker()}
    </>
  );

  return (
    <div className={`${props.gridWidth ? "" : " parent-height "} column `}>
      {getDataGrid()}
      {state.needNoDataView && getNoDataView()}
    </div>
  );
};
export default DataGridHolderPayroll;
