import React from "react";
import { DKLabel } from "deskera-ui-library";
import FormatCurrencyService from "../services/FormatCurrencyService";

function FormatCurrency(props) {
  const amount = FormatCurrencyService.format(props.amount);

  return (
    <DKLabel
      className={props.className}
      style={{
        cursor: "default",
      }}
      text={amount}
      data-tip={amount}
    />
  );
}

export default FormatCurrency;
