import React, { Component } from "react";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import { DKLabel } from "deskera-ui-library";
import SegmentListContainer from "../list/SegmentListContainer";
import ExpenseCategory from "./ExpenseCategory";
import ExpenseGroup from "./ExpenseGroup";


export default class ExpenseAdminSetting extends Component {
    tabs = [
        { id: "", name: "Expenses Groups" },
        { id: "categories", name: "Categories" }
    ]
    constructor(props) {
        super(props);
        this.state = {
            selectedTabId: ""
        }
    }
    componentDidMount() {
        RouteManager.setPresenter(this);
    }
    onTabChange(data) {
        this.setState({
            ...this.state,
            selectedTabId: data.id
        })
    }
    //*******UI Logic ******/
    toggleButton() {
        return (
            <SegmentListContainer
                data={this.tabs}
                selectedId={this.state.selectedTabId}
                onChange={(data) => this.onTabChange(data)}
                className="d-flex justify-content-end"
                maxWidth={React.createRef().current?.innerWidth}
                isAddEnabled={false}
            />
        )
    }
    groups() {
        return (
            <ExpenseGroup />
        )
    }
    categories() {
        return (
            <ExpenseCategory />
        )
    }
    render() {
        return (

            <div className="parent-width parent-height">
                <DKLabel className="fw-m fs-l" text="Expense Claims Settings" />
                {this.toggleButton()}
                {this.state.selectedTabId == "" && this.groups()}
                {this.state.selectedTabId == "categories" && this.categories()}
            </div>
        )
    }
}