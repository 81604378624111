import { configureStore } from "@reduxjs/toolkit";
import { ExpenseCategoriesSlice } from "./slices/ExpenseCategorySlice";
import { ExpenseClaimListSlice } from "./slices/ExpenseClaimListSlice";
import { ExpenseGroupSlice } from "./slices/ExpenseGroupSlice";
import { ExpenseIntegrationSlice } from "./slices/ExpenseIntegartionSlice";

export const Store = configureStore({
  reducer: {
    expenses: ExpenseClaimListSlice.reducer,
    expenseIntegration: ExpenseIntegrationSlice.reducer,
    expenseGroup: ExpenseGroupSlice.reducer,
    expenseCategories: ExpenseCategoriesSlice.reducer,
  },
});
