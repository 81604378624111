export const ENV = {
  DEV: "DEV",
  QA: "QA",
  QA2: "QA2",
  STAGING: "STAGING",
  PROD: "PROD",
};
export const PRODUCTS = {
  AIO: "aio",
  ERP: "erp",
  CRM: "crm",
  PEOPLE: "people",
  WEBSITE_BUILDER: "website builder",
  SHOP: "shop",
  REPORT_BUILDER: "report_builder",
  DOCUMENT_BUILDER: "document builder",
  DOCUMENT_SCANNER: "document scanner",
  ORGANISATION: "organisation",
  CONSOLE: "console",
  WORKFLOW_ENGINE: "workflow_engine",
  ATTENDANCE: "attendance",
  EXPENSE: "expense",
  CHAT: "chat",
  EM: "em", //Expense
  DS: "ds", //Shop
  EL: "el", //Leave Management
};

export const PLANS = {
  STARTUP: "startup",
  ESSENTIAL: "essential",
  PROFESSIONAL: "professional",
};

export const INTERVALS = {
  YEARLY: "yearly",
  MONTHLY: "monthly",
};

export const ALIGNMENT = {
  LEFT: "left",
  RIGHT: "right",
  CENTER: "center",
  JUSTIFY: "justify",
};

export const FORM_TYPE = {
  BOOK_A_DEMO: "BOOK_A_DEMO",
  SIGN_UP_LEAD_GENERATION: "SIGN_UP_LEAD_GENERATION",
  CONTACT_US: "CONTACT_US",
};

export const API_STATUS = {
  SUCCESS_200: 200,
  SUCCESS_201: 201,
};

export const USER_ROLE = {
  OWNER: "Owner",
  ADMIN: "Admin",
  MANAGER: "Manager",
  MEMBER: "User",
};
export const USER_TYPE = {
  GUEST: "Guest",
  PAID: "Paid",
};
export const USER_STATUS = {
  ACTIVE: "Active",
  DISABLED: "Disabled",
  PENDING: "Pending",
};

export const SUBSCRIPTION_STATUS = {
  IN_TRIAL: "In trial",
  TRIAL_PENDING: "Trial available",
  EXPIRED: "Expired",
  ACTIVE: "Active",
  TRIAL_ENDED: "Trial over",
  CANCELLED: "Cancelled",
};

export const ACTION_KEY = {
  DELETE_COMAPANY: "delete_company",
  RESET_COMPANY: "reset_company",
};

export const DELETE_OPTIONS = [
  {
    label: "It's too costly",
    value: "It's too costly",
  },
  {
    label: "I found another product that fulfills my needs.",
    value: "I found another product that fulfills my needs.",
  },
  {
    label: "I don't use it enough.",
    value: "I don't use it enough.",
  },
  {
    label: "Some features I need are missing",
    value: "Some features I need are missing",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const PASSWORD_OBJ = {
  OLD_PASSWORD: "old password",
  NEW_PASSWORD: "new password",
  CONFIRM_NEW_PASSWORD: "confirm new password",
};

export const PASSWORD_SETTING = {
  SHOW: "show",
  HIDE: "hide",
};

export const PASSWORD_ERROR = {
  ERROR_API: "error: api",
  ERROR_BLANK: "error: blank",
  ERROR_NOT_MATCHED: "error: mismatched",
  ERROR_NOT_MATCHED_FIELD: "error: mismatched other field",
  ERROR_LENGTH: "error: length invalid",
};

export const PLAN_UPDATE_TYPE = {
  TRIAL_START: "TrialStart",
  PLAN_START: "PlanStart",
  UPGRADE: "Upgrade",
  DOWNGRADE: "Downgrade",
};
export const INVOICE_STATUS = {
  PAID: "paid",
  FAILED: "failed",
};

export const CODE_TYPE = {
  PREPAID_CODE: "prepaid",
  PROMO_CODE: "promo / discount",
  PLAN_CODE: "plan",
  ADDON_CODE: "addon",
  COUPON_CODE: "coupon",
};

export const WEBSITE_TYPE = {
  HELP_DESK: "helpdesk",
  BUILDER: "builder",
};

export const CHART_TYPE = {
  BAR_VERTICAL: "Bar Vertical Graph",
  MULTI_BAR_VERTICAL: "Multi Vertical Bar Graph",
  BAR_HORIZONTAL: "Bar Horizontal Graph",
  MULTI_BAR_HORIZONTAL: "Multi Bar Horizontal Graph",
  LINE: "Line Chart",
  PIE: "Pie Chart",
  DONUT: "DONUT",
};

export const CHART_API_SOURCES = {
  INVOICES: "INVOICES",
  AGE_RECEIVABLE: "AGE_RECEIVABLE",
  BANK_ACCOUNTS: "BANK_ACCOUNTS",
  WON_AND_LOST_DEALS: "WON_AND_LOST_DEALS",
  PIPELINES: "PIPELINES",
  CONTACTS: "CONTACTS",
  EXPENSES_CLAIMED: "EXPENSES_CLAIMED",
  PAYROLL: "PAYROLL",
  LEAVES_PER_MONTH: "LEAVES_PER_MONTH",
};
export const VISIBILITY_TYPE = {
  VISIBLITY_1: "Private",
  VISIBLITY_2: "Team",
  VISIBLITY_3: "Everyone",
};
export const LABEL_TYPE = {
  LABEL_1: "Cold Lead",
  LABEL_2: "Hot Lead",
  LABEL_3: "Warm Lead",
};
export const LABEL_COLOR = {
  COLOR_1: "data-grid-badge-color-7",
  COLOR_2: "data-grid-badge-color-10",
  COLOR_3: "data-grid-badge-color-5",
};
export const DEAL_STATUS_CODE = {
  WON: 1,
  LOST: 2,
};
export const DEAL_VIEW = {
  KANBAN: "Kanban",
  GRID: "Grid",
};
export const CURRENCY = {
  ZWD: "$",
  ZMW: "ZK",
  ZMK: "ZK",
  ZAR: "R",
  YER: "﷼",
  XPF: "₣",
  XOF: "CFA",
  XCD: "$",
  XAF: "FCFA",
  WST: "WS$",
  VUV: "VT",
  VND: "₫",
  VEF: "Bs",
  UZS: "лв",
  UYU: "$U",
  USD: "$",
  UGX: "USh",
  UAH: "₴",
  TZS: "TSh",
  TWD: "NT$",
  TTD: "TT$",
  TRY: "₺",
  TOP: "T$",
  TND: "د.ت",
  TMT: "T",
  TMM: "T",
  TJS: "ЅM",
  THB: "฿",
  SZL: "E",
  SYP: "£",
  STD: "Db",
  SSP: "£",
  SRD: "$",
  SOS: "S",
  SLL: "Le",
  SKK: "Sk",
  SHP: "£",
  SGD: "$",
  SEK: "kr",
  SDG: "SD",
  SCR: "₨",
  SBD: "Si$",
  SAR: "﷼",
  RWF: "FRw",
  RUB: "₽",
  RSD: "Дин.",
  RON: "lei",
  QAR: "﷼",
  PYG: "₲",
  PLN: "zł",
  PKR: "₨",
  PHP: "₱",
  PGK: "K",
  PEN: "S/",
  PAB: "B/.",
  OMR: "﷼",
  NZD: "$",
  NPR: "₨",
  NOK: "kr",
  NIO: "C$",
  NGN: "₦",
  NAD: "$",
  MZN: "MT",
  MYR: "RM",
  MXN: "$",
  MWK: "MK",
  MVR: "Rf",
  MUR: "₨",
  MTL: "₤",
  MRO: "UM",
  MOP: "MOP$",
  MNT: "₮",
  MMK: "K",
  MKD: "ден",
  MGA: "Ar",
  MDL: "L",
  MAD: "DH",
  LYD: "ل.د",
  LVL: "Ls",
  LTL: "Lt",
  LSL: "L",
  LRD: "$",
  LKR: "₨",
  LBP: "£",
  LAK: "₭",
  KZT: "лв",
  KYD: "$",
  KWD: "د.ك",
  KRW: "₩",
  KPW: "₩",
  KMF: "CF",
  KHR: "៛",
  KGS: "лв",
  KES: "KSh,",
  JPY: "¥",
  JOD: "د.ا",
  JMD: "J$",
  ISK: "kr",
  IRR: "﷼",
  IQD: "ع.د",
  INR: "₹",
  ILS: "₪",
  IDR: "Rp",
  HUF: "Ft",
  HTG: "G",
  HRK: "kn",
  HNL: "L",
  HKD: "$",
  GYD: "GY$",
  GTQ: "Q",
  GNF: "FG",
  GMD: "D",
  GIP: "£",
  GHS: "₵",
  GHC: "GH₵",
  GEL: "ლ",
  GBP: "£",
  FKP: "£",
  FJD: "FJ$",
  EUR: "€",
  ETB: "ብር",
  ERN: "ናቕፋ",
  EGP: "£",
  EEK: "EEK",
  DZD: "دج",
  DOP: "RD$",
  DKK: "kr.",
  DJF: "Fdj",
  CZK: "Kč",
  CYP: "£",
  CVE: "$",
  CUC: "$",
  CRC: "₡",
  COP: "$",
  CNY: "¥",
  CLP: "$",
  CHF: "CHF",
  CDF: "FC",
  CAD: "$",
  BZD: "BZ$",
  BYR: "Br",
  BWP: "P",
  BTN: "Nu.",
  BSD: "$",
  BRL: "R$",
  BOB: "b$",
  BND: "$",
  BMD: "$",
  BIF: "FBu",
  BHD: ".د.ب",
  BGN: "лв",
  BDT: "৳",
  BBD: "$",
  BAM: "KM",
  AZN: "₼",
  AWG: "Afl",
  AUD: "$",
  ARS: "$",
  AOA: "Kz",
  ANG: "Naf",
  AMD: "դր",
  ALL: "Lek",
  AFN: "؋",
  AED: "د.إ",
};
export const AUTHORIZATION_STATUS = {
  INITIATE: "INITIATE",
  PENDING: "PENDING",
  DENIED: "DENIED",
  AUTHORIZED: "AUTHORIZED",
  TRIAL_AVAILABLE: "TRIAL_AVAILABLE",
};
