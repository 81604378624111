import ApiConstants from "../utils/ApiConstants";
import axios from "axios";
import { showAlert, TOAST_TYPE, showToast } from "deskera-ui-library";
import AppManager from "../managers/AppManager";
import Utility from "../utils/Utility";

const ERRORS_TO_IGNORE = [
  "Billing Info not added yet",
  "Employee tax declaration details not found for userid",
  "Employee tax proof of investment not found for userid",
  "Employee tax approval details not found for userid",
];

const URLS_TO_IGNORE = {
  "v1/taxes": [
    "Failed due to exception while processing the request. Error Message: 403 Forbidden",
    "User is not authorized to perform the operation.",
  ],
};

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: ApiConstants.URL.BASE,
});
const requestInterceptSuccess = (config) => {
  return config;
};
const requestInterceptError = (error) => {
  return Promise.reject(error);
};
const responseInterceptSuccess = (response) => {
  AppManager.didSessionExpired = false;
  if (response.data && response.data.code && response.data.errorMessage) {
    let message = response.data.errorMessage;
    if (
      response.data.debugMessage &&
      response.data.debugMessage.length > response.data.errorMessage.length
    ) {
      message = response.data.debugMessage;
    }
    showToast(message || "Error occurred!", TOAST_TYPE.DEFAULT);
    return Promise.reject(response.data);
  }
  return response.data;
};

const responseInterceptError = (error) => {
  if (Utility.isEmpty(error.response)) {
    AppManager.gotoLoginPage();
    return Promise.reject(error);
  } else {
    if (error.response.data) {
      if (
        (error.response.data.errorCode || error.response.data.code) &&
        error.response.data.errorMessage
      ) {
        const errorIndex = ERRORS_TO_IGNORE.findIndex(
          (ignore) => error.response.data.errorMessage.indexOf(ignore) !== -1
        );
        const ignoreErrorIndex = Object.entries(URLS_TO_IGNORE).findIndex(
          ([key, value]) => {
            return (
              error?.config?.url?.indexOf(key) !== -1 &&
              value.findIndex(
                (v) => error.response.data.errorMessage.indexOf(v) !== -1
              ) !== -1
            );
          }
        );

        if (errorIndex === -1 && ignoreErrorIndex === -1) {
          if (
            error.response.data.errorMessage.toLowerCase() ===
              "user not authorized" ||
            error.response.data.errorMessage.toLowerCase() ===
              "user not authorised"
          ) {
            showToast(error.response.data.errorMessage, TOAST_TYPE.FAILURE);
          } else {
            if (
              error.config &&
              error.config.url &&
              error.response.data.errorMessage === "record not found"
            ) {
              // no alert required if helpcenter get
            } else {
              let message = error.response.data.errorMessage;
              if (
                error.response.data.debugMessage &&
                error.response.data.debugMessage.length >
                  error.response.data.errorMessage.length
              ) {
                message = error.response.data.debugMessage;
              }
              showToast(message || "Error occurred!", TOAST_TYPE.DEFAULT);
            }
          }
        }
      }

      return Promise.reject(error.response.data);
    } else {
      showToast(
        "There was some problem with server. Please try again later.",
        TOAST_TYPE.DEFAULT
      );
      return Promise.reject(error.response);
    }
  }
};

axiosInstance.interceptors.request.use(
  (response) => requestInterceptSuccess(response),
  (error) => requestInterceptError(error)
);

axiosInstance.interceptors.response.use(
  (response) => responseInterceptSuccess(response),
  (error) => responseInterceptError(error)
);

export default axiosInstance;
