import React, { Component } from "react";
import ReimburseIconSvg from "../../assets/icons/reimburse-icon";
import { getDashboardWidgetLoading } from "../dashboard/Dashboard";
import { DKLabel } from "deskera-ui-library";
import { TABLE_DISPLAY_NAME } from "../../managers/TableManager";
import ExpenseClaimListService from "../../services/ExpenseClaimListService";
import { EXPENSE_CLAIM_STATUS } from "../../utils/ExpenseCommon";
import { TENANT, TenantService } from "../../services/TenantMangerService";
import { CONSTANTS } from "../../utils/Constants";

class ApprovedClaimsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
    };
  }

  componentDidMount() {
    this.fetchApprovedClaims();
  }

  fetchApprovedClaims() {
    const view = TenantService.get(TENANT.IS_ADMIN)
      ? CONSTANTS.ADMIN
      : CONSTANTS.USER;
    this.setState({ loading: true });
    ExpenseClaimListService.fetchClaimsCount(
      `query=status=${EXPENSE_CLAIM_STATUS.APPROVED}&view=${view}`
    )
      .then(
        (response) => {
          this.setState({ data: response, loading: false });
        },
        (error) => {
          this.setState({ data: null, loading: false });
        }
      )
      .catch((error) => {
        this.setState({ data: null, loading: false });
      });
  }

  getEmptyState = () => {
    return (
      <div
        className={`column dashboard-no-data parent-width parent-height align-items-center justify-content-center`}
      >
        <DKLabel text="No data found" className="fw-m fs-m text-gray" />
        <DKLabel text="Add new claims" className="text-gray mt-xs" />
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="bg-white p-h-xl pt-r pb-l border-m border-radius-m border-box dashboard-card column align-items-center parent-width">
          <div
            className="column align-items-start parent-width parent-height custom-scroll"
            style={{
              overflow: "hidden",
            }}
          >
            <div className="row align-items-center">
              <DKLabel
                text={TABLE_DISPLAY_NAME[this.props.module]}
                className="fw-m fs-m"
              />
              <DKLabel
                className={" ml-s fw-m border-radius-s "}
                text={
                  this.state.data?.totalElements > 0
                    ? `<b>(${this.state.data.totalElements})</b>`
                    : ""
                }
              />
            </div>
            {this.state.loading && getDashboardWidgetLoading()}
            {!this.state.loading && this.state.data?.totalElements > 0 && (
              <div
                className={`column dashboard-no-data parent-width parent-height align-items-center justify-content-center`}
              >
                <ReimburseIconSvg />
              </div>
            )}
            {!this.state.loading &&
              this.state.data?.totalElements == 0 &&
              this.getEmptyState()}
          </div>
        </div>
      </>
    );
  }
}

export default ApprovedClaimsCard;
