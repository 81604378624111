import {
  COLUMN_CODES,
  FORMAT,
} from "../utils/Constants";
import { COLUMN_CODE, TABLES } from "../managers/TableManager";
import Utility from "../utils/Utility";
import { isIndiaCompliance, isUSCompliance } from "../managers/common/CommonUtil";
import { EXPENSE_CONSTANTS } from "./ExpenseClaimListService";
import TenantManager from "../managers/TenantManager";
import ic_check_mark from "../assets/icons/ic_check_mark.png";
import ic_close from "../assets/icons/ic_close.png";
import ic_copy from "../assets/icons/ic_copy.png";
import ic_printer from "../assets/icons/ic_printer.png";
import ic_save from "../assets/icons/ic_save.png";
import ic_paypal from "../assets/icons/ic_paypal.png";
import ic_delete from "../assets/icons/ic_delete.png";

export function ExpenseSyncRevertColumnConfig(expenses, isSync) {
  let rowData = [];
  if (expenses && expenses.length > 0) {
    expenses.forEach((expense) => {
      const row = {
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.EXPENSE_ID]: expense.code,
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.CATEGORY]: expense.category,
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.DATE]: Utility.formatDate(
          expense.spendDate,
          FORMAT.YYYYMMDD
        ),
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.APPLICANT]: expense.userName,
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.EXPENSE_GROUP]:
          expense.groupName,
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.MERCHANT]: expense.merchant,
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.TOTAL_AMOUNT]:
          expense.totalAmount,
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.DATE_OF_JE]: new Date(),
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.DATE_OF_REVERSAL_JE]: !isSync
          ? new Date()
          : "",
        ["data"]: expense,
        ["id"]: expense.id,
      };
      rowData.push(row);
    });
  }
  return rowData;
}

export function parseExpenseColumnConfig(columnData, isSync) {
  columnData.forEach((column) => {
    switch (column.columnCode) {
      case COLUMN_CODES.DATE_OF_JE:
        column.editable = isSync;
        break;
      case COLUMN_CODES.DATE_OF_REVERSAL_JE:
        column.editable = !isSync;
        column.uiVisible = !isSync;
        column.hidden = isSync;
        break;
    }
  });
  return columnData;
}

export function parseVisibleColumnConfig(columnData) {
  columnData = columnData.filter((column) => !column.hidden);
  columnData.forEach((column) => {
    column["key"] = column.columnCode;
  });
  return columnData;
}

export function parseColumnConfig(columnData, tableName, viewConfig) {
  let isContractor = false;
  if (viewConfig) {
    isContractor = viewConfig.isContractor;
  }
  columnData.forEach((column) => {
    column["key"] = column.columnCode;
    if (isIndiaCompliance()) {
      if (column.columnCode === "compensation") {
        column.uiVisible = false;
        column.hidden = true;
        column.name = "Basic/Salary";
        column.type = "number";
      } else if (column.columnCode === "ctc") {
        column.uiVisible = true;
        column.hidden = false;
        column.name = "CTC";
        column.type = "number";
      }
    } else {
      if (column.columnCode === "compensation") {
        column.uiVisible = true;
        column.hidden = false;
        column.name = "Basic/Salary";
        column.type = "number";
      } else if (column.columnCode === "ctc") {
        column.uiVisible = false;
        column.hidden = true;
        column.name = "CTC";
        column.type = "number";
      }
    }

    switch (column.columnCode) {
      case "pf":
      case "esi":
      case "taxexempt":
        column.uiVisible = isIndiaCompliance() ? true : false;
        column.hidden = isIndiaCompliance() ? false : true;
        break;
      case "deductionType":
        column.uiVisible = isUSCompliance() ? true : false;
        column.hidden = isUSCompliance() ? false : true;
        break;
      case "overtime":
        column.name = "Overtime (OT x Hrs x Rate = Amount)";
        column.width = 300;
        break;
      case "uniqueId":
        if (isIndiaCompliance()) {
          column.uiVisible = false;
          column.hidden = true;
        }
        break;
      case "filePath":
      case "updatedFilePath":
        if (column.name == "Original File" || column.name == "Download") {
          column.hidden = true;
          column.uiVisible = false;
        }
        break;
      case "totalMinutes":
      case "entryOffsetMinutes":
      case "exitOffsetMinutes":
      case "clockIn":
        column.editable = false;
        break;
      case "designation":
        column.name = "Job Title";
        break;
      case "lop":
        column.name = "Unpaid Days";
        break;
      case "payDate":
        column.width = 180;
        break;
      case "status":
         if (tableName === TABLES.PEOPLE_EXPENSE_CLAIMS) {
          column.allowFilter = false;
        }
        break;
      case "days":
      case "period":
      case "grossPay":
      case "taxes":
      case "netPay":
        column.hidden = isContractor;
        break;
      case "code":
      case "spendDate":
      case "userName":
      case "merchant":
      case "groupName":
      case "totalAmount":
        if (tableName === TABLES.PEOPLE_EXPENSE_CLAIMS) {
          column.allowFilter = false;
        }
        break;
      case "category":
        if (tableName === TABLES.PEOPLE_EXPENSE_CLAIMS) {
          column.type = "select";
          if (Array.isArray(viewConfig.categories)) {
            column.options = [
              { id: "", name: "All" },
              ...viewConfig.categories,
            ];
          }
        }
        break;
      case COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.GROUP:
        column.allowFilter = true;
        column.required = true;
        column.editable = true;
        break;
      case COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.CATEGORY_ID:
        column.allowAddOption = false;
        break;
      case COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.TAX_CODE:
        column.allowAddOption = false;
        break;
    }
  });
  return columnData;
}

export function ExpenseGroupUserColumnConfig(expenses) {
  let rowData = [];
  if (expenses && expenses.length > 0) {
    expenses.forEach((expense) => {
      const row = {
        [COLUMN_CODE.PEOPLE_EXPENSE_GROUP_USER
          .NAME]: `${expense.firstName} ${expense.lastName}`,
        [COLUMN_CODE.PEOPLE_EXPENSE_GROUP_USER.ROLE]:
          expense.role == "admin"
            ? "Admin"
            : expense.role == "employee"
            ? "Member"
            : "Manager",
        ["data"]: expense,
      };
      rowData.push(row);
    });
  }
  return rowData;
}

export function ExpenseCategoryColumnConfig(expenses) {
  let rowData = [];
  if (expenses && expenses.length > 0) {
    expenses.forEach((expense) => {
      const row = {
        [COLUMN_CODE.PEOPLE_EXPENSE_CATEGORY.NAME]: expense.name,
        [COLUMN_CODE.PEOPLE_EXPENSE_CATEGORY.GROUP_NAME]: expense.groups
          ? expense.groups.map((res) => res.name).join(", ")
          : "",
        ["data"]: expense,
      };
      rowData.push(row);
    });
  }
  return rowData;
}

export function ExpenseGroupColumnConfig(expenses) {
  let rowData = [];
  if (expenses && expenses.length > 0) {
    expenses.forEach((expense) => {
      const row = {
        [COLUMN_CODE.PEOPLE_EXPENSE_GROUP.NAME]: expense.name,
        [COLUMN_CODE.PEOPLE_EXPENSE_GROUP.MEMBER_COUNT]: expense.memberCount
          ? `${expense.memberCount} ${
              expense.memberCount && expense.memberCount > 1
                ? "Members"
                : "Member"
            } `
          : "<span class='text-red'>0 Members</span>",
        ["data"]: expense,
      };
      rowData.push(row);
    });
  }
  return rowData;
}

export function ExpenseClaimsColumnConfig(
  expenses,
  onContextMenuAction,
  isAdmin,
  userId
) {
  let rowData = [];
  if (expenses && expenses.length > 0) {
    expenses.forEach((expense) => {
      const row = {
        ["id"]: expense.id,
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.EXPENSE_ID]: expense.code,
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.CATEGORY]: expense.category,
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.DATE]: Utility.formatDate(
          expense.spendDate,
          FORMAT.YYYYMMDD
        ),
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.APPLICANT]: expense.userName,
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.EXPENSE_GROUP]: expense.groupName,
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.MERCHANT]: expense.merchant,
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.TOTAL_AMOUNT]: expense.totalAmount,
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.STATUS]: expense.status,
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.GROUP]: expense.expenseGroup,
        ["rowContextMenu"]: getContextMenuForExpenseGrid(
          expense,
          onContextMenuAction,
          isAdmin,
          userId
        ),
        ["nonEditableColumns"]:
          expense.status == EXPENSE_CONSTANTS.DRAFT &&
          (userId === expense.userId || isAdmin)
            ? []
            : [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.GROUP],
        ["data"]: expense,
      };
      rowData.push(row);
    });
  }
  return rowData;
}

export function getFileName(url) {
  return url.substring(url.indexOf("_") + 1, url.length);
}

export function insertRowIntoMultiClaim(rowData) {
  const row = {
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.EXPENSE_ID]: null,
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.DATE]: new Date(),
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.MERCHANT]: "",
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.TAX_AMOUNT]: 0,
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.TAX_CODE]: null,
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.TOTAL_AMOUNT]: 0,
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.OTHER_DETAILS]: "",
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.CATEGORY_ID]: null,
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.ATTACHMENTS]: null,
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.DESCRIPTION]: "",
    invalidFields: [],
  };
  rowData.push(row);
  return rowData;
}

export function getContextMenuForExpenseGrid(
  expense,
  onContextMenuAction,
  isAdmin,
  userId
) {
  const contextMenu = [];
  const { tenant } = TenantManager.getPeopleTenantData();
  if (onContextMenuAction) {
    switch (expense.status) {
      case "PENDING":
        if (isAdmin) {
          contextMenu.push({
            title: "Approve",
            icon: ic_check_mark,
            onClick: (data) => {
              onContextMenuAction(EXPENSE_CONSTANTS.APPROVE, data);
            },
          });
          contextMenu.push({
            title: "Reject",
            icon: ic_close,
            onClick: (data) => {
              onContextMenuAction(EXPENSE_CONSTANTS.REJECT, data);
            },
          });
        }
        contextMenu.push({
          title: "Duplicate",
          icon: ic_copy,
          onClick: (data) => {
            onContextMenuAction(EXPENSE_CONSTANTS.DUPLICATE, data);
          },
        });
        break;
      case "DRAFT":
        if (userId === expense.userId) {
          contextMenu.push({
            title: "Submit",
            icon: ic_save,
            onClick: (data) => {
              onContextMenuAction(EXPENSE_CONSTANTS.SUBMITTED, data);
            },
          });
        }
        contextMenu.push({
          title: "Duplicate",
          icon: ic_copy,
          onClick: (data) => {
            onContextMenuAction(EXPENSE_CONSTANTS.DUPLICATE, data);
          },
        });
        if (userId === expense.userId) {
          contextMenu.push({
            title: "Delete",
            icon: ic_delete,
            onClick: (data) => {
              onContextMenuAction(EXPENSE_CONSTANTS.DELETE, data);
            },
          });
        }
        break;
      case "APPROVED":
        if (isUSCompliance) {
          if (tenant.isCheckhqOffCyclePaymentEnabled) {
            contextMenu.push({
              title: "Pay with CheckHq",
              icon: ic_check_mark,
              onClick: (data) => {
                onContextMenuAction(EXPENSE_CONSTANTS.EXPENSE_PAY, data);
              },
            });
          }
          if (tenant.isPaypalPaymentEnabled) {
            contextMenu.push({
              title: "Pay with Paypal",
              icon: ic_paypal,
              onClick: (data) => {
                onContextMenuAction(EXPENSE_CONSTANTS.EXPENSE_PAYPAL_PAY, data);
              },
            });
          }
        }
        break;
    }
  }

  contextMenu.push({
    title: "Print",
    icon: ic_printer,
    onClick: (data) => {
      onContextMenuAction(EXPENSE_CONSTANTS.PRINT, data);
    },
  });

  return contextMenu;
}
