import React, { Component } from "react";
import ReviewIconSvg from "../../assets/icons/reimburse-icon";
import { getDashboardWidgetLoading } from "../dashboard/Dashboard";
import { DKLabel } from "deskera-ui-library";
import ExpenseClaimListService from "../../services/ExpenseClaimListService";
import { EXPENSE_CLAIM_STATUS } from "../../utils/ExpenseCommon";
import { CONSTANTS } from "../../utils/Constants";
import { TENANT, TenantService } from "../../services/TenantMangerService";

class PendingClaimsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
    };
  }

  componentDidMount() {
    this.fetchPendingClaims();
  }

  fetchPendingClaims() {
    const view = TenantService.get(TENANT.IS_ADMIN)
      ? CONSTANTS.ADMIN
      : CONSTANTS.USER;
    this.setState({ loading: true });
    ExpenseClaimListService.fetchClaimsCount(
      `query=status=${EXPENSE_CLAIM_STATUS.PENDING}&view=${view}`
    )
      .then(
        (response) => {
          this.setState({ data: response, loading: false });
          if (this.props.onCountUpdated) {
            this.props.onCountUpdated(response?.totalElements);
          }
        },
        (error) => {
          this.setState({ data: null, loading: false });
        }
      )
      .catch((error) => {
        this.setState({ data: null, loading: false });
      });
  }

  getEmptyState = () => {
    return (
      <div
        className={`column parent-width parent-height align-items-center justify-content-center`}
      >
        <DKLabel text="No data found" className="fw-m fs-m text-gray" />
        <DKLabel text="Add new claims" className="text-gray mt-xs" />
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.loading && getDashboardWidgetLoading()}
        {!this.state.loading && this.state.data?.totalElements > 0 && (
          <div
            className={`column parent-width parent-height align-items-center justify-content-center`}
          >
            <ReviewIconSvg />
          </div>
        )}
        {!this.state.loading &&
          this.state.data?.totalElements == 0 &&
          this.getEmptyState()}
      </>
    );
  }
}

export default PendingClaimsCard;
