import React, { Component } from "react";
import { showAlert, DKLabel, DKButton } from "deskera-ui-library";
import ApiManager from "../../managers/ApiManager";
import UserManager from "../../managers/UserManager";

// - onCancel

class FeedbackPopup extends Component {
  constructor(props) {
    super(props);
    this.userInfo = `Hi,<br/><br/><br/><br/><br/><br/><br/>Type your feedback or query above.<br/><br/>--------------------------------------------------<br/><br/>Thank you,<br/>${UserManager.getUserName()}<br/>Email: ${UserManager.getUserEmail()}<br/>Contact Number: ${
      UserManager.getUserPhoneNumber() ?? "NA"
    }<br/>Country: ${UserManager.getUserCountry()}<br/><br/>`;
  }
  render() {
    return this.getForm();
  }

  getForm() {
    return (
      <div className="transparent-background">
        <div className="popup-window">
          <DKLabel
            text="Submit your feedback or query "
            className="fw-m fs-l"
          />

          <div
            contenteditable="true"
            id="emailMessage"
            dangerouslySetInnerHTML={{ __html: this.userInfo }}
            style={{
              padding: 10,
              outlineWidth: 0,
            }}
            className="app-font fw-n bg-gray1 parent-width fs-r text-align-left border-box mt-l border-radius-s"
          />
          <div className="row mt-l justify-content-between">
            <div>
              <input
                id="inputfile"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    this.uploadImageToAWS(e.target.files[0]);
                  }
                }}
                className=" p-xs bg-gray1 border-radius-s word-break-all"
                style={{ width: "auto" }}
              />
            </div>
            <div className="row-reverse" style={{ width: "auto" }}>
              <DKButton
                title="Submit"
                className="bg-app text-white ml-r"
                onClick={this.submitFeedback}
              />
              <DKButton
                title="Cancel"
                className="bg-gray1 border-m"
                onClick={this.cancelTapped}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  cancelTapped = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  submitFeedback = () => {
    ApiManager.sendEmail(
      "CRM+ Feedback",
      "care@deskera.com",
      document.getElementById("emailMessage").innerHTML,
      () => {
        showAlert(
          "Feedback submitted!",
          "We have received your feedback or query, will get back to you soon.<br/><br/>Thank you!"
        );
        this.cancelTapped();
      },
      false
    );
  };

  uploadImageToAWS(imageData) {
    ApiManager.getUploadFileURL(
      imageData,
      (url) => {
        let div = document.getElementById("emailMessage");

        const image = document.createElement("img");
        image.src = url;
        image.className = "max-width-100 border-box";

        div.appendChild(image);
      },
      (err) => {}
    );
  }
}

export default FeedbackPopup;
