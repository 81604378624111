import { TableManger, COLUMN_CODE, TABLES } from "../managers/TableManager";
import AppManager from "../managers/AppManager";

class TableDataParser {
  static parseTableData(data) {
    let tableData = {};
    tableData["id"] = data._id;
    tableData["name"] = data.name;
    tableData["columns"] = this.parseColumnData(data.columnsMetaData);
    return tableData;
  }
  static parseColumnData(data) {
    let columnsData = [];
    data.forEach((column) => {
      let data = { ...column };
      data["key"] = column.id;
      data["type"] = column.type.toLowerCase();
      data["options"] = column.options ? column.options : [];
      data["required"] = column.required ? column.required : false;
      data["width"] = column.width ? column.width : 200;
      data["name"] = column.name;
      columnsData.push(data);
    });
    return columnsData;
  }

  static parseRowData(data, table = null) {
    return data.map((row) => {
      this.checkAndReplaceDirectLink(row, table); // TODO - Need to remove this @Piyush this is a workaround
      return { ...row.cells, id: row._id };
    });
  }
  static getColumnToAdd(column) {
    return {
      name: column.name,
      type: column.type,
      options: column.options || [],
      required: column.required || false,
      width: column.width || 200,
      editable: column.editable || true,
      hidden: column.hidden || false,
      systemField: column.systemField || false,
      uiVisible: column.uiVisible || true,
    };
  }
  static getRowToAdd(rowData) {
    let cells = {};
    for (let key in rowData) {
      cells[key] = rowData[key].value;
    }
    return { cells };
  }

  static checkAndReplaceDirectLink(row, table) {
    if (table !== null) {
      const column_id = TableManger.getColumnId(
        table,
        COLUMN_CODE.JOB_FORMS.DIRECT_LINK
      );
      row.cells[column_id] = AppManager.generateDirectLink(
        row.cells[column_id]
      );
    }
  }
  // static checkAndReplaceSegment() {
  //   const contactSegmentCol = TableManger.getColumn(
  //     TABLES.CANDIDATE,
  //     COLUMN_CODE.CANDIDATE.JOBOPENING
  //   );
  //   let segmentData = {
  //     type: contactSegmentCol.type,
  //     options: contactSegmentCol.options,
  //     name: contactSegmentCol.name,
  //   };
  //   TableManger.updateColumn(
  //     COLUMN_CODE.JOB_FORMS.SEGMENT_ID,
  //     TABLES.JOB_FORMS,
  //     {
  //       ...TableManger.getColumn(
  //         TABLES.JOB_FORMS,
  //         COLUMN_CODE.JOB_FORMS.SEGMENT_ID
  //       ),
  //       ...segmentData,
  //     }
  //   );
  //   TableManger.updateColumn(COLUMN_CODE.CAMPAIGN.SEGMENT_ID, TABLES.CAMPAIGN, {
  //     ...TableManger.getColumn(
  //       TABLES.CAMPAIGN,
  //       COLUMN_CODE.CAMPAIGN.SEGMENT_ID
  //     ),
  //     ...segmentData,
  //   });
  // }
}

export default TableDataParser;
