import { LANGUAGES, LANGUAGES_CODE } from "../../../utils/Constants";
import united_states from "../../../assets/flags/united-states.svg";

export const LanguageMenuList = [
  {
    title: LANGUAGES.ENGLISH_US,
    code: LANGUAGES_CODE.ENGLISH_US,
    icon: united_states,
  },
  // {
  //     title: LANGUAGES.ENGLISH_UK,
  //     code: LANGUAGES_CODE.ENGLISH_UK,
  //     icon: uk
  // }
];
