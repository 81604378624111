import { CHART_INTERVALS_CLAIMS_TREND } from "../utils/Constants";
import Utility from "../utils/Utility";

export default class GraphManager {
  static graphData = {
    claimByTrend: {
      data: [],
      interval: CHART_INTERVALS_CLAIMS_TREND[1],
    },
  };
  static set(key, data) {
    this.graphData[key] = data;
  }
  static get(key) {
    return this.graphData[key];
  }
  static hasData(key) {
    return !Utility.isEmpty(this.graphData[key].data);
  }
  static clear(key) {
    this.graphData[key].data = null;
    if (this.graphData[key].interval)
      this.graphData[key].interval = CHART_INTERVALS_CLAIMS_TREND[0];
    if (this.graphData[key].pipeline) this.graphData[key].pipeline = null;
  }
}
