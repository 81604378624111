import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
    DKLabel,
    DKButton,
    DKCalendar,
    showToast,
    TOAST_TYPE,
    DKIcon,
    DKIcons,
    DKInput,
    removeLoader,
    showLoader,
    showAlert,
    INPUT_VIEW_DIRECTION,
    DKCheckMark
} from "deskera-ui-library";
import AppManager from "../../managers/AppManager";
import { ADMIN } from "../../managers/common/RouteAuth";
import { useDispatch, useSelector } from "react-redux";
import RouteManager from "../../managers/RouteManager";
import SegmentListContainer from "../list/SegmentListContainer";
import calender from '../../assets/icons/white/date_range.svg';
import Utility, { deepClone, removeDelayedLoader, showDelayedLoader } from "../../utils/Utility";
import moment from 'moment';
import { FORMAT } from "../../utils/Constants";
import DataGridHolderPayroll from "../common/DataGridHolderPayroll";
import { COLUMN_CODE, TableManger, TABLES } from "../../managers/TableManager";
import { fetchCategories, fetchExpenseClaims, fetchTaxes, selectCategories, selectExpenses, selectTaxes } from "../../redux/slices/ExpenseClaimListSlice";
import { ExpenseClaimsColumnConfig, parseColumnConfig, parseVisibleColumnConfig } from "../../services/Config";
import ExpenseClaimListService, { EXPENSE_CONSTANTS } from "../../services/ExpenseClaimListService";
import Popup from "../common/Popup";
import ClaimForm from "./NewClaim";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import _ from 'lodash';
import { TENANT, TenantService } from "../../services/TenantMangerService";
import UserManager from "../../managers/UserManager";
import PopupList from "../list/PopupList";
import MultipleExpensePopup from "./MultipleExpensePopup";
import ExpenseService from "../../services/ExpenseService";
import i18next, { t } from "i18next";
import { EXPENSE_CLAIM_STATUS } from "./ClaimsConstants";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function ExpenseClaims(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    const [tabs, setTabs] = useState([]);
    const [selectedTabId, setSelectedTabId] = useState("");
    const [showClaimPopup, setClaimPopup] = useState({
        isVisible: false,
        claimId: null,
        isDuplicate: false
    });
    const [showMultiClaimPopup,setMultiClaimPopup] = useState({
        isVisible: false
    })
    const [showEditGroup, setShowEditGroup] = useState({
        isVisible:false,
        title: i18next.t("GROUP_NAME_TITLE"),
        canValidate: false,
        value:'',
        data:null
    })
    const list = [
        {
            id: "single",
            icon:"",
            title: i18next.t("SINGLE_CLAIM")
        },
        {
            id: "multiple",
            icon:"",
            title:i18next.t("MULTI_CLAIM")
        }
    ]
    const initialClaimPeriod = {
        toggle: false,
        startDate: moment(moment().format()).startOf('month'),
        endDate: moment(moment().format()).endOf('month'),
        defaultDate: false
    };
    const [claimPeriod, setClaimPeriod] = useState(initialClaimPeriod);

    const initialExpenseClaims = {
        columnData: [],
        rowData: [],
        originalData: [],
        filter: []
    };
    const [expenseClaims, setExpenseClaims] = useState(initialExpenseClaims);
    const [filters, setFilters] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [createNewPopup, setCreateNewPopup] = useState(false)
    const expenses = useSelector(selectExpenses);
    const categories = useSelector(selectCategories);
    const taxes = useSelector(selectTaxes);
    const [reload, setReload] = useState(false);
    const [isTransactionDone, setTransationDone] = useState(false);
    const [showTransactionHistory, setTransactionHistory] = useState([]);
    const [applicantName, setApplicantName] = useState("");
    const [paypalConfigurationEnabled, setpaypalConfiguration] = useState(false);
    const [paymentSelection, setPaymentSelection] = useState(true);
    const [paypalValue, setPaypalValue] = useState("");
    const [selectedRecord, setSelectedRecord] = useState({});

    const onlyMultipleClaim = true;
    useEffect(() => {
        RouteManager.setPresenter({ props: { ...props, history: history } });
        AppManager.scrollToTop();

        if (props.accessedBy === ADMIN) {
            setTabs([
                { id: "", name: "All Claims" },
                { id: "DRAFT", name: "Drafts" },
                { id: "PENDING", name: "To Review" },
                { id: "APPROVED", name: "Approved" },
            ]);
        } else {
            setTabs([
                { id: "", name: "All Claims" },
                { id: "DRAFT", name: "Drafts" },
                { id: "PENDING", name: "Submitted" },
                { id: "APPROVED", name: "Approved" },
            ]);
        }

        if (ExpenseClaimListService.activeTab) {
            setSelectedTabId(ExpenseClaimListService.activeTab);
            ExpenseClaimListService.activeTab = "";
        }

        dispatch(fetchCategories());
        dispatch(fetchTaxes());

        if(!showClaimPopup.isVisible||!showMultiClaimPopup.isVisible||!showEditGroup.isVisible) {
            fetchExpenses(getAPIConfig(claimPeriod.startDate, claimPeriod.endDate, currentPage, selectedTabId));
        }

    }, [showClaimPopup,showMultiClaimPopup, showEditGroup, reload]);

    useEffect(() => {
        if (!Utility.isEmptyObject(expenses)) {
            const { content, number, totalPages } = expenses;
            const rowData = ExpenseClaimsColumnConfig(content, onContextMenuAction, TenantService.get(TENANT.IS_ADMIN), UserManager.getUserDetails().id);
            const columnConfig = parseVisibleColumnConfig(parseColumnConfig(TableManger.getTableVisibleColumns(TABLES.PEOPLE_EXPENSE_CLAIMS), TABLES.PEOPLE_EXPENSE_CLAIMS, { categories: categories }));

            const claimsData = {
                columnData: deepClone(columnConfig),
                rowData: rowData,
                filter: [],
                originalData: rowData
            };
            setExpenseClaims(claimsData);
            setCurrentPage(number + 1);
            setTotalPageCount(totalPages);
        }
    }, [expenses]);

    const fetchExpenses = async (apiConfig) => {
        const timeout = showDelayedLoader();
        if (apiConfig) {
            ExpenseClaimListService.apiConfig = apiConfig;
        }
        await dispatch(fetchExpenseClaims());
        removeDelayedLoader(timeout);
    }

    const onTabChange = (tab) => {
        setSelectedTabId(tab.id);
        fetchExpenses(getAPIConfig(claimPeriod.startDate, claimPeriod.endDate, currentPage, tab.id));
    };

    const getAPIConfig = (startDate, endDate, currentPage, status, category,groupName) => {
        return {
            params: {
                view: props.accessedBy === ADMIN ? "ADMIN" : "EMPLOYEE",
                page: currentPage - 1,
                limit: 20
            },
            queryParams: getQueryParams(startDate, endDate, status, category,groupName)
        }
    }

    const getQueryParams = (startDate, endDate, status, category,groupName) => {
        const queryParams = {
            "spendDate>=": moment(startDate).format(FORMAT.YYYYMMDD),
            "spendDate<=": moment(endDate).format(FORMAT.YYYYMMDD)
        }

        if (status) {
            queryParams["status="] = status;
        }

        if (category) {
            queryParams["categoryId="] = category;
        }
        if(groupName) {
            let condition = "=";
            switch(groupName.condition) {
                case "c":
                    condition = "~";
                    break;
                case "eq":
                    condition = "=";
                    break;

            }

            queryParams[`expenseGroup${condition}`]=groupName.value
        }

        return queryParams;
    }

    const getClaimPeriodText = (startDate, endDate) => {
        if (startDate && endDate) {
            return `${Utility.formatDate(moment(startDate).format(FORMAT.DDMMYYYY), FORMAT.DDMMYYYY)} - ${Utility.formatDate(moment(endDate).format(FORMAT.DDMMYYYY), FORMAT.DDMMYYYY)}`;
        }

        return `${Utility.formatDate(moment().startOf('month').format(FORMAT.DDMMYYYY), FORMAT.DDMMYYYY)} - ${Utility.formatDate(moment().endOf('month').format(FORMAT.DDMMYYYY), FORMAT.DDMMYYYY)}`;
    }

    const onNewClaim = () => {
        setClaimPopup({ isVisible: true, claimId: null })
    }

    const onContextMenuAction = (action, data) => {
        switch (action) {
            case EXPENSE_CONSTANTS.APPROVE:
            case EXPENSE_CONSTANTS.REJECT:
                approveOrRejectClaims(action, data?.rowData?.id, false);
                break;
            case EXPENSE_CONSTANTS.DELETE:
                deleteExpense(data?.rowData?.id);
                break;
            case EXPENSE_CONSTANTS.PRINT:
                printExpenses([data?.rowData]);
                break;
            case EXPENSE_CONSTANTS.SUBMITTED:
                submitExpense(data?.rowData?.data);
                break;
            case EXPENSE_CONSTANTS.DUPLICATE:
                duplicateExpense(data?.rowData?.data);
                break;
            case EXPENSE_CONSTANTS.EDIT_GROUP_NAME:
                setShowEditGroup({
                    ...showEditGroup,
                    data:data?.rowData?.data,
                    value:data?.rowData?.data?.expenseGroup,
                    isVisible:true
                })
                break;
            case EXPENSE_CONSTANTS.EXPENSE_PAY:
                const rowData = data?.rowData?.data
                payRespectiveExpense(rowData);
                break;
            case EXPENSE_CONSTANTS.EXPENSE_PAYPAL_PAY:
                const selectedData = data?.rowData?.data
                payRespectiveExpenseWithPaypal(selectedData);
                break;
        }
    }

    const payRespectiveExpenseWithPaypal = (data) => {
        setpaypalConfiguration(true);
        setSelectedRecord(data);
    }

    const approvePayment = (name) => {
        showLoader("Approval is in progress... Please wait")
        ExpenseClaimListService.ApprovePayment().then((response) => {
            removeLoader();
            showToast("Payment approved successfully", TOAST_TYPE.SUCCESS);
            getPaystubList(name)
        }, (error) => {
            removeLoader();
            showToast("Something went wrong", TOAST_TYPE.FAILURE)
        })
    }

    const getPaystubList = (name) => {
        showLoader("Fetching Details... Please wait");
        ExpenseClaimListService.getExpensePaystubList().then(
          (response) => {
              setApplicantName(name)
            setTransationDone(true);
            setTransactionHistory(response?.paystubObject);
            removeLoader();
          },
          (error) => {
              showToast("Something went wrong", TOAST_TYPE.FAILURE);
          }
        );
    }

    const payRespectiveExpense = (data) => {
        showLoader("Paying... Please wait");
        const expenseAmount = data.totalAmount;
        const expenseId = data.code;
        const employeeName = data.userName;

        const params = {
          expenseId: expenseId,
          expenseAmount: expenseAmount,
        };

        ExpenseClaimListService.PayCLaims(params, params).then(
          (response) => {
            removeLoader();
            const deleteConfirm = [
              {
                title: "Reject",
                className: "bg-gray1 border-m",
              },
              {
                title: "Approve",
                className: "bg-app text-white ml-r",
                onClick: () => approvePayment(employeeName),
              },
            ];
            showAlert(
              "Payment Approval",
              "Please approve current payout?",
              deleteConfirm
            );
          },
          (error) => {
            removeLoader();
            showToast("Something went wrong", TOAST_TYPE.FAILURE);
          }
        );
    }

    // const showTransactionPopup = (name) => {
    //     const options = {

    //         method: 'GET',

    //         headers: {

    //             Accept: 'application/json',

    //             Authorization: 'Bearer 2d896cb5beccda91f20b8fe54357cea80edc2e4d'

    //         }

    // };


    //     fetch('https://sandbox.checkhq.com/employees/emp_QYiCu4fiMgGRkKsznXkW/paystubs', options)
    //       .then((response) => response.json())
    //       .then((response) => {
    //           setTransationDone(true);
    //           setTransactionHistory(response);
    //           setApplicantName(name);
    //           removeLoader();
    //       })

    //       .catch((err) => {
    //           console.error(err)
    //           showToast("Something went wrong", TOAST_TYPE.FAILURE);
    //           removeLoader();
    //         });
    // }

    const approveOrRejectClaims = (view, id, isBulk) => {
        const params = {
            view: view
        }

        let payload;
        if (isBulk) {
            payload = selectedRows;
        } else {
            payload = [id];
        }

        ExpenseClaimListService.approveOrRejectClaims(payload, params).then(
            (response) => {
                showToast(view === EXPENSE_CONSTANTS.APPROVE ? i18next.t("EXPENSE_APPROVE") : i18next.t("EXPENSE_REJECT") , TOAST_TYPE.SUCCESS);
                fetchExpenses();
            }, (error) => {
                showToast(view === EXPENSE_CONSTANTS.APPROVE ? i18next.t("CANT_EXPENSE_APPROVE") : i18next.t("CANT_EXPENSE_REJECT"), TOAST_TYPE.FAILURE);
            }
        );
    }
    const multipleAction = (data,action) => {
        let isPending = -1 // for now set default to -1
        if(isPending == -1) {
            let ids = data.map(r=>r.id)
            let params = {
                view: action

            }
            ExpenseClaimListService.approveOrRejectClaims(ids, params).then(
                (response) => {
                    showToast(params.view === EXPENSE_CONSTANTS.APPROVE ?  i18next.t("EXPENSE_APPROVE") : (params.view === EXPENSE_CONSTANTS.PENDING ? i18next.t("EXPENSE_SUBMIT") :i18next.t("EXPENSE_REJECT")) , TOAST_TYPE.SUCCESS);
                    fetchExpenses();
                }, (error) => {
                    showToast(params.view === EXPENSE_CONSTANTS.APPROVE ? i18next.t("CANT_EXPENSE_APPROVE") : (params.view === EXPENSE_CONSTANTS.PENDING ? i18next.t("CANT_EXPENSE_SUBMIT") :i18next.t("CANT_EXPENSE_REJECT")), TOAST_TYPE.FAILURE);
                }
            );
        } else {
            showAlert(
                i18next.t("NOTE"),
                `${i18next.t("PENDING_CLAIM_ONLY")} ${(action === EXPENSE_CONSTANTS.APPROVE) ? `${i18next.t("APPROVED")}` : `${i18next.t("REJECTED")} `} `)
        }

    }
    const deleteExpense = (id) => {
        if (id) {
            ExpenseClaimListService.deleteExpense(id).then(
                (response) => {
                    showToast("Expense has been deleted successfully.", TOAST_TYPE.SUCCESS);
                    fetchExpenses();
                }, (error) => {
                    showToast("Couldn't delete expense, Please try again.", TOAST_TYPE.FAILURE);
                }
            );
        }
    }

    const printExpenses = (expenses) => {
        if (Array.isArray(expenses)) {
            expenses = expenses.map((expense) => expense.data);
            pdfMake.createPdf(getTemplate(expenses)).download("Expense Claims:" + Utility.formatDate(moment().format(FORMAT.DDMMYYYY), FORMAT.DDMMYYYY));
        } else {
            showToast("Invalid selection", TOAST_TYPE.FAILURE);
        }
    }

    const getTemplate = (expenses) => {
        const tableHeader = [
            {
                text: 'Expense ID',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Status',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Date',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Applicant',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Merchant',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Total Amount',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Tax Code',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Tax Amount',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Category',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Expense Group',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Description',
                style: 'tableHeader',
                alignment: 'center'
            }
        ];
        const tableBody = [tableHeader];
        expenses.forEach(expense => {
            const tax = taxes.find(tax => tax.code === expense.taxCode);
            const expenseRow = [
                expense.code,
                expense.status,
                Utility.formatDate(expense.expenseDate, FORMAT.YYYYMMDD),
                _.get(expense, "userName", ""),
                expense.merchant,
                expense.currency + ' ' + expense.totalAmount,
                _.get(tax, "name", "-"),
                _.get(expense, "taxAmount", "-"),
                _.get(expense, "category", "-"),
                _.get(expense, "groupName", "-"),
                _.get(expense, "otherDetails", "-")
            ]
            tableBody.push(expenseRow);
        });
        return {
            pageSize: "A4",
            pageOrientation: 'landscape',
            pageMargins: [40, 40],
            content: [
                {
                    columns: [
                        {
                            stack: [{ text: TenantService.get(TENANT.ORGANIZATION_NAME), style: "companyName" }],
                            width: "35%"
                        }
                    ]
                },
                {
                    stack: [
                        {
                            text: 'Expense Claim',
                            style: "headingStyle1"
                        }
                    ],
                    margin: [1, 40]
                },
                {
                    widths: [10, "*", "*", 10],
                    table: {
                        body: tableBody
                    },
                }
            ],
            styles: {
                companyName: {
                    fontSize: 20,
                    bold: true,
                    alignment: "left"
                },
                headingStyle1: {
                    fontSize: 20,
                    bold: true,
                    alignment: "center"
                },
                headingStyle2: {
                    fontSize: 16,
                    alignment: "center"
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }
            },
            defaultStyle: {}
        }
    }

    const submitExpense = (data) => {
        if (data) {
            const payload = {
                ...data,
                status: EXPENSE_CONSTANTS.PENDING
            }
            ExpenseClaimListService.submitExpense(data.id, payload).then(
                (response) => {
                    showToast("Expense has been submitted successfully.", TOAST_TYPE.SUCCESS);
                    fetchExpenses();
                }, (error) => {
                    showToast("Couldn't submit expense, Please try again.", TOAST_TYPE.FAILURE);
                }
            );
        }
    }

    const duplicateExpense = (data) => {
        setClaimPopup({ isVisible: true, claimId: data?.id, isDuplicate: true });
    }

    const onRowEdit = (data) => {
        setClaimPopup({ isVisible: true, claimId: data?.rowData?.data?.id })
    }

    const onRowUpdate = (data) => {
        if(data.columnKey == COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.GROUP) {
            onGroupNameEdit(data.rowData)
        }
    }

    const onGroupNameEdit = (editGroupData) => {

        if (editGroupData.data) {
            showLoader();
            let obj = {
                ...editGroupData.data,
                deletedAttachmentIds: [],
                attachmentIds: [],
                attachments: [],
                expenseGroup: editGroupData.expenseGroup
            }
            ExpenseService.saveExpenses(obj, editGroupData.data.id).then(res => {
                removeLoader();

                showToast(i18next.t("GROUP_NAME"), TOAST_TYPE.SUCCESS);


            }, error => {
                removeLoader();
                if (showEditGroup.data['status'] === EXPENSE_CLAIM_STATUS.DRAFT) {
                    showToast(i18next.t("ERROR_EXPENSE_DRAFT"), TOAST_TYPE.FAILURE);
                }
            })
        }

    }

    const onFilter = (dataList, queries) => {
        if (queries?.length > 0) {
            queries.forEach((query) => {
                const column = TableManger.getColumnById(TABLES.PEOPLE_EXPENSE_CLAIMS, query.key);
                if (column && column.columnCode === "category") {
                    const config = getAPIConfig(claimPeriod.startDate, claimPeriod.endDate, currentPage, selectedTabId, query?.value[0]);
                    fetchExpenses(config);
                }
                if (column && column.columnCode === COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.GROUP) {
                    const config = getAPIConfig(claimPeriod.startDate, claimPeriod.endDate, currentPage, selectedTabId,null, query);
                    fetchExpenses(config);
                }
            });
        } else {
            const config = getAPIConfig(claimPeriod.startDate, claimPeriod.endDate, currentPage, selectedTabId);
            fetchExpenses(config);
        }
    }
    const onGroupNameChange = () => {
        if(!Utility.isEmpty(showEditGroup.value)){
            if (showEditGroup.data) {
                showLoader();
                let obj = {
                    ...showEditGroup.data,
                    deletedAttachmentIds:[],
                    attachmentIds: [],
                    attachments: [],
                    expenseGroup: showEditGroup.value
                }
                ExpenseService.saveExpenses(obj, showEditGroup.data.id).then(res => {
                    removeLoader();

                        showToast(i18next.t("GROUP_NAME"), TOAST_TYPE.SUCCESS);

                        setShowEditGroup({ ...showEditGroup, canValidate: false, data:null,value:'',isVisible:false })
                }, error => {
                    removeLoader();
                    if (showEditGroup.data['status'] === EXPENSE_CLAIM_STATUS.DRAFT) {
                        showToast(i18next.t("ERROR_EXPENSE_DRAFT"), TOAST_TYPE.FAILURE);
                    }
                })
            }
        } else {
            setShowEditGroup({ ...showEditGroup, canValidate: true })

        }

    }
    const gridAddRowButton = () => {
        return (
            <>
                {!onlyMultipleClaim && <div className="d-flex">
                    <DKLabel text={i18next.t("CREATE_NEW")} />
                    <DKIcon className="ic-s ml-r" src={DKIcons.white.ic_arrow_down2} />
                </div>}
                {onlyMultipleClaim && <div className="d-flex">
                    <DKLabel text={`+ ${i18next.t("NEW_CLAIM")} `} />
                </div>}
            </>
        )
    }
    const onListClick=(selectedOption)=>{
        switch(selectedOption.id) {
            case "single":
                onNewClaim()
                break;
            case "multiple":
                setMultiClaimPopup({isVisible:true})
                break;
        }
    }
    const onContextCancel = () => {
        setReload(!reload)
    }

    const changePaymentSelection = () => {
        setPaymentSelection(!paymentSelection)
    }

    const proceedWithPayment = () => {
        if (Utility.isEmpty(paypalValue)) {
            return;
        }
        showLoader("Payment in progress... Please wait")
        const expenseAmount = selectedRecord.totalAmount;
        const items = {};
        if (paymentSelection) {
            items["recipientType"] = "EMAIL";
            items["receiverId"] = paypalValue;
        } else {
            items["recipientType"] = "PAYPAL_ID";
            items["receiverId"] = paypalValue;
        }
        const payload = {
          amount: expenseAmount.toString(),
          items: [items],
        };
        ExpenseClaimListService.paypalPayment(payload).then((response) => {
            showToast("Expense paid successfully", TOAST_TYPE.SUCCESS);
            setpaypalConfiguration(false);
            removeLoader();
        }, error => {
            showToast("Something went wrong", TOAST_TYPE.FAILURE)
            setpaypalConfiguration(false);
            removeLoader();
        })
    }

    return (
      <>
        {showClaimPopup.isVisible && (
          <ClaimForm
            {...props}
            claimId={showClaimPopup.claimId}
            isDuplicate={showClaimPopup.isDuplicate}
            onClose={() => {
              setClaimPopup({ isVisible: false, claimId: null });
            }}
          />
        )}
        {showMultiClaimPopup.isVisible && (
          <MultipleExpensePopup
            {...props}
            onClose={() => {
              setMultiClaimPopup({ isVisible: false });
            }}
            maxWidth={1020}
          />
        )}
        {showEditGroup.isVisible && (
          <Popup>
            <>
              <div className="row justify-content-between">
                <DKLabel
                  className="fw-m fs-l"
                  text={i18next.t("EDIT_GROUP_NAME")}
                />
                <div className="d-flex">
                  <DKButton
                    title={i18next.t("CANCEL")}
                    className="border-m bg-gray1"
                    onClick={() =>
                      setShowEditGroup({
                        ...showEditGroup,
                        canValidate: false,
                        value: "",
                        data: null,
                        isVisible: false,
                      })
                    }
                  />
                  <DKButton
                    title={i18next.t("SAVE")}
                    className="bg-app text-white ml-m"
                    onClick={() => onGroupNameChange()}
                  />
                </div>
              </div>

              <DKInput
                className="mt-l"
                title={showEditGroup.title}
                placeholder={showEditGroup.title}
                value={showEditGroup.value}
                direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
                onChange={(data) => {
                  setShowEditGroup({
                    ...showEditGroup,
                    value: data.trim(),
                  });
                }}
                canValidate={showEditGroup.canValidate}
                required={true}
              />
            </>
          </Popup>
        )}
        <div className="parent-width parent-height">
          <div>
            <SegmentListContainer
              data={tabs}
              selectedId={selectedTabId}
              onChange={onTabChange}
              className="d-flex justify-content-end"
              maxWidth={React.createRef().current?.innerWidth}
              isAddEnabled={false}
            />
          </div>
          <DKLabel text={"Expense Claims"} className="fw-m fs-l mb-s" />
          <div className="row" style={{ width: "193px" }}>
            <div className="column" style={{ zIndex: 99 }}>
              <DKButton
                className="bg-app text-white"
                title={getClaimPeriodText(
                  claimPeriod.startDate,
                  claimPeriod.endDate
                )}
                icon={calender}
                onClick={(value) => {
                  setClaimPeriod({
                    toggle: claimPeriod.toggle ? false : true,
                    startDate: claimPeriod.startDate,
                    endDate: claimPeriod.endDate,
                    defaultDate: true,
                  });
                }}
              />
              {claimPeriod.toggle && (
                <DKCalendar
                  className="position-absolute border-m shadow-m bg-white z-index-1 mt-s"
                  isDateRange={true}
                  startDate={
                    claimPeriod.startDate
                      ? new Date(claimPeriod.startDate)
                      : new Date()
                  }
                  endDate={
                    claimPeriod.endDate
                      ? new Date(claimPeriod.endDate)
                      : new Date()
                  }
                  onSelectDate={(startDate, endDate) => {
                    if (startDate && endDate) {
                      setClaimPeriod({
                        toggle: false,
                        startDate: startDate,
                        endDate: endDate,
                        defaultDate: false,
                      });
                      fetchExpenses(
                        getAPIConfig(
                          startDate,
                          endDate,
                          currentPage,
                          selectedTabId
                        )
                      );
                    }
                  }}
                  onClose={() => {
                    setClaimPeriod({
                      toggle: false,
                      startDate: claimPeriod.startDate,
                      endDate: claimPeriod.endDate,
                      defaultDate: false,
                    });
                  }}
                />
              )}
            </div>
          </div>
          <div className="column" style={{ marginTop: "-41px", height: "90%" }}>
            <div
              className="flex-1 position-relative"
              style={{ minHeight: "200px" }}
            >
              {createNewPopup && (
                <PopupList
                  list={list}
                  listTitle={i18next.t("CREATE_NEW")}
                  onClick={(obj) => {
                    onListClick(obj);
                  }}
                  onClose={() => {
                    setCreateNewPopup(false);
                  }}
                />
              )}
              <DataGridHolderPayroll
                tableName={TABLES.PEOPLE_EXPENSE_CLAIMS}
                allowColumnEdit={false}
                allowColumnDelete={false}
                allowColumnShift={false}
                allowSearch={false}
                allowFilter={true}
                allowColumnAdd={false}
                allowRowAdd={false}
                allowColumnSort={false}
                refresh={false}
                allowDataExport={false}
                allowRowEdit={true}
                showContextEdit={false}
                isActionEnabled={true}
                contextMenu={true}
                isMenuVisible={true}
                showContextMenu={true}
                gridData={expenseClaims}
                filter={filters}
                currentPage={currentPage}
                totalPageCount={totalPageCount}
                onSelection={(selection) => {
                  // setSelectedRows(selection); //  Note: will use in future
                }}
                onFilter={(dataList, queries) => {
                  onFilter(dataList, queries);
                }}
                onRowEdit={(data) => {
                  onRowEdit(data);
                }}
                onRowUpdate={(data) => {
                  onRowUpdate(data);
                }}
                addButtonConfig={[
                  {
                    title: gridAddRowButton(),
                    className: "bg-app text-white ml-s",
                    onClick: () => {
                      if (onlyMultipleClaim) {
                        setMultiClaimPopup({ isVisible: true });
                      } else {
                        setCreateNewPopup(!createNewPopup);
                      }
                    },
                  },
                ]}
                addMenuButton={[
                  {
                    title: i18next.t("APPROVE"),
                    className: "text-app bg-gray1 border-m mr-r ",
                    hide: !TenantService.get(TENANT.IS_ADMIN),
                    condition: {
                      columnCode: COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.STATUS,
                      value: EXPENSE_CONSTANTS.PENDING,
                    },
                    onClick: (data, value) => {
                      multipleAction(data, EXPENSE_CONSTANTS.APPROVE);
                    },
                  },
                  {
                    title: i18next.t("REJECT"),
                    className: "text-red bg-gray1 border-m mr-r ",
                    hide: !TenantService.get(TENANT.IS_ADMIN),
                    condition: {
                      columnCode: COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.STATUS,
                      value: EXPENSE_CONSTANTS.PENDING,
                    },
                    onClick: (data, value) => {
                      multipleAction(data, EXPENSE_CONSTANTS.REJECT);
                    },
                  },
                  {
                    title: i18next.t("SUBMIT"),
                    className: "text-app bg-gray1 border-m mr-r ",
                    hide: false,
                    condition: {
                      columnCode: COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.STATUS,
                      value: EXPENSE_CONSTANTS.DRAFT,
                    },
                    onClick: (data, value) => {
                      multipleAction(data, EXPENSE_CONSTANTS.PENDING);
                    },
                  },
                ]}
                onPaginationClick={(requestedPageNumber) => {
                  const config = getAPIConfig(
                    claimPeriod.startDate,
                    claimPeriod.endDate,
                    requestedPageNumber,
                    selectedTabId
                  );
                  fetchExpenses(config);
                }}
                onCancelContext={() => {
                  onContextCancel();
                }}
                hideTableName={{
                  hidden: true,
                  width: 197,
                }}
              />
            </div>
          </div>
        </div>
        {showTransactionHistory && isTransactionDone && (
          <div className="transparent-background">
            <div
              className="popup-window"
              style={{ maxWidth: "50%", height: "70%" }}
            >
              <div className="row-reverse mb-s">
                <DKButton
                  title={"Cancel"}
                  className="g-gray1 border-m"
                  onClick={(event) => {
                    setTransationDone(false);
                  }}
                />
              </div>
              <div className="row">
                <table>
                  <tr>
                    <td>SrNo</td>
                    <td>Employee Name</td>
                    <td>Expense Amount</td>
                    <td>Paydate</td>
                    <td>Status</td>
                  </tr>
                  {showTransactionHistory &&
                    showTransactionHistory.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{applicantName}</td>
                          <td>{item.amount}</td>
                          <td>{item.payday}</td>
                          <td>Paid</td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>
          </div>
        )}
        {paypalConfigurationEnabled && (
          <div className="transparent-background">
            <div
              className="popup-window"
              style={{ maxWidth: "40%", height: "40%" }}
            >
              <div className="row-reverse mb-s">
                <DKButton
                  title={"Cancel"}
                  className="g-gray1 border-m"
                  onClick={() => {setpaypalConfiguration(false)}}
                />
                <DKButton
                  title={"Proceed"}
                  className="bg-app text-white border-m mr-s"
                  onClick={() => {
                    proceedWithPayment();
                  }}
                />
              </div>
              <div className="column">
                <div className="row mb-l">
                  <div onClick={() => changePaymentSelection()}>
                    <DKCheckMark
                      isSelected={paymentSelection}
                      color="bg-app"
                      // disabled={isCheckHqPaymentEnabled}
                    />
                  </div>
                  <DKLabel className="ml-s" text={"Pay with EmailId"} />
                </div>
                <div className="row mb-l">
                  <div onClick={() => changePaymentSelection()}>
                    <DKCheckMark
                      isSelected={!paymentSelection}
                      color="bg-app"
                      // disabled={isCheckHqPaymentEnabled}
                    />
                  </div>
                  <DKLabel className="ml-s" text={"Pay with Paypal Id"} />
                </div>
              </div>
              <div className="row" style={{ width: "60%" }}>
                <DKInput
                  className=""
                  title={
                    paymentSelection ? "Enter Paypal email" : "Enter Paypal ID"
                  }
                  placeholder={"Enter value"}
                  value={paypalValue}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  onChange={(data) => {
                    setPaypalValue(data);
                  }}
                  canValidate={true}
                  required={true}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
}

export default ExpenseClaims;
