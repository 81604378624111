import React, { useEffect, useState } from "react";
import Popup from "../components/common/Popup";
import { DKLabel, DKCheckMark } from "deskera-ui-library";
import { TABLE_DISPLAY_NAME } from "../managers/TableManager";

const CardRearrangePopup = (props) => {
  const [sequence, setSequence] = useState([]);

  let fromRowIndex = null;
  let toRowIndex = null;

  useEffect(() => {
    let storedSequence = props.sequence;
    storedSequence.forEach((module) => {
      sequence.push({
        ...module,
        visible: module.visible != null ? module.visible : true,
      });
    });
    setSequence([...sequence]);
  }, []);

  const toggleShowCard = (index) => {
    sequence[index].visible = !sequence[index].visible;
    setSequence([...sequence]);
    if (props.onSequenceChange) {
      props.onSequenceChange(sequence);
    }
  };

  const onDragStart = (e, rowIndex) => {
    e.dataTransfer.dropEffect = "move";
    fromRowIndex = rowIndex;
  };

  const onCardDragOver = (e, rowIndex) => {
    e.preventDefault();
  };
  const onCardDrop = (e, rowIndex) => {
    toRowIndex = rowIndex;
    const newSequence = [...sequence];
    const itemMoved = newSequence.splice(fromRowIndex, 1)[0];
    newSequence.splice(toRowIndex, 0, itemMoved);
    setSequence(newSequence);
    if (props.onSequenceChange) {
      props.onSequenceChange(newSequence);
    }
    e.preventDefault();
  };

  return (
    <Popup
      popupClass={props.popupClass}
      onClose={props.onClose}
      title="Arrange Dashboard"
      allowClose={true}
    >
      <DKLabel
        text="You can re-arrange and show/hide cards"
        className="fw-r fs-r text-gray mb-m"
      />
      <div
        className={
          "column z-index-1 bg-gray1 border-box border-radius-s parent-width align-items-stretch p-r position-relative"
        }
      >
        {sequence != null &&
          sequence.map((module, rowIndex) => {
            return (
              <div
                key={module.name}
                id={`row-${rowIndex}`}
                className="p-s mb-s bg-white border-s border-gray fw-m fs-m cursor-hand"
                onClick={() => toggleShowCard(rowIndex)}
                draggable
                onDragStart={(e) => onDragStart(e, rowIndex)}
                onDragOver={(e) => onCardDragOver(e, rowIndex)}
                onDrop={(e) => onCardDrop(e, rowIndex)}
              >
                <DKCheckMark
                  title={TABLE_DISPLAY_NAME[module.name]}
                  isSelected={module.visible}
                  className="text-black"
                />
              </div>
            );
          })}
      </div>
    </Popup>
  );
};
export default CardRearrangePopup;
