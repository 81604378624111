import { MIX_PANEL_EVENTS } from "../../utils/MixPanelEvents";
import UserManager from "../UserManager";
import mixpanel from "mixpanel-browser";

class MixPanelManager {
  static init = () => mixpanel.init(process.env.REACT_APP_APP_MIXPANEL_KEY);
  static getBasicData = () => {
    return {
      userid: UserManager.getUserID(),
      tenantid: UserManager.getUserTenantID(),
    };
  };
  static openGO = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.GO_OPEN, {
      ...this.getBasicData(),
      ...data,
    });
  static trialStarted = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.PLAN_TRIAL_START, {
      ...this.getBasicData(),
      ...data,
    });
  static planBuy = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.PLAN_BUY, {
      ...this.getBasicData(),
      ...data,
    });
  static planBuyFailed = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.PLAN_BUY_FAILED, {
      ...this.getBasicData(),
      ...data,
    });
  static planChange = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.PLAN_CHANGE, {
      ...this.getBasicData(),
      ...data,
    });
  static planCancel = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.PLAN_CANCEL, {
      ...this.getBasicData(),
      ...data,
    });
  static deleteCompany = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.DELETE_COMPANY, {
      ...this.getBasicData(),
      ...data,
    });
  static resetCompany = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.RESET_ORG, {
      ...this.getBasicData(),
      ...data,
    });
  static mfaEnabled = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.MFA_ENABLED, {
      ...this.getBasicData(),
      ...data,
    });
  static mfaDisabled = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.MFA_DISABLED, {
      ...this.getBasicData(),
      ...data,
    });
  static invoiceView = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.INVOICE_VIEW, {
      ...this.getBasicData(),
      ...data,
    });
  static cardSave = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.CARD_SAVE, {
      ...this.getBasicData(),
      ...data,
    });
  static passwordChange = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.PASSWORD_CHANGE, {
      ...this.getBasicData(),
      ...data,
    });
  static userAdd = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.USER_ADD, {
      ...this.getBasicData(),
      ...data,
    });
  static userUpdate = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.USER_UPDATE, {
      ...this.getBasicData(),
      ...data,
    });
  static userDelete = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.USER_DELETE, {
      ...this.getBasicData(),
      ...data,
    });
  static userRoleChange = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.USER_PERMISSION_CHANGED, {
      ...this.getBasicData(),
      ...data,
    });
  static userAppAccessChanged = (data) =>
    mixpanel.track(MIX_PANEL_EVENTS.USER_APP_ACCESS_CHANGED, {
      ...this.getBasicData(),
      ...data,
    });
}

export default MixPanelManager;
