import { COLUMN_CODE } from "../managers/TableManager";
///////////////////////////////////////
/* Import Export File Type Constants */
///////////////////////////////////////
const supportedFileTypes = {
  CSV: "csv",
  XLSX: "xlsx",
};
const acceptedFileFormats = [".csv", ".xlsx"];
const acceptedResumeFormat = [".pdf", ".doc", ".docx"];
const supportedMimeTypes = {
  csv: "text/csv;charset=utf-8;",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};
const bufferTypes = {
  csv: "string",
  xlsx: "array",
};
////////////////////////////////////////

////////////////////////////////////////
/* Mapping Screen */
///////////////////////////////////////
const excludedColumnCodes = [];
const headerMapDisplaySections = [
  {
    name: "Imported Header",
    key: "importedKey",
    type: "Text",
  },
  {
    name: "Deskera Header",
    key: "dkColKey",
    type: "Select",
  },
  {
    name: "Sample",
    key: "sampleData",
    type: "Text",
  },
];

const headerMapDisplaySections2 = [
  {
    name: "Deskera Header",
    key: "dkColKey",
    type: "Select",
  },
  {
    name: "Imported Header",
    key: "importedKey",
    type: "Text",
  },
  {
    name: "Sample",
    key: "sampleData",
    type: "Text",
  },
];
/////////////////////////////////////////

////////////////////////////////////////
/* Import Options */
////////////////////////////////////////
const importOptions = JSON.stringify([
  {
    title: "Import Options",
    options: [
      {
        value: "ADD",
        displayValue: "Add New Records",
        checked: false,
      },
      {
        value: "UPDATE",
        displayValue: "Update Existing Records",
        checked: false,
      },
      {
        value: "ADD_AND_UPDATE",
        displayValue: "Add New, And Update Existing Records",
        checked: true,
      },
    ],
    selectedOption: "ADD_AND_UPDATE",
    payloadKey: "importOption",
  },
  {
    title: "In case of Duplicate Records",
    options: [
      {
        value: "REJECT",
        displayValue: "Reject the duplicate",
        checked: true,
      },
      //   {
      //     value: "IMPORT",
      //     displayValue: "Import the duplicate rows",
      //     checked: false,
      //   },
    ],
    selectedOption: "REJECT",
    payloadKey: "duplicateRecordOption",
  },
  {
    title: "In case of Invalid Values",
    options: [
      {
        value: "REJECT_ROW",
        displayValue: "Reject the Entire Row",
        checked: false,
      },
      {
        value: "REJECT_VALUE",
        displayValue: "Reject the Invalid Value, But Import the Row",
        checked: false,
      },
      {
        value: "ADD_VALUE",
        displayValue: "Add the Value in the Dropdown",
        checked: true,
      },
    ],
    selectedOption: "ADD_VALUE",
    payloadKey: "invalidValueOption",
  },
]);
///////////////////////////////////////

export {
  /* Import Export Constants */
  supportedFileTypes,
  supportedMimeTypes,
  bufferTypes,
  acceptedFileFormats,
  /* Column Mapper Screen */
  excludedColumnCodes,
  headerMapDisplaySections,
  headerMapDisplaySections2,
  /* Confirmation Screen */
  importOptions,
  acceptedResumeFormat,
};
