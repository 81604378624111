import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ExpenseService from "../../services/ExpenseService";

const initialState = {
  data: {},
  status: "idle",
};
export const fetchExpenseGroup = createAsyncThunk("expenseGroup", async () => {
  const response = await ExpenseService.fetchExpensesGroup();
  return response;
});
export const ExpenseGroupSlice = createSlice({
  name: "expenseGroup",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpenseGroup.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchExpenseGroup.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload;
      });
  },
});

export const selectExpenseGroup = (state) => {
  return state.expenseGroup.data;
};

export default ExpenseGroupSlice.reducer;
