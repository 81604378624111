import ApiConstants from "../utils/ApiConstants";
import { showAlert, removeLoader } from "deskera-ui-library";
import IAM from "../services/Iam";
import { PAGE_ROUTES } from "./RouteManager";
import { isMobileWebView } from "../utils/ViewportSizeUtils";
import { isUndefined } from "../utils/Utility";

export default class AppManager {
  static didUserLoggedIn = false;
  static didSessionExpired = false;
  static needSubscriptionApiCall = true;

  static userLoggedIn() {
    AppManager.didUserLoggedIn = true;
  }
  static isUserLoggedIn() {
    return AppManager.didUserLoggedIn;
  }

  static gotoLoginPage(needAlert = true) {
    removeLoader();
    if (!needAlert) {
      window.open(
        ApiConstants.URL.IAM.REDIRECT + window.location.href,
        "_self"
      );
      return;
    }

    if (!isMobileWebView() && !AppManager.didSessionExpired) {
      showAlert(
        "Session expired!",
        "Your session is expired, please login and try again.",
        [
          {
            title: "Ok",
            className: "bg-app text-white fw-m",
            onClick: () => {
              window.open(
                ApiConstants.URL.IAM.REDIRECT + window.location.href,
                "_self"
              );
            },
          },
        ]
      );
    }

    AppManager.didSessionExpired = true;
  }

  static logout() {
    let buttons = [
      { title: "Cancel", className: "bg-gray2", onClick: () => {} },
      {
        title: "Log out",
        className: "bg-red text-white ml-r",
        onClick: () => {
          IAM.logOut().then((res) => {
            window.open(
              ApiConstants.URL.IAM.REDIRECT + window.location.href,
              "_self"
            );
          });
        },
      },
    ];
    showAlert("Log out", "Are you sure want to log out the session?", buttons);
  }

  static scrollToTop() {
    var myDiv = document.getElementById("containerDiv");
    if (myDiv) {
      myDiv.scrollTop = 0;
    }
  }

  static refreshApp() {
    // RouteManager.navigateToPage(PAGE_ROUTES.HOME);
    if (typeof window.history.pushState != "undefined") {
      var obj = { Page: "new", Url: "/" };
      window.history.pushState(obj, obj.Page, obj.Url);
    }
    window.location.reload();
  }

  static showTrialActivationWarning(onContinue) {
    let buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m fw-m",
        onClick: () => {},
      },
      {
        title: "Start trial",
        className: "bg-app text-white ml-r fw-m",
        onClick: () => {
          onContinue();
        },
      },
    ];
    showAlert(
      "Trial activation",
      "You are about to start your 15 days FREE trial. Once your trial expires, you will be automatically put on a paid plan.<br><br>You can <b>cancel or change your trial at anytime</b>. We'll remind you before your trial ends.",
      buttons
    );
  }

  static generateDirectLink(path) {
    if (path === undefined || path === null || path.trim() === "") {
      return "";
    }

    if (path && path.includes("http")) {
      return path;
    }
    return window.location.origin + PAGE_ROUTES.PUBLIC_PAGE_PATH + path;
  }

  static isPublicURL() {
    return window.location.pathname.includes(PAGE_ROUTES.PUBLIC_PAGE_PATH);
  }

  static getFormSubmissionURL() {
    return window.location.origin + "/FormSubmission.js";
  }

  static showComingSoonWarning = () => {
    showAlert("Coming soon!", "We are working on it.");
  };
  static handleWindowFocusListeners = (callback, register = false) => {
    if (register) {
      /* Register visibility listeners */
      if (isUndefined(document.hidden)) {
        window.addEventListener("focus", callback);
      } else {
        document.addEventListener("visibilitychange", callback, false);
      }
    } else {
      /* Remove visibility listeners */
      if (isUndefined(document.hidden)) {
        window.removeEventListener("focus", callback);
      } else {
        document.removeEventListener("visibilitychange", callback, false);
      }
    }
  };
  static reloadApp() {
    // RouteManager.navigateToPage(PAGE_ROUTES.HOME);
    if (typeof window.history.pushState != "undefined") {
      var obj = { Page: "new", Url: "/" };
      window.history.pushState(obj, obj.Page, obj.Url);
    }
    window.location.reload();
  }
}
