import React, { Component } from "react";
import { DKLabel, DKLine, DKIcon } from "deskera-ui-library";
import ApiConstants from "../../utils/ApiConstants";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import AppManager from "../../managers/AppManager";
import ic_profile_pic from "../../assets/menu/ic_profile_pic.png";
import UserManager from "../../managers/UserManager";
import { ADMIN } from "../../managers/common/RouteAuth";

class ProfileOptionsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.onClose) {
      document.addEventListener("mouseup", this.onMouseUp);
    }
  }
  componentWillUnmount() {
    if (this.props.onClose) {
      document.removeEventListener("mouseup", this.onMouseUp);
    }
  }
  onMouseUp = (e) => {
    if (this.props.onClose) {
      const target = e.target;
      const editingContainer = target.closest(
        "#profile-options-section, #dk-alert, #dk-alert-background"
      );

      if (!editingContainer) {
        this.props.onClose();
      }
    }
  };

  render() {
    let options = [];
    if (this.props.accessedBy === ADMIN) {
      options = [
        {
          title: "View or Edit Profile",
          url: ApiConstants.PRODUCT_URL_GO,
        },
        {
          title: "Organization Info",
          url: ApiConstants.PRODUCT_URL_GO + "settings",
        },
        {
          title: "Permissions",
          url: ApiConstants.PRODUCT_URL_GO + "users",
        },
        {
          title: "Subscription & Billing",
          url: ApiConstants.PRODUCT_URL_GO + "billing",
        },
        {
          title: "Preferences",
          url: "settings",
        },
        {
          title: "Switch to Classic People",
          desc: "Go back to the previous People design at any time.",
          url: ApiConstants.PRODUCT_URL_PEOPLE,
        },
      ];
    } else {
      options = [
        {
          title: "Organization Info",
          url: ApiConstants.PRODUCT_URL_GO + "settings",
        },
        {
          title: "Switch to Classic People",
          desc: "Go back to the previous People design at any time.",
          url: ApiConstants.PRODUCT_URL_PEOPLE,
        },
      ];
    }

    return (
      <div
        id="profile-options-section"
        className="column position-absolute bg-white shadow-l border-radius-m border-m pb-m"
        style={{ top: 50, right: 0, width: 300 }}
      >
        {this.getProfileSection(true)}
        <DKLine className="mb-r" />
        {options.map((option) => (
          <div
            className="p-v-s cursor-hand parent-width listPickerBG"
            onClick={() => {
              if (option.url === "settings") {
                this.props.onClose();
                RouteManager.navigateToPage(PAGE_ROUTES.SETTINGS);
              } else {
                if (
                  option.url === ApiConstants.PRODUCT_URL_PEOPLE &&
                  !ApiConstants.SWITCH_NEW_WINDOW
                ) {
                  window.open(option.url, "_self");
                } else {
                  window.open(option.url, "_blank");
                }
              }
            }}
          >
            <DKLabel text={option.title} className="p-h-r" />
            {option.desc && (
              <DKLabel
                text={option.desc}
                className="p-h-r fs-s mt-xs"
                style={{ opacity: 0.5 }}
              />
            )}
          </div>
        ))}

        <DKLine className="mb-r mt-r" />
        <div
          className="cursor-hand parent-width listPickerBG"
          onClick={() => {
            AppManager.logout();
          }}
        >
          <DKLabel text="Sign Out" className="p-h-r p-v-s text-red" />
        </div>
      </div>
    );
  }

  getProfileSection(detailedView = false) {
    return (
      <div
        className={
          "row border-radius-m p-m " + (!detailedView ? "cursor-hand" : "")
        }
        style={{ backgroundColor: "rgba(255, 255, 255, 0.08)" }}
        onClick={
          detailedView
            ? null
            : () => {
                this.setState({ needProfileOptionsSection: true });
              }
        }
      >
        <DKIcon
          src={ic_profile_pic}
          className="circle ic-m shadow-s"
          style={{ opacity: 0.7 }}
        />
        {/* <DKContactIcon
          title={UserManager.getUserName()}
          className="text-white bg-app flex-shrink-0"
        /> */}
        <div className={" ml-r " + (!detailedView ? "text-white" : "")}>
          <DKLabel
            text={UserManager.getUserName()}
            style={{ opacity: !detailedView ? 0.9 : 1 }}
          />
          <DKLabel
            text={UserManager.getUserEmail()}
            className="mt-xs"
            style={{ opacity: 0.5, wordBreak: "break-all" }}
          />
        </div>
      </div>
    );
  }
}

export default ProfileOptionsSection;
