import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ExpenseClaimListService from "../../services/ExpenseClaimListService";

const initialState = {
  data: {},
  categories: [],
  taxes: {
    content: [],
  },
  status: "idle",
};

export const fetchExpenseClaims = createAsyncThunk("expenses", async () => {
  const response = await ExpenseClaimListService.fetchExpenses();
  return response;
});

export const fetchCategories = createAsyncThunk("categories", async () => {
  const response = await ExpenseClaimListService.fetchCategories();
  return response;
});

export const fetchTaxes = createAsyncThunk("taxes", async () => {
  const response = await ExpenseClaimListService.fetchTaxes();
  return response;
});

export const ExpenseClaimListSlice = createSlice({
  name: "expenses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpenseClaims.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchExpenseClaims.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload;
      })
      .addCase(fetchCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = "idle";
        state.categories = action.payload;
      })
      .addCase(fetchTaxes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTaxes.fulfilled, (state, action) => {
        state.status = "idle";
        state.taxes = action.payload;
      });
  },
});

export const selectExpenses = (state) => {
  return state.expenses.data;
};

export const selectCategories = (state) => {
  return state.expenses.categories;
};

export const selectTaxes = (state) => {
  return state.expenses.taxes.content;
};

export default ExpenseClaimListSlice.reducer;
