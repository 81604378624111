import ApiConstants from "../utils/ApiConstants";
import http from "../http";
import { TENANT, TenantService } from "../services/TenantMangerService";
import Utility from "../utils/Utility";


export const EXPENSE_CONSTANTS = {
    DRAFT: 'DRAFT',
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    APPROVE: "APPROVE",
    REJECT: "REJECT",
    DUPLICATE: "DUPLICATE",
    SUBMITTED: "SUBMITTED",
    DELETE: "DELETE",
    PRINT: "PRINT",
    EDIT_GROUP_NAME: "EDIT_GROUP_NAME",
    EXPENSE_PAY:"PAY_WITH_CHECK",
    EXPENSE_PAYPAL_PAY: "PAY_WITH_PAYPAL"

}

const defaultConfig = {
    params: {
        view: "EMPLOYEE",
        limit: 20,
        page: 0
    },
    queryParams: {}
};

class ExpenseClaimListService {
    static activeTab = null;
    static apiConfig;

    static fetchExpenses() {
        if (Utility.isEmpty(this.apiConfig)) {
            this.apiConfig = defaultConfig;
        }

        const queryParams = [];
        if (this.apiConfig?.queryParams) {
            Object.keys(this.apiConfig?.queryParams).map((param) =>
              queryParams.push(`${param}${this.apiConfig.queryParams[param]}`)
            );
        }

        return http.get(`${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.GET_EXPENSES}?query=${queryParams.join(',')}`, { params: this.apiConfig.params }).catch(
            (error) => {
                console.error('Error fetching expenses: ', error);
                return Promise.reject(error);
            }
        );
    }

    static fetchCategories() {
        const URL = TenantService.get(TENANT.IS_ADMIN) ? `${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.CATEGORY}` : `${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.GET_CATEGORY}`;
        return http.get(URL).catch(
            (error) => {
                console.error('Error fetching categories: ', error);
                return Promise.reject(error);
            }
        );
    }

    static fetchTaxes() {
        return http.get(`${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.TAXES}`).catch(
            (error) => {
                console.error('Error fetching taxes: ', error);
                return Promise.reject(error);
            }
        );
    }

    static approveOrRejectClaims(payload, params) {
        return http.post(`${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.APPROVE_EXPENSE}`, payload, { params: params }).catch(
            (error) => {
                console.error('Error approve/reject expense: ', error);
                return Promise.reject(error);
            }
        );
    }

    static deleteExpense(id) {
        return http.delete(`${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.EXPENSES}/${id}`).catch(
            (error) => {
                console.error('Error deleting expense: ', error);
                return Promise.reject(error);
            }
        );
    }

    static submitExpense(id, payload) {
        return http.put(`${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.EXPENSES}/${id}`, payload).catch(
            (error) => {
                console.error('Error submitting expense: ', error);
                return Promise.reject(error);
            }
        );
    }

    static fetchClaimsSummary(id, payload) {
        return http.get(`${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.STATUS}`, payload).catch(
            (error) => {
                console.error('Error getting expense summary: ', error);
                return Promise.reject(error);
            }
        );
    }

    static fetchClaimsByStatus(payload) {
        return http.get(`${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.EXPENSES}`, payload).catch(
            (error) => {
                console.error('Error getting expense by status {}: ', payload, error);
                return Promise.reject(error);
            }
        );
    }
    static fetchClaimsCount(payload) {
        return http.get(`${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.EXPENSES}?${payload}`).catch(
            (error) => {
                console.error('Error getting expense by status {}: ', payload, error);
                return Promise.reject(error);
            }
        );
    }

    static PayCLaims(payload) {
        const payUrl = "v1/people/users/expense/pay"
        return http.post(`${ApiConstants.URL.BASE}${payUrl}`, payload).catch(
            (error) => {
                console.error('Error approve/reject expense: ', error);
                return Promise.reject(error);
            }
        );
    }

    static ApprovePayment(payload) {
        const payUrl = "v1/people/users/expense/approve"
        return http.post(`${ApiConstants.URL.BASE}${payUrl}`, {}).catch(
            (error) => {
                console.error('Error approve/reject expense: ', error);
                return Promise.reject(error);
            }
        );
    }

    static getExpensePaystubList() {
        const payUrl = "v1/people/users/expense/paystubs"
        return http.get(`${ApiConstants.URL.BASE}${payUrl}`).catch(
            (error) => {
                console.error('Error getting expense by status {}: ', error);
                return Promise.reject(error);
            }
        );
    }

    static paypalPayment(payload) {
        const payUrl = "v1/people/users/paypal/payouts"
        return http.post(`${ApiConstants.URL.BASE}${payUrl}`, payload).catch(
            (error) => {
                console.error('Error approve/reject expense: ', error);
                return Promise.reject(error);
            }
        );
    }
}

export default ExpenseClaimListService;
