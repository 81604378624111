import React, { useState } from "react";
import {
  DKButton,
  DKCheckMark,
  DKLabel,
  removeLoader,
  showLoader,
  TOAST_TYPE,
  showToast,
} from "deskera-ui-library";
import TenantManager from "../../managers/TenantManager";
import CompanyDetailService from "../../services/CompayDetailService";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";

const PaymentOptions = (props) => {
  const [isCheckHqPaymentEnabled, setCheckHqPayment] = useState(false);
  const [isPaypalPaymentEnabled, setPaypalPayment] = useState(false);
  const [tenantDetails, setTenantDetails] = useState(
    TenantManager.getPeopleTenantData()
  );

  useState(() => {
    RouteManager.setPresenter({ props: props });
    const { tenant } = tenantDetails;
    setCheckHqPayment(tenant.isCheckhqOffCyclePaymentEnabled);
    setPaypalPayment(tenant.isPaypalPaymentEnabled);
  }, []);

  const cancelTapped = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const onCheHqDirectCheck = () => {
    setCheckHqPayment(!isCheckHqPaymentEnabled);
  };

  const onPaypalCheck = () => {
    setPaypalPayment(!isPaypalPaymentEnabled);
  };

  const saveTenantSettings = () => {
    const { tenant } = tenantDetails;
    tenant["isCheckhqOffCyclePaymentEnabled"] = isCheckHqPaymentEnabled;
    tenant["isPaypalPaymentEnabled"] = isPaypalPaymentEnabled;
    console.log("tenant");
    showLoader("Please wait...");
    CompanyDetailService.updateCompanyDetail(tenant).then(
      (response) => {
        removeLoader();
        TenantManager.setPeopleTenantData(response);
        RouteManager.navigateToPage(PAGE_ROUTES.DASHBOARD);
        showToast("Details saved successfully", TOAST_TYPE.SUCCESS);
      },
      (error) => {
        removeLoader();
        showToast("Internal Server Error", TOAST_TYPE.FAILURE);
      }
    );
  };

  return (
    <>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{ maxWidth: "30%", height: "30%" }}
        >
          <div className="row-reverse mb-s">
            <div className="row-reverse ">
              <DKButton
                title={"Cancel"}
                className="g-gray1 border-m"
                onClick={() => cancelTapped()}
              />
              <DKButton
                title={"Save"}
                className="bg-app text-white border-m mr-s"
                onClick={() => saveTenantSettings()}
              />
            </div>
            <div className="row fw-m fs-r">{"Select your mode of Payment"}</div>
          </div>
          <div className="column mt-l">
            <div className="row mb-l">
              <div onClick={() => onCheHqDirectCheck()}>
                <DKCheckMark
                  isSelected={isCheckHqPaymentEnabled}
                  color="bg-app"
                  // disabled={isCheckHqPaymentEnabled}
                />
              </div>
              <DKLabel
                className="ml-s"
                text={"Enable Direct Deposit payment through CheckHq"}
              />
            </div>
            <div className="row">
              <div onClick={() => onPaypalCheck()}>
                <DKCheckMark
                  isSelected={isPaypalPaymentEnabled}
                  color="bg-app"
                  // disabled={isPaypalEnabled}
                />
              </div>
              <DKLabel className="ml-s" text={"Enable Paypal Payment"} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentOptions;
