import Utility from "../utils/Utility";
import { isViewportLarge } from "../utils/ViewportSizeUtils";

class SideBarService {
  static sideBarExpandedWidth = 230;
  static sideBarCollapsedWidth = 60;
  static isSideBarExpanded = true;

  static setIsSideBarExpanded() {
    if (isViewportLarge()) {
      SideBarService.isSideBarExpanded = true;
    } else {
      SideBarService.isSideBarExpanded =
        Utility.getPersistentValue("isSideBarExpanded")?.toString() ===
          "true" || false;
    }
  }

  static triggerSidebarChangeEvent() {
    dispatchEvent(
      new CustomEvent("onSideBarChange", {
        detail: { isExpanded: SideBarService.isSideBarExpanded },
      })
    );
  }

  static toggleSideBar = () => {
    SideBarService.isSideBarExpanded = !SideBarService.isSideBarExpanded;
    dispatchEvent(
      new CustomEvent("onSideBarChange", {
        detail: { isExpanded: SideBarService.isSideBarExpanded },
      })
    );
    Utility.setPersistentValue(
      "isSideBarExpanded",
      SideBarService.isSideBarExpanded
    );
  };

  static getContainerWidth = () => {
    if (isViewportLarge()) {
      return window.innerWidth - SideBarService.sideBarExpandedWidth - 48;
    } else {
      return (
        window.innerWidth * 0.92
      ); /* as 4% padding present on mainContainer */
    }
  };

  static getCurrentMenuWidth = () => {
    return SideBarService.isSideBarExpanded
      ? SideBarService.sideBarExpandedWidth
      : SideBarService.sideBarCollapsedWidth;
  };
}

export default SideBarService;
