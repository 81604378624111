import React, { Component, useEffect } from "react";
import ApiConstants from "../../utils/ApiConstants";
import http from "../../http";
import { DKLabel, PieChart } from "deskera-ui-library";
import { COLORS } from "../../utils/Colors";
import { TABLE_DISPLAY_NAME } from "../../managers/TableManager";
import TenantManager from "../../managers/TenantManager";
import { getDashboardWidgetLoading } from "../dashboard/Dashboard";

class ClaimsByCategoryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      chartData: [],
      claimDetails: null,
    };
  }

  componentDidMount() {
    this.fetchExpensesBreakdown();
  }

  fetchExpensesBreakdown() {
    this.setState({});
    http
      .get(`${ApiConstants.URL.EXPENSE.EXPENSES_BREAKDOWN}`, {
        params: { type: this.props.type },
      })
      .then(
        (response) => {
          const numChartColors = COLORS.charts.pie.length;
          const catData = [];
          response.data.map((category, index) => {
            catData.push({
              title: category.name ? category.name : "Default",
              value: category.amount,
              color: COLORS.charts.pie[index % numChartColors],
            });
          });
          const month = response.data[0].month.substr(0, 3);
          const year = response.data[0].year;
          const claimDetails = `Total Claims for <b>${month} ${year} ${TenantManager.getCurrency()}${
            response.totalClaimAmount
          }</b>`;
          this.setState({ chartData: catData, claimDetails });
        },
        (error) => {
          this.setState({ chartData: [], claimDetails: null });
        }
      )
      .catch((error) => {
        this.setState({ chartData: [], claimDetails: null });
      });
  }
  getEmptyState = () => {
    return (
      <div
        className={`column dashboard-no-data parent-width parent-height align-items-center justify-content-center`}
      >
        <DKLabel text="No data found" className="fw-m fs-m text-gray" />
        <DKLabel text="Add new Claims" className="text-gray mt-xs" />
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="bg-white p-h-xl pt-r pb-l border-m border-radius-m border-box dashboard-card column align-items-center parent-width">
          <div className="column align-items-start parent-width parent-height custom-scroll">
            {this.state.showLoading && getDashboardWidgetLoading()}
            <div
              className={`column parent-width parent-height align-items-center`}
            >
              <div className="row align-items-center">
                <DKLabel
                  text={TABLE_DISPLAY_NAME[this.props.module]}
                  className="fw-m fs-m"
                />
              </div>
              {!this.state.showLoading && this.state.chartData.length > 0 && (
                <>
                  {this.props.type === "GROUP" &&
                    this.state.chartData.length > 0 && (
                      <div className="row align-items-center">
                        <DKLabel
                          text={this.state.claimDetails}
                          className="fw-r fs-r p-v-m"
                        />
                      </div>
                    )}
                  {this.state.chartData.length > 0 && (
                    <PieChart
                      dataSet={this.state.chartData}
                      chartConfig={{
                        arcWidth: 0.7,
                        size: 180,
                      }}
                      className={
                        this.props.type === "GROUP" ? "p-v-r" : "pt-xxl"
                      }
                      showLegend={true}
                    />
                  )}
                </>
              )}
              {!this.state.showLoading &&
                this.state.chartData.length === 0 &&
                this.getEmptyState()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClaimsByCategoryCard;
