import React, { Component } from "react";
import { DKLabel, DKIcon } from "deskera-ui-library";
export default class PopupList extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    if (this.props.onClose) {
      document.addEventListener("mouseup", this.onMouseUp);
    }
  }
  componentWillUnmount() {
    if (this.props.onClose) {
      document.removeEventListener("mouseup", this.onMouseUp);
    }
  }
  onMouseUp = (e) => {
    const target = e.target;
    const editingContainer = target.closest("#data-grid-date-editor");

    if (!editingContainer) {
      setTimeout(() => {
        this.props.onClose();
      }, 10);
    }
  };
  render() {
    return (
      <>
        {
          <div
            className="column bg-white border-radius-s border-m shadow-m position-absolute pb-s"
            style={{ right: 0, width: 150, zIndex: 9999, marginTop: 44 }}
          >
            {this.props.list && this.props.list.length != 0 && (
              <>
                {this.props.listTitle && (
                  <DKLabel
                    text={this.props.listTitle}
                    className="fw-m fs-m pt-r pb-s p-h-s"
                  />
                )}
                {this.props.list.map((obj, index) => {
                  return (
                    <div
                      key={obj.title}
                      className="row listPickerBG cursor-hand parent-width border-box"
                      style={{ padding: 12 }}
                      onClick={() => {
                        if (this.props.onClick) {
                          this.props.onClick(obj);
                        }
                      }}
                    >
                      <DKIcon src={obj.icon} className="ic-s" />
                      <DKLabel text={obj.title} className="ml-r" />
                    </div>
                  );
                })}
              </>
            )}
            {((this.props.list && this.props.list.length == 0) ||
              !this.props.list) && (
              <div className="text-align-center p-v-l parent-width">
                No Data
              </div>
            )}
          </div>
        }
      </>
    );
  }
}
