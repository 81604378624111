import AppManager from "../managers/AppManager";
import ApiConstants from "../utils/ApiConstants";
import UserManager from "../managers/UserManager";

import { showLoader, removeLoader, showAlert } from "deskera-ui-library";
import { TENANT, TenantService } from "../services/TenantMangerService";

export default class ApiManager {
  static commonError = "Server error occured. Please try again later.";

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static getApiRequestOptions(method, body = null) {
    var requestOptions = {
      method: method,
      credentials: "include",
      withCredentials: true,
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        accept: "*/*",
        // "x-access-token": ApiConstants.ACCESS_TOKEN,
      },
    };
    if (method === "POST" || method === "PUT") {
      body = JSON.stringify(body);
      requestOptions = { ...requestOptions, body };
    }
    return requestOptions;
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static checkIfUserLoggedIn(onSuccess, onFail) {
    fetch(
      ApiConstants.URL.BASE + ApiConstants.URL.IAM.STATUS,
      ApiManager.getApiRequestOptions("GET")
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.accessToken) {
          ApiManager.tokenReceived(data.accessToken, data.emailVerified);
          TenantService.set(TENANT.REFRESH_TOKEN, data.refreshToken);
          onSuccess();
        } else {
          // onFail();
          AppManager.gotoLoginPage(false);
        }
      })
      .catch((error) => {
        // onFail();
        AppManager.gotoLoginPage(false);
      });
  }
  static tokenReceived(accessToken, emailVerified) {
    let token = ApiManager.parseJwt(accessToken);
    ApiConstants.ACCESS_TOKEN = accessToken;

    let user = {
      imID: token.iamUserId,
      id: token.userId,
      tenantID: token.tenantId,
      name: token.name,
      email: token.email,
      phone: token.phone_number,
      website: token.website,
      tenantName: token.website,
      country: token.taxResidency,
      currency: token.currency,
      isOrgSet: token.isOrgSet,
      emailVerified: emailVerified,
    };
    UserManager.setUserDetails(user);
    AppManager.userLoggedIn();
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static checkIfSessionExpired(error, needToShowAlert = true) {
    if (error.toString() === "TypeError: Failed to fetch") {
      alert("Your session is expired. Please login again.");
      AppManager.gotoLoginPage();
    } else {
      if (needToShowAlert) {
        alert(error);
      }
    }
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static sendEmail(subject, recipients, body, callback, needAlert = true) {
    showLoader();

    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "multipart/form-data");
    // myHeaders.append("Accept", "application/json");

    var formdata = new FormData();
    formdata.append("body", body);
    formdata.append("recipientEmail", recipients);
    formdata.append("recipientName", "Deskera");
    formdata.append("subject", subject);
    formdata.append("senderName", "Deskera");
    // formdata.append("replyTo", "piyush.kandrikar@deskera.com");
    // formdata.append("sendCopy", "piyush.kandrikar@deskera.com");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://api-dev.deskera.xyz/v1/anonymous/document-designer/email",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        removeLoader();
        if (needAlert) {
          showAlert(
            "Email sent!",
            "Email has been sent successfully. Please check your inbox."
          );
        }
        callback();
      })
      .catch((error) => {
        console.log("error", error);
        removeLoader();
        callback();
      });
  }

  static getUploadFileURL(imageData, success, failed) {
    showLoader("Uploading....");
    let url = ApiConstants.URL.BASE + "v1/attachment/file-upload/public";
    let inputData = new FormData();
    inputData.append("file", imageData);
    inputData.append("file-entity", "REPORT_THUMBNAIL");

    let requestOptions = {
      method: "POST",
      credentials: "include",
      withCredentials: true,
      mode: "cors",
      body: inputData,
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData && responseData.publicUrl) {
          success(responseData.publicUrl);
          removeLoader();
        } else {
          failed({ errorMessage: "Please try again." });
        }
      })
      .catch((error) => {
        removeLoader();
        failed({ errorMessage: "Please try again." });
      });
  }

  static getPublicPageJSONData(id, onSuccess, OnError) {
    showLoader("Loading....");
    let url =
      ApiConstants.URL.BASE + ApiConstants.URL.PUBLIC_PAGE.GET_JSON_DATA(id);

    let requestOptions = {
      method: "GET",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData) {
          onSuccess(responseData);
        } else {
          OnError({ errorMessage: "Please try again." });
        }
      });
  }
}
