import ApiConstants from "../utils/ApiConstants";
import http from "../http";
import TenantManager from "../managers/TenantManager";
import ITable, { IColumn } from "../model/Table";
class Table {
  static getAllViews(): Promise<ITable[]> {
    return http.get(ApiConstants.URL.TABLE.GET_ALL).then(
      (res:any) => {
        return Promise.resolve(res.data);
      },
      (err:any) => {
        return Promise.reject(err);
      }
    );
  }
  static getTable(tableId: string) {
    return http
      .get(`${ApiConstants.URL.TABLE.GET(tableId)}`)
      .then(
        (response:any) => {
          return Promise.resolve(response);
        },
        (error:any) => {
          return Promise.reject(error);
        }
      )
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }
  static addColumn(column: IColumn, tableId: string) {
    return http
      .post(`${ApiConstants.URL.TABLE.ADD_COLUMN(tableId)}`, column)
      .then(
        (response:any) => {
          return Promise.resolve(response);
        },
        (error:any) => {
          return Promise.reject(error);
        }
      )
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }
  static getRecords(tableId: string) {
    return http
      .get(`${ApiConstants.URL.TABLE.GET_RECORD(tableId)}`)
      .then(
        (response:any) => {
          return Promise.resolve(response);
        },
        (error:any) => {
          return Promise.reject(error);
        }
      )
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }
  static getRecordsByPage(params: any, data: any, tableId: string) {
    if (!params) params = { pageNo: 1, pageSize: 20 };
    return http
      .post(`${ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(tableId)}`, data, {
        params,
      })
      .then(
        (response:any) => {
          return Promise.resolve(response);
        },
        (error:any) => {
          return Promise.reject(error);
        }
      )
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }
  static addRecord(record: any, tableId: string) {
    return http
      .post(`${ApiConstants.URL.TABLE.ADD_RECORD(tableId)}`, record)
      .then(
        (response:any) => {
          return Promise.resolve(response);
        },
        (error:any) => {
          return Promise.reject(error);
        }
      )
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }
  static updateRecord(data: any, recordId: string, tableId: string) {
    return http
      .put(`${ApiConstants.URL.TABLE.UPDATE_RECORD(recordId, tableId)}`, {
        cells: data,
      })
      .then(
        (response:any) => {
          return Promise.resolve(response);
        },
        (error:any) => {
          return Promise.reject(error);
        }
      )
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }
  static getRecordById(recordId: string, tableId: string) {
    return http
      .get(`${ApiConstants.URL.TABLE.GET_RECORD_BY_ID(recordId, tableId)}`)
      .then(
        (response:any) => {
          return Promise.resolve(response);
        },
        (error:any) => {
          return Promise.reject(error);
        }
      )
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }
  static addBulkRecord(record: any, tableId: string) {
    return http
      .post(`${ApiConstants.URL.TABLE.ADD_BULK_RECORD(tableId)}`, record)
      .then(
        (response:any) => {
          return Promise.resolve(response);
        },
        (error:any) => {
          return Promise.reject(error);
        }
      )
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }
  static updateBulkRecord(record: any, tableId: string) {
    return http
      .put(`${ApiConstants.URL.TABLE.UPDATE_BULK_RECORD(tableId)}`, record)
      .then(
        (response:any) => {
          return Promise.resolve(response);
        },
        (error:any) => {
          return Promise.reject(error);
        }
      )
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }
  static deleteRecord(recordId: string, tableId: string) {
    return http
      .delete(`${ApiConstants.URL.TABLE.DELETE_RECORD(recordId, tableId)}`)
      .then(
        (response:any) => {
          return Promise.resolve(response);
        },
        (error:any) => {
          return Promise.reject(error);
        }
      )
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }
  static deleteRecordInBulk(records: string[], tableId: string) {
    return http
      .delete(`${ApiConstants.URL.TABLE.DELETE_BULK_RECORD(tableId)}`, {
        data: records,
      })
      .then(
        (response:any) => {
          return Promise.resolve(response);
        },
        (error:any) => {
          return Promise.reject(error);
        }
      )
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }
  static deleteColumn(columnId: string, tableId: string) {
    return http
      .delete(`${ApiConstants.URL.TABLE.DELETE_COLUMN(columnId, tableId)}`)
      .then(
        (response:any) => {
          return Promise.resolve(response);
        },
        (error:any) => {
          return Promise.reject(error);
        }
      )
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }
  static updateColumn(data: IColumn, tableId: string) {
    return http
      .put(`${ApiConstants.URL.TABLE.UPDATE_COLUMN(data.key, tableId)}`, data)
      .then(
        (response:any) => {
          return Promise.resolve(response);
        },
        (error:any) => {
          return Promise.reject(error);
        }
      )
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }
  static shiftColumn(data: any, tableId: string) {
    return http
      .post(`${ApiConstants.URL.TABLE.SHIFT_COLUMN(tableId)}`, data)
      .then(
        (response:any) => {
          return Promise.resolve(response);
        },
        (error:any) => {
          return Promise.reject(error);
        }
      )
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }
  /**
   *
   * @param {String} tableId - parent Table Unique ID(*)
   * @param {String} tableName - Parent Table Name(optional)
   * @param {String} viewName - View Name(*)
   * @returns
   */
//   static createView(params: any) {
//     return http
//       .post(`${ApiConstants.URL.TABLE.ADD_VIEW}`, params)
//       .then(
//         (response) => {
//           return Promise.resolve(response);
//         },
//         (error) => {
//           return Promise.reject(error);
//         }
//       )
//       .catch((error) => {
//         return Promise.reject(error);
//       });
//   }
//   static attendance(params) {
//     return http
//     .post(ApiConstants.URL.BASE+ApiConstants.URL.PEOPLE.ATTENDANCE, params)
//     .then(
//     (response) => {
//         return Promise.resolve(response);
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
//     )
//     .catch((error) => {
//         return Promise.reject(error);
//     });
//     }

//     static getAttendance(param){

//         return http.get(ApiConstants.URL.PEOPLE.ATTENDANCE_SELF(param.limit,param.sortOn,param.sortDir))
//     }

//     static getPayStub(userId, param){

//       return http.get(ApiConstants.URL.PAYROLL.PAYSTUB + `/employee/${userId}?limit=${param.limit}&currentPage=${param.currentPage}&sortField=${param.sortField}&sortOrder=${param.sortOrder}`)
//   }

//   static proxyAttendance(params) {
//     return http
//     .post(ApiConstants.URL.BASE+ApiConstants.URL.PEOPLE.PROXY_ATTENDANCE, params)
//     .then(
//     (response) => {
//         return Promise.resolve(response);
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
//     )
//     .catch((error) => {
//         return Promise.reject(error);
//     });
//     }

    static getTenantData() {
        return http.get(ApiConstants.URL.BASE+ApiConstants.URL.PEOPLE.TENANT).then((res) => {
          TenantManager.setPeopleTenantData(res)
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        })
    }

//     static savePayroll(params) {
//       return http.post(ApiConstants.URL.PAYROLL.GET_PAYROLL, params)
//     }

//   static editPayroll(params) {
//     return http.put(ApiConstants.URL.PAYROLL.GET_PAYROLL + `/${params._id}`, params)
//   }

//   static deletePayroll(payrollIds) {
//     return http.delete(ApiConstants.URL.PAYROLL.GET_PAYROLL, {
//       data: payrollIds,
//     })
//   }

//   static processPayroll(payrollId) {
//     return http.patch(ApiConstants.URL.PAYROLL.PROCESS_PAYROLL + `/${payrollId}`)
//   }
//   static revertPayroll(payrollId) {
//     return http.patch(ApiConstants.URL.PAYROLL.REVERT(payrollId),{})
//   }
// static deletePayrollMapping(payrollIds, payrunId) {
//   return http.delete(ApiConstants.URL.PAYROLL.GET_PAYROLL+`/payrun/${payrunId}/payroll`, {
//           data: payrollIds,
//         })
// }

// static deletePaySchedule(payScheduleId) {
//   return http.delete(ApiConstants.URL.PEOPLE.PAY_SCHEDULE_BASE+`/pay-schedules/bulk`, {
//           data: payScheduleId,
//         })
// }

// static upatePayroll(payroll, payrunId) {
//   return http.put(ApiConstants.URL.PAYROLL.GET_PAYROLL+`/payrun/${payrunId}/payroll/${payroll._id}`, payroll)
// }

// static linkCashFreeAccount(body) {
//   return http.post(ApiConstants.URL.CASHFREE.LINK_ACCOUNT,body)
// }

// static getLinkedAccounts() {
//   return http.get(ApiConstants.URL.CASHFREE.GET_ACCOUNTS)
// }

// static updateCashFreeAccount(body) {
//   return http.post(ApiConstants.URL.CASHFREE.UPDATE_ACCOUNT,body)
// }

// static deleteEmployee(body) {
//   return http.post(ApiConstants.URL.EMPLOYEE.DELETE_EMPLOYEE, body  )
// }

// static saveEmployee(body) {
//   return http.post(ApiConstants.URL.EMPLOYEE.SAVE_EMPLOYEE, body)
// }
// static getCheckList(iamUserId) {
//   return http.get(ApiConstants.URL.EMPLOYEE.EMPLOYEE_SERVICE+`/${iamUserId}/check-list`)
// }

// static getTermination(iamUserId) {
//   return http.get(ApiConstants.URL.EMPLOYEE.EMPLOYEE_SERVICE+`/termination?empId=${iamUserId}`)
// }

// static postTerminatedEmployee(body) {
//   return http.post(ApiConstants.URL.EMPLOYEE.EMPLOYEE_SERVICE + `/termination`, body  )
// }

// static putTerminatedEmployee(body) {
//   return http.put(ApiConstants.URL.EMPLOYEE.EMPLOYEE_SERVICE + `/termination`, body )
// }

// static postTerminatedCheckList(body, imaUserId) {
//   return http.post(ApiConstants.URL.EMPLOYEE.EMPLOYEE_SERVICE + `/${imaUserId}/check-list`, body  )
// }

// static patchTerminatedCheckList(body, iamUserId) {
//   return http.patch(ApiConstants.URL.EMPLOYEE.EMPLOYEE_SERVICE + `/${iamUserId}/check-list`, body)
// }

// static updateEmployee(body) {
//   return http.patch(ApiConstants.URL.EMPLOYEE.SAVE_EMPLOYEE, body)
// }
// static deleteCustomFields(body) {
//   return http.post(ApiConstants.URL.PEOPLE.TENANT + `/custom-fields/delete`, body)
// }

// static postCustomField(body) {
//   return http.post(ApiConstants.URL.PEOPLE.TENANT + `/custom-fields`, body  )
// }

// static verifyIfscAccountNumber(body) {
//   return http.post(ApiConstants.URL.CASHFREE.VERIFY_IFSC_ACCOUNT_NUMBER, body)
// }

// static syncBeneficiary() {
//   return http.post(ApiConstants.URL.CASHFREE.SYNC_BENEFICIARIES)
// }

// static retryPayment(payrunId) {
//   return http.patch(ApiConstants.URL.CASHFREE.RETRY_PAYMENT(payrunId))
// }

// static deleteBenefits(benefitsId) {
//   return http.delete(ApiConstants.URL.PEOPLE.COMPONENT+`benefits`, {
//           data: benefitsId,
//         })
// }

// static saveBenefit(body) {
//   return http.post(ApiConstants.URL.PEOPLE.COMPONENT + `benefit`, body  )
// }

// static updateBenefit(body) {
//   return http.put(ApiConstants.URL.PEOPLE.COMPONENT + `benefit`, body  )
// }

// static saveUSTaxDetails(body) {
//   return http.post(ApiConstants.URL.PEOPLE.TENANT + `/US/tax`, body  )
// }

// static updateUSTaxDetails(body) {
//   return http.put(ApiConstants.URL.PEOPLE.TENANT + `/US/tax`, body  )
// }

// static updateColumnPeople(data: IColumn, tableId: string) {
//     return http
//       .put(`${ApiConstants.URL.TABLE.UPDATE_COLUMN(data.id, tableId)}`, data)
//       .then(
//         (response) => {
//           return Promise.resolve(response);
//         },
//         (error) => {
//           return Promise.reject(error);
//         }
//       )
//       .catch((error) => {
//         return Promise.reject(error);
//       });
//   }
  // Component groups
//   static deleteComponentGroups(body) {
//     return http.post(ApiConstants.URL.PEOPLE.TENANT + `/component-group/delete`, body)
//   }

//   static getStateFormsAndFields(country, state) {
//     return http.get(ApiConstants.URL.REPORT.GET_FORMS_FIELDS(country, state));
//   }

}

export default Table;
