import React, { useEffect, useRef, useState } from "react";
import {
  DKLabel,
  DKButton,
  DKInput,
  INPUT_VIEW_DIRECTION,
  showToast,
  TOAST_TYPE,
  INPUT_TYPE,
  removeLoader,
  showLoader,
} from "deskera-ui-library";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Utility, {
  deepClone,
  removeDelayedLoader,
  showDelayedLoader,
} from "../../utils/Utility";
import {
  fetchExpenseClaims,
  selectExpenses,
} from "../../redux/slices/ExpenseClaimListSlice";
import moment from "moment";
import { FORMAT, MESSAGES } from "../../utils/Constants";
import ExpenseClaimListService from "../../services/ExpenseClaimListService";
import { isMobileWebView } from "../../utils/ViewportSizeUtils";
import { TableManger, TABLES } from "../../managers/TableManager";
import {
  ExpenseSyncRevertColumnConfig,
  parseExpenseColumnConfig,
  parseVisibleColumnConfig,
} from "../../services/Config";
import DataGridHolderPayroll from "../common/DataGridHolderPayroll";
import ExpenseIntegrationService from "../../services/ExpenseIntegrationService";

const SyncRevertJE = (props) => {
  const initialExpenseClaims = {
    columnData: [],
    rowData: [],
    originalData: [],
    filter: [],
  };
  const isSync = props.action === "sync";
  const { t, i18n } = useTranslation();
  const [startDate, setStartDate] = useState(moment().startOf("month"));
  const [endDate, setEnadDate] = useState(moment().endOf("month"));
  const [defaultValues, setDefaultValues] = useState({
    START_DATE: { value: new Date(startDate), hasError: false, errMsg: "" },
    END_DATE: { value: new Date(endDate), hasError: false, errMsg: "" },
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [expenseClaims, setExpenseClaims] = useState(initialExpenseClaims);
  const [filters, setFilters] = useState([]);
  const [gridWidth, setGridWidth] = useState(950);
  const gridContainer = useRef();
  const [selectedData, setSelectedData] = useState({ data: [] });
  const [isSyncRevertTriggered, setSyncRvertAction] = useState(false);

  //Selectors and Dispactor
  const expenses = useSelector(selectExpenses);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchEclaims(getAPIConfig());
  }, []);

  useEffect(() => {
    if (!Utility.isEmpty(expenses)) {
      setGridWidth(gridContainer?.current?.clientWidth || 1000);
      const { content, pageable, totalPages } = expenses;
      const rowData = ExpenseSyncRevertColumnConfig(content, isSync);
      const columns = parseExpenseColumnConfig(
        TableManger.getTableColumns(TABLES.PEOPLE_EXPENSE_SYNC_REVERT),
        isSync
      );
      const columnConfig = parseVisibleColumnConfig(columns);
      const claimsData = {
        columnData: deepClone(columnConfig),
        rowData: rowData,
        filter: [],
        originalData: rowData,
      };
      setExpenseClaims(claimsData);
      setCurrentPage(pageable.pageNumber);
      setTotalPageCount(totalPages);
    }
  }, [expenses]);

  const getAPIConfig = () => {
    return {
      params: {
        view: "ADMIN",
        page: currentPage,
        limit: 20,
      },
      queryParams: getQueryParams(),
    };
  };

  const getQueryParams = () => {
    const queryParams = {
      "spendDate>=": moment(defaultValues["START_DATE"].value).format(
        FORMAT.YYYYMMDD
      ),
      "spendDate<=": moment(defaultValues["END_DATE"].value).format(
        FORMAT.YYYYMMDD
      ),
      "isPosted=": isSync ? false : true,
      "categoryId!": null,
      "status=": "APPROVED",
    };
    return queryParams;
  };

  const fetchEclaims = async (apiConfig) => {
    const timeout = showDelayedLoader();
    try {
      ExpenseClaimListService.apiConfig = apiConfig;
      await dispatch(fetchExpenseClaims());
      removeDelayedLoader(timeout);
    } catch (err) {
      removeDelayedLoader(timeout);
      showToast(t("SOMETHING_WENT_WRONG"), TOAST_TYPE.FAILURE);
    }
  };

  const onSyncRevertClick = () => {
    setSyncRvertAction(true);
    for (const [key, value] of Object.entries(defaultValues)) {
      if (defaultValues[key]?.hasError) {
        showToast(t("SELECT_PROPER_RANGE"), TOAST_TYPE.FAILURE);
        setSyncRvertAction(false);
        return;
      }
    }
    if (!selectedData["data"]?.length) {
      showToast(
        isSync
          ? t("NO_EXPENSE_SELECTED_SYNC")
          : t("NO_EXPENSE_SELECTED_REVERT"),
        TOAST_TYPE.FAILURE
      );
      setSyncRvertAction(false);
      return;
    }
    const selectedIds = getSelectedIds();
    const body = {
      ids: selectedIds,
    };
    const action = isSync ? "SYNC" : "REVERT";
    showLoader(`${isSync ? t("SYNCING") : t("REVERTING")} ${t("IN_PROGRESS")}`);
    ExpenseIntegrationService.syncRevertJEs(body, action).then(
      (response) => {
        selectedData["data"] = [];
        fetchEclaims(getAPIConfig());
        removeLoader();
        setSyncRvertAction(false);
        showToast(
          `${
            isSync
              ? t("JE_POSTED_SUCCESSFULLY_EXPENSE")
              : t("JE_REVERTED_SUCCESSFULLY_EXPENSE")
          }`,
          TOAST_TYPE.SUCCESS
        );
      },
      (error) => {
        setSyncRvertAction(false);
        showToast(t("SOMETHING_WENT_WRONG"), TOAST_TYPE.FAILURE);
        removeLoader();
      }
    );
  };

  const getSelectedIds = () => {
    let selectedIds = [];
    if (selectedData["data"]?.length > 0) {
      selectedIds = selectedData["data"].map((x) => x.id);
    }
    return selectedIds;
  };

  const onSelection = (data) => {
    selectedData["data"] = data;
  };

  const onRowUpdate = (data) => {
    const rowData = data?.rowData;
    if (!Utility.isEmpty(rowData)) {
      if (isSync) {
        const todaysDate = moment().format("MM-DD-YYYY");
        const jeDate = moment(rowData.dateOfJE).format("MM-DD-YYYY");
        if (
          moment(todaysDate, "MM-DD-YYYY").isAfter(moment(jeDate, "MM-DD-YYYY"))
        ) {
          showToast(t("BACK_DATE_DATE_OF_JE"), TOAST_TYPE.FAILURE);
        }
      } else {
        const dateOfReversal = moment(rowData.dateOfReversalOfJE).format(
          "MM-DD-YYYY"
        );
        const todaysDate = moment().format("MM-DD-YYYY");
        if (
          moment(todaysDate, "MM-DD-YYYY").isAfter(
            moment(dateOfReversal, "MM-DD-YYYY")
          )
        ) {
          showToast(t("BACK_DATE_REVERSAL_DATE_OF_JE"), TOAST_TYPE.FAILURE);
        }
      }
    }
  };

  return (
    <>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{ maxWidth: "70%", height: "70%" }}
        >
          <div className="row">
            <DKLabel
              text={isSync ? t("SYNC_TO_BOOKS") : t("REVERT_FROM_BOOKS")}
              className="fs-m fw-m align-self-center width-80"
            />
            <div className="row-reverse ">
              <DKButton
                title={isSync ? t("SYNC") : t("REVERT")}
                className="border-m ml-r bg-app text-white"
                onClick={() => onSyncRevertClick()}
                disabled={isSyncRevertTriggered}
              />
              <DKButton
                title={t("CANCEL")}
                className="g-gray1 border-m"
                onClick={(event) => {
                  props.onClose();
                }}
              />
            </div>
          </div>
          <div className="p-v-m p-h-m">
            <span>
              {isSync
                ? t("EXPENSE_DATA_WILL_BE_POSTED")
                : t("EXPENSE_DATA_WILL_BE_REVERTED")}
            </span>
          </div>
          <div className="bg-white pb-l mt-l web-width-40">
            <div className="row row-responsive">
              <div
                className="p-h-l parent-width "
                style={{ minWidth: "400px" }}
              >
                <div>
                  <div className="row row-responsive">
                    <DKInput
                      title={t("START_DATE")}
                      type={INPUT_TYPE.DATE}
                      value={defaultValues["START_DATE"].value}
                      required={true}
                      readOnly={false}
                      canValidate={true}
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      errorMessage={defaultValues["START_DATE"].errMsg}
                      onChange={(value) => {
                        //   onChange(value, "START_DATE");
                      }}
                      validator={(value) => {
                        const startDate = value;
                        const endDate = defaultValues["END_DATE"]?.value;
                        defaultValues["START_DATE"].value = value;
                        if (
                          !Utility.isEmpty(startDate) &&
                          moment(startDate).isAfter(endDate)
                        ) {
                          defaultValues["START_DATE"].hasError = true;
                          defaultValues["START_DATE"].errMsg =
                            MESSAGES.START_DATE_ERR;
                          return false;
                        } else {
                          defaultValues["START_DATE"].hasError = false;
                          defaultValues["START_DATE"].errMsg =
                            MESSAGES.START_DATE_ERR;
                          return true;
                        }
                      }}
                    />
                    <DKInput
                      title={t("END_DATE")}
                      type={INPUT_TYPE.DATE}
                      value={defaultValues["END_DATE"].value}
                      required={true}
                      className={!isMobileWebView() ? `ml-l` : ""}
                      readOnly={false}
                      canValidate={true}
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      onChange={(value) => {
                        //   onChange(value, "END_DATE");
                      }}
                      errorMessage={defaultValues["END_DATE"].errMsg}
                      validator={(value) => {
                        const startDate = defaultValues["START_DATE"]?.value;
                        const endDate = value;
                        defaultValues["END_DATE"].value = value;
                        if (
                          !Utility.isEmpty(startDate) &&
                          moment(endDate).isBefore(startDate)
                        ) {
                          defaultValues["END_DATE"].hasError = true;
                          defaultValues["END_DATE"].errMsg =
                            MESSAGES.END_DATE_ERR;
                          return false;
                        } else {
                          defaultValues["END_DATE"].hasError = false;
                          defaultValues["END_DATE"].errMsg =
                            MESSAGES.END_DATE_ERR;
                          return true;
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="mb-xs ml-l" style={{ marginTop: "30px" }}>
                <DKButton
                  title={t("Fetch")}
                  className="bg-app text-white"
                  onClick={() => {
                    const config = {
                      params: {
                        view: "ADMIN",
                        page: currentPage,
                        limit: 20,
                      },
                      queryParams: getQueryParams(),
                    };
                    fetchEclaims(config);
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="column parent-height mt-l pl-l"
            style={{
              width: "98%",
            }}
          >
            <div
              className="column parent-width mb-s position-relative flex-1 parent-width"
              ref={gridContainer}
            >
              <DataGridHolderPayroll
                tableName={TABLES.PEOPLE_EXPENSE_SYNC_REVERT}
                allowColumnEdit={false}
                allowColumnDelete={false}
                allowColumnShift={false}
                allowSearch={false}
                allowFilter={false}
                allowColumnAdd={false}
                allowRowAdd={false}
                refresh={false}
                allowDataExport={false}
                allowRowEdit={true}
                showContextEdit={false}
                isActionEnabled={false}
                contextMenu={false}
                isMenuVisible={false}
                gridData={expenseClaims}
                filter={filters}
                gridWidth={gridWidth}
                currentPage={currentPage}
                totalPageCount={totalPageCount}
                onSelection={(data) => {
                  onSelection(data);
                }}
                onFilter={(dataList, queries) => {
                  // onFilter(dataList, queries);
                }}
                onRowDelete={() => {
                  // onRowDelete();
                }}
                onRowUpdate={(data) => {
                  onRowUpdate(data);
                }}
                addButtonConfig={[]}
                onPaginationClick={(requestedPageNumber) => {
                  const config = {
                    params: {
                      view: "ADMIN",
                      page: requestedPageNumber,
                      limit: 20,
                    },
                    queryParams: getQueryParams(),
                  };
                  fetchEclaims(config);
                }}
                isHeaderHidden={true}
                onRowOpenBtnHidden={true}
              />
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default SyncRevertJE;
