import React, { useEffect, useState } from "react";
import { TableManger } from "../../managers/TableManager";
import {
  DKLabel,
  DKCheckMark,
  DKButton,
  INPUT_TYPE,
  DKIcons,
} from "deskera-ui-library";
import UserManager from "../../managers/UserManager";
import i18next from "i18next";

// interface IContextMenuProps {
//   tableName: string;
//   displayTableName: string;
//   className?: string;
//   onDelete?: () => void;
//   onCancel?: () => void;
//   onApply?: (data) => void;
//   onEdit?: () => void;
//   onCheckPrint?: () => void;
//   isCheckPrint?: boolean;
//   isShowEdit?: boolean;
//   isSelectOptionShow?: boolean;
//   onRowSelectActionBtn?: any;
//   selection?: any;
//   isShowRevert?: boolean;
//   onRevertClick?: (data) => void;
//   addMenuButton?: any;
//   hideTableName?: any;
// }
const ContextMenu = (props) => {
  let columnsToShow = TableManger.getTableColumns(props.tableName).filter(
    (column) =>
      (column.type === INPUT_TYPE.SELECT ||
        column.type === INPUT_TYPE.MULTI_SELECT) &&
      column.editable
  );
  let [count, setCount] = useState(0);
  let [columns, setColumns] = useState([]);
  let [opData, setOpData] = useState({});

  useEffect(() => {
    setColumns(JSON.parse(JSON.stringify(columnsToShow)));
  }, [count]);

  const onSelect = (column, data) => {
    let newOpData = opData;
    opData[column.id] = data;
    setOpData(newOpData);
    setCount((prevCount) => prevCount + 1);
  };

  const onApply = () => {
    if (props.onApply) props.onApply(opData);
  };
  const onRevertClick = () => {
    if (props.onRevertClick) props.onRevertClick(props.selection);
  };
  const isShowForMultiple = (btn) => {
    if (
      props.selection &&
      props.selection.length > 1 &&
      btn.isShowForMultiple
    ) {
      return true;
    } else if (props.selection && props.selection.length == 1) {
      let row = props.selection;
      if (btn.condition) {
        let keys = Object.keys(btn.condition);
        let isShow = false;
        keys.forEach((key) => {
          if (btn.condition[key] == row[0][key]) {
            isShow = true;
          } else {
            isShow = false;
          }
        });
        return isShow;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const getManagerTitle = (btn) => {
    let title = {
      text: "",
      value: false,
    };
    if (btn.onSelectionTitle) {
      title.text = btn.onSelectionTitle.title1;
    } else {
      title.text = btn.title;
    }

    let ind = props.selection.findIndex((res) => res.data.role != "manager");
    if (ind == -1) {
      title = {
        text: btn.onSelectionTitle ? btn.onSelectionTitle.title2 : btn.title,
        value: true,
      };
    }
    return title;
  };
  return (
    <div
      className={`parent-width row pt-s ${
        props.addMenuButton ? "mb-xs" : ""
      }  `}
      style={{ overflowX: "hidden" }}
    >
      <DKLabel
        text={props.displayTableName}
        className={`fw-m fs-m mr-l text-align-left white-space-no-wrap ${
          props.hideTableName?.hidden ? "d-none" : ""
        } `}
      />
      {props.hideTableName?.hidden && (
        <DKLabel
          text=""
          style={{ width: `${props.hideTableName?.width}px ` }}
          className={`fw-m fs-m mr-l text-align-left white-space-no-wrap  `}
        />
      )}

      <div
        className={`row ${props.addMenuButton ? "ml-6" : ""}`}
        style={{ width: "auto" }}
      >
        {props.isSelectOptionShow && (
          <span>
            {columns.map((column) => {
              switch (column.type) {
                case INPUT_TYPE.MULTI_SELECT:
                  return (
                    <MultiSelectList
                      {...column}
                      className={`mr-r`}
                      onSelect={(data) => onSelect(column, data)}
                    />
                  );
                case INPUT_TYPE.SELECT:
                  return (
                    <SelectList
                      {...column}
                      className={`mr-r`}
                      onSelect={(data) => onSelect(column, [data])}
                    />
                  );
              }
            })}
          </span>
        )}
        {props.isShowEdit && (
          <DKButton
            title="Edit"
            icon={DKIcons.ic_edit}
            className="mr-r bg-white  border-m"
            onClick={() => (props.onEdit ? props.onEdit() : false)}
          />
        )}
        {!props.addMenuButton && (
          <DKButton
            title="Delete"
            className="border-red mr-r bg-red text-white"
            onClick={() => (props.onDelete ? props.onDelete() : false)}
          />
        )}
        {Object.keys(opData).length > 0 && (
          <DKButton
            title="Apply"
            className="border-green mr-r bg-green text-white"
            onClick={() => onApply()}
          />
        )}
        {props.isShowRevert && (
          <DKButton
            title="Revert"
            className="border-m mr-r bg-white"
            onClick={() => onRevertClick()}
          />
        )}
        {props.addMenuButton &&
          props.addMenuButton.map((btn) => {
            return (
              <>
                {isMenuButtonVisible(btn, props) && (
                  <DKButton
                    title={
                      btn.onSelectionTitle
                        ? getManagerTitle(btn).text
                        : btn.title
                    }
                    icon={btn.icon}
                    className={btn.className}
                    onClick={() =>
                      btn.onClick(props.selection, getManagerTitle(btn).value)
                    }
                  />
                )}
              </>
            );
          })}

        {props.isCheckPrint && (
          <DKButton
            icon={DKIcons.white.ic_download}
            title="Check Print"
            className="bg-app text-white mr-r"
            onClick={() => (props.onCheckPrint ? props.onCheckPrint() : false)}
          />
        )}

        <DKButton
          icon={DKIcons.ic_close}
          className="border-m mr-r bg-white"
          onClick={() => (props.onCancel ? props.onCancel() : false)}
        />
      </div>
    </div>
  );
};
const isMenuButtonVisible = (btn, props) => {
  let isConditionMatch = true;
  if (btn.condition) {
    let ind = props.selection.findIndex(
      (r) => r[btn.condition.columnCode] != btn.condition.value
    );
    if (ind != -1) {
      isConditionMatch = false;
    }
    if (btn.title == i18next.t("SUBMIT")) {
      let userId = UserManager.getUserDetails().id;
      let userInd = props.selection.findIndex((r) => r.data.userId != userId);
      if (userInd != -1) {
        isConditionMatch = false;
      }
    }
  }
  return !btn.hide && isConditionMatch;
};
const SelectList = (props) => {
  const [showList, setShowList] = useState(false);
  const [options, setOptions] = useState(props.options);

  const onOptionSelect = (index) => {
    let newOptions = [...options].filter((item, i) => {
      item.selected = false;
      if (index === i) {
        item.selected = true;
        props.onSelect(item);
      }
      return item;
    });
    setOptions(newOptions);
    setShowList(false);
  };
  const getOptionList = () => {
    return (
      <div
        className="option-list-wrapper column bg-white p-s shadow-l"
        style={{
          position: "absolute",
          zIndex: 2,
        }}
      >
        {options.map((option, index) => {
          return (
            <div
              className="p-xs cursor-hand row"
              onClick={() => onOptionSelect(index)}
            >
              <DKLabel text={option.name} />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={`multi-select-list parent-width fw-m ${props.className}`}
      style={props.style}
    >
      <DKButton
        className={`border-m bg-white shadow-s ${
          options.find((i) => i.selected) ? "border-blue text-blue" : ""
        }`}
        icon={showList ? DKIcons.ic_arrow_up2 : DKIcons.ic_arrow_down2}
        isReverse={true}
        onClick={() => setShowList(!showList)}
        title={
          options.find((i) => i.selected)
            ? options.find((i) => i.selected).name
            : props.name
        }
      />
      {showList && getOptionList()}
    </div>
  );
};
const MultiSelectList = (props) => {
  const [showList, setShowList] = useState(false);
  const [options, setOptions] = useState(props.options);

  const onOptionSelect = (index) => {
    let newOptions = [...options];
    newOptions[index]["selected"] = !newOptions[index]["selected"];
    setOptions(newOptions);
    props.onSelect(newOptions.filter((option) => option.selected));
  };

  const getOptionList = () => {
    return (
      <div
        className="option-list-wrapper column bg-white p-s shadow-l"
        style={{
          position: "absolute",
          zIndex: 2,
        }}
      >
        {options.map((option, index) => {
          return (
            <div className="p-xs cursor-hand row">
              <DKCheckMark
                isSelected={option.selected}
                onClick={() => onOptionSelect(index)}
              />
              <DKLabel text={option.name} />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={`multi-select-list parent-width fw-m ${props.className}`}
      style={props.style}
    >
      <DKButton
        className={`border-m bg-white shadow-s ${
          options.find((i) => i.selected) ? "border-blue text-blue" : ""
        }`}
        icon={showList ? DKIcons.ic_arrow_up2 : DKIcons.ic_arrow_down2}
        isReverse={true}
        onClick={() => setShowList(!showList)}
        title={
          options.find((i) => i.selected)
            ? options.find((i) => i.selected).name
            : props.name
        }
      />
      {showList && getOptionList()}
    </div>
  );
};
export default ContextMenu;
