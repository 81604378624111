import { Component } from "react";
import { DKLabel, DKButton, DKInput, showToast, TOAST_TYPE,removeLoader,showLoader } from "deskera-ui-library";
import { NEW_GROUP_FORM_FILED } from "../claims/ClaimsConstants";
import Utility from "../../utils/Utility";
import ExpenseService from "../../services/ExpenseService";

export default class AddEditExpenseGroup extends Component {
    isEdit = false;
    canValidate = false;
    groupForm = NEW_GROUP_FORM_FILED;
    expenseData = {
        name: '',
        employee: []
    }
    allUsers = [];
    selectedUser = [];
    groupId = null;
    constructor(props) {
        super(props);
        if(this.props.expenseData) {
            this.expenseData.name = this.props.expenseData.name;
            this.groupId = this.props.expenseData.id;
            this.isEdit = true;

        } else {
            this.groupId = null;
        }
    }
    componentDidMount() {

        this.getAllUser();
        if(this.groupId) {
            this.groupNameReadOnly(true);
        } else {
            this.groupNameReadOnly(false);
        }
    }

    updateState() {
        this.setState({
            ...this.state
        })
    }
    getUserByGroupId() {
        ExpenseService.getUserByGroup(this.groupId).then(res=>{
            if(res) {
                let selectedUsersIndex = []

                this.expenseData.employee = res.map(r=>{
                    r['userId']=r.id;
                    return r
                })
                this.updateState();


            }
        },
        error=>{
            console.log(error);
        })
    }
    getAllUser() {
        if (this.props.users) {
            this.allUsers = this.props.users;
            if (this.groupId) {
                this.getUserByGroupId();
            }
            let empInd = this.groupForm.findIndex(resp=>resp.key==="employee");
            if (empInd != -1) {
                this.groupForm[empInd].options = this.allUsers;
                this.updateState()
            }
        }

    }
    validateForm() {
        let isValid = true;
        this.canValidate = true;
        this.groupForm.forEach(field => {
            if (field.required && typeof this.expenseData[field.key] == "string" && Utility.isEmpty(this.expenseData[field.key].trim())) {
                isValid = false;
            } else if (field.required && Utility.isEmpty(this.expenseData[field.key])) {
                isValid = false;
            } else if (!field.isValid) {
                isValid = false;
            }
        })
        this.updateState();
        return isValid
    }

    submit() {
        this.canValidate = true;
        this.updateState()
        if (!this.validateForm()) {
            return;
        }
        showLoader()
        if (!this.groupId) {
            ExpenseService.saveGroup({ name: this.expenseData.name }).then(res => {
                if (res && res.id) {
                    this.groupId = res.id;
                    this.groupNameReadOnly(true);

                    showToast("Details saved successfully", TOAST_TYPE.SUCCESS);
                    this.props.onClose(true)
                }
                removeLoader()
            }, error => {
                if (error && error.message) {
                    showToast(error.message.replace(/{/g, '').replace(/}/g, ''), TOAST_TYPE.FAILURE);
                }
                removeLoader()
            })
        }
    }
    groupNameReadOnly(isReadOnly) {
        let empInd = this.groupForm.findIndex(resp => resp.key === "name");
        if (empInd != -1) {
            this.groupForm[empInd].readOnly = isReadOnly;
            this.updateState()
        }
    }
    saveEmployee() {
        let employee = this.expenseData['employee'].map(e=>{
            e.groupId = this.groupId;
            return e;
        })
        ExpenseService.expenseBulkUser(employee).then(r=>{
            showToast("Details saved successfully", TOAST_TYPE.SUCCESS);
            this.props.onClose(true)
            removeLoader()
        },error=>{
            removeLoader()
            if(error && error.message) {
                showToast(error.message, TOAST_TYPE.FAILURE);
            } else {
                showToast('Internal server error', TOAST_TYPE.FAILURE);
            }
        })
    }
    onChange(value, index, obj) {
        switch (obj.key) {
            case "employee":
               let a = []
                value.forEach(ind=>{
                    let empObj = {

                        role: "employee"
                    }
                    empObj['userId'] = obj['options'][ind].id;
                    a.push(empObj)
                })
                this.expenseData[obj.key] = a;
                break;

            default:
                this.expenseData[obj.key] = value;
                this.updateState()
                break;
        }

    }
    getFieldValue(obj) {
        switch (obj.columnCode) {
            case "employee":
                let empInd = this.groupForm.findIndex(resp => resp.key === "employee");
                let options = []
                if(empInd != -1)
                this.expenseData.employee.forEach(emp=>{
                    let ind = this.groupForm[empInd].options.findIndex(o=>o.id==emp.userId)
                    if(ind !=-1){
                        options.push(ind)
                    }
                })
                return (obj.options && obj.options.length!=0) ? options:undefined
            default:
                return this.expenseData[obj.columnCode]
        }

    }

    //**********UI LOGIC */
    renderTop() {
        return (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <DKLabel text={`${this.isEdit ? "Edit" : "New"} Expense Group`} className="fw-m fs-l" />
                    <div className="d-flex">
                        <DKButton
                            className="bg-gray1 border-m mr-s"
                            title="Close"
                            onClick={() => {
                                if (this.props.onClose) {
                                    this.props.onClose(this.groupId ? true:false)
                                }
                            }}
                        />

                        <DKButton
                            className={`bg-app text-white  `}
                            title="Submit"

                            onClick={() => {
                                this.submit()
                            }}
                        />
                    </div>
                </div>
            </>
        )
    }
    renderFormField(obj, index) {
        return (
            <>
                <DKInput
                    displayKey="name"
                    className={"p-v-s mb-r"}
                    icon={obj.icon}
                    title={obj.name}
                    placeholder={obj.name}
                    value={this.getFieldValue(obj)}
                    // options={obj.options}
                    type={obj.type}
                    errorMessage={obj.errorMessage}
                    labelClassName=" text-align-left "
                    canValidate={this.canValidate}
                    onChange={(value) => {
                        this.onChange(value, index, obj);
                    }}
                    validator={(value) => {
                        if (obj.validation && obj.validation.regExp) {
                            const regex = new RegExp(obj.validation.regExp);
                            obj.isValid = regex.test(value);
                            return regex.test(value);
                        } else if (obj.required && typeof value === "string" && Utility.isEmpty(value.trim())) {
                            obj.isValid = false
                            return false;
                        } else if (obj.required && Utility.isEmpty(value)) {
                            obj.isValid = false
                            return false;
                        } else {
                            obj.isValid = true;
                            return true;
                        }
                    }}
                    formatter={(obj) => {
                        /** How to render a selected option in case of object **/
                        return `${obj.firstName} ${obj.lastName}`;
                    }}
                    dropdownConfig={{
                        className: "",
                        style: {},
                        allowSearch:true,
                        searchableKey:"name",
                        checkMarkColor: "bg-app",
                        data: obj.options,
                        multiSelect: obj.multiSelect,
                        renderer: (index, obj) => {
                            /** How to render value in Dropdown in case of object **/
                            return <DKLabel text={`${obj.firstName} ${obj.lastName}`} />;
                        },
                        onSelect: (index, obj) => {
                            // onselect
                        },
                    }}
                    direction={obj.direction}
                    required={obj.required}
                    readOnly={obj.readOnly}
                />
            </>
        )
    }
    render() {
        return (
            <>
                {this.renderTop()}
                <div className="mt-l ">
                    {this.groupForm.map((obj, index) => { return this.renderFormField(obj, index) })}
                </div>
            </>
        )
    }

}